import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";

export function convertToIndianISOString(
  timestamp: Timestamp,
  format: string = "YYYY-MM-DDTHH:mm:ss"
): string {
  // Convert Firestore Timestamp to JavaScript Date
  const date = timestamp.toDate();

  // Convert to IST (Indian Standard Time) using moment-timezone
  //   const istDateString = moment(date).tz("Asia/Kolkata").format();
  const istDateString = moment(date).tz("Asia/Kolkata").format(format); //this format is needed in the application

  console.log("converted iso string", istDateString);

  return istDateString;
}

export function convertToIndianISOStringFromDate(
  _date: Date | null,
  format: string = "YYYY-MM-DDTHH:mm:ss"

) {
  const istDateString = moment(_date).tz("Asia/Kolkata").format(format); //this format is needed in the application
  // console.log("converted iso string", istDateString);
  return istDateString;

}
