import { Badge, Button, Col, Container, Row, Table } from "react-bootstrap";
import {
  Days,
  TimeTable,
  TimeTableSlotEntry,
} from "../../../models/time_table_model";
import { PlusCircle } from "react-bootstrap-icons";
import uuid from "react-uuid";
import { CloseButton } from "react-toastify/dist/components";
import { useEffect, useState } from "react";
import SlotItem from "./TimeTableSlotEntry";
import AddSlotEntryModal from "./AddSlotEntryModal";
import { weekDays } from "../../../utils/days_constants";
import {
  getTimeTable,
  saveTimeTable,
} from "../../../controllers/time_table_controller";
import { showSuccess } from "../../../controllers/toaster";
import userStore from "../../../store/WebsiteStore";

export const timeTableEditorPageUrl = "/time-table-editor";

interface TimeTableProper {
  // isStudentView: boolean;
}

/**
 * The time table edit page
 * The rows will be the classes , the columns will be the classes
 */
const TimeTableEditorPage: React.FC<TimeTableProper> = () => {
  //Variables for adding / editing slot entries
  const [currentSelectedClass, setCurrentSelectedClass] = useState<string>("");
  const [currentSelectedDay, setCurrentSelectedDay] = useState<Days>(Days.NONE);
  const [currentSlotIndex, setCurrentSlotIndex] = useState<number>(-1);
  const [existingEntry, setEditingEntry] = useState<TimeTableSlotEntry | null>(
    null
  );
  const currentUserDetails = userStore((state) => state.currentUserData);
  const [isStudentView, setIsStudentView] = useState(true);

  const [timeTableData, setTimeTableData] = useState<TimeTable>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  // const classNumbers: string[] = [];
  const [classNumbers, setclassNumbers] = useState(['12'])

  function addSlotItem(classNumber: string, weekDay: Days) {
    setEditingEntry(null); //set null to avoid edit issue
    setShowModal(true);
    setCurrentSelectedClass(classNumber);
    setCurrentSelectedDay(weekDay);
  }

  function handleEditOrRemoveItem(
    weekDay: Days,
    classNumber: string,
    slotEntryIndex: number,
    isRemoveEntry: boolean
  ) {
    if (isRemoveEntry) {
      removeEntry(weekDay, classNumber, slotEntryIndex);
    } else {
      editEntry(weekDay, classNumber, slotEntryIndex);
    }
  }

  /**
   *
   * This function gets called from the SlotItem child component
   * @param weekDay
   * @param classNumber
   * @param slotEntryIndex
   */
  function removeEntry(
    weekDay: string,
    classNumber: string,
    slotEntryIndex: number
  ) {
    console.log("removing the slot item", weekDay, classNumber);

    setTimeTableData((prevState) => {
      if (
        prevState &&
        prevState[classNumber] &&
        prevState[classNumber][weekDay]
      ) {
        console.log("valid deletion entry");

        const classData = { ...prevState[classNumber] };
        const weekDayData = [...classData[weekDay]];
        console.log("weekday data", weekDayData);

        if (weekDayData.length > slotEntryIndex) {
          console.log("valid deletion");

          weekDayData.splice(slotEntryIndex, 1); // Remove the entry at slotEntryIndex
          classData[weekDay] = weekDayData;

          if (weekDayData.length === 0) {
            delete classData[weekDay];
            //delete the entry
          }

          return {
            ...prevState,
            [classNumber]: classData,
          };
        } else {
          console.log("invalid deletion", weekDayData.length, slotEntryIndex);
        }
      } else {
        console.log("prev entry is ", prevState);
      }
      return prevState;
    });
  }

  /**
   *
   * This function gets called from the SlotItem child component
   * @param weekDay
   * @param classNumber
   * @param slotEntryIndex
   */
  function editEntry(
    weekDay: Days,
    classNumber: string,
    slotEntryIndex: number
  ) {
    console.log("editing entry", weekDay, classNumber, slotEntryIndex);
    console.log("tableData", timeTableData);

    //set the current entry
    if (
      timeTableData &&
      timeTableData[classNumber] &&
      timeTableData[classNumber][weekDay] &&
      timeTableData[classNumber][weekDay][slotEntryIndex]
    ) {
      setShowModal(true);
      const editingEntry = timeTableData[classNumber][weekDay][slotEntryIndex];
      setEditingEntry(editingEntry);
      setCurrentSelectedClass(classNumber);
      setCurrentSelectedDay(weekDay);
      setCurrentSlotIndex(slotEntryIndex);
    }
  }

  /**
   * This function gets called by the slot entry modal
   *
   * @param newSlotEntry
   * @param isNewEntry
   */
  function handleSlotEditOrAddition(
    newSlotEntry: TimeTableSlotEntry,
    isNewEntry: boolean
  ) {
    if (isNewEntry) {
      console.log("slot entry addition called by child", newSlotEntry);
      setTimeTableData((prevState) => {
        const classData = prevState[newSlotEntry.class] || {};
        const weekDayData = classData[newSlotEntry.forDay] || [];

        return {
          ...prevState,
          [newSlotEntry.class]: {
            ...classData,
            [newSlotEntry.forDay]: [...weekDayData, newSlotEntry],
          },
        };
      });
    } else {
      //handle the edit scenario
      console.log("slot entry edit called by child", newSlotEntry);
      setTimeTableData((prevState) => {
        // Find the class data
        const classData = prevState[newSlotEntry.class] || {};
        // Find the day data
        const weekDayData = classData[newSlotEntry.forDay] || [];
        if (currentSlotIndex !== -1) {
          // If entry found, update it
          weekDayData[currentSlotIndex] = newSlotEntry;

          return {
            ...prevState,
            [newSlotEntry.class]: {
              ...classData,
              [newSlotEntry.forDay]: [...weekDayData],
            },
          };
        } else {
          console.error("Entry not found for edit");
          return prevState; // Return previous state unchanged
        }
      });
    }
  }

  async function handleSave() {
    console.log("time table data", timeTableData);
    const isSuccess: boolean = await saveTimeTable(timeTableData);
    if (isSuccess) showSuccess("Changes Saved...");
  }

  useEffect(() => {
    const fetchTimeTable = async () => {
      try {
        const timeTableData = await getTimeTable();
        if (timeTableData) {
          setTimeTableData(timeTableData);
        }
      } catch (error) {
        console.error("Error fetching time table:", error);
        // Handle error
      }
    };

    fetchTimeTable();
    //check if
    if (currentUserDetails && currentUserDetails.role === "teacher") {
      setIsStudentView(false); //safe -case to view
      const classNums = []
      for (var c = 12; c >= 3; c--) {
        classNums.push(`${c}`);
      }
      setclassNumbers(classNums)
    } else {
      //populate only the selected class number
      if (
        currentUserDetails &&
        currentUserDetails.class &&
        currentUserDetails.class > 0
      ) {
        // classNumbers.push(`${currentUserDetails.class}`); //only populate with the users class number
        setclassNumbers([`${currentUserDetails.class}`])
      }
    }
    return () => { };
  }, []);

  return (
    <div
      style={{
        marginLeft: "16px",
        marginTop: "16px",
      }}
    >
      {/* <Container> */}
      {/* <h2>Edit the time table</h2> */}

      {!isStudentView ? <Button onClick={handleSave}>Save</Button> : null}
      <Table responsive>
        <thead>
          {/* Display the days in columns */}
          <tr>
            <th>Class</th>
            {weekDays.map((day) => {
              return <th key={day}>{day}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {/* Display the class numbers */}
          {classNumbers.map((classNumber, index) => {
            return (
              <tr key={`${classNumber}-${index}`}>
                <td>Class {classNumber}</td>
                {weekDays.map((weekDay: Days, index: number) => (
                  <SlotItem
                    key={`${classNumber}-${weekDay}-${index}`}
                    classNumber={classNumber}
                    slotEntryIndex={index}
                    weekDay={weekDay}
                    timeTableData={timeTableData}
                    onAddSlotEntry={addSlotItem}
                    onEditOrRemoveSlotEntry={handleEditOrRemoveItem}
                    isTeacherView={!isStudentView}
                  />
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Add/modify slot entry modal */}
      {!isStudentView ? (
        <AddSlotEntryModal
          existingEntry={existingEntry}
          forClass={currentSelectedClass}
          forDay={currentSelectedDay}
          showModal={showModal}
          setShowModal={setShowModal}
          onConfirm={handleSlotEditOrAddition}
        ></AddSlotEntryModal>
      ) : null}
    </div>
  );
};

export default TimeTableEditorPage;
