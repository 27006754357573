import { Form, Row, Col } from "react-bootstrap";

import classes from "../../css_modules/StudentMyProfile.module.css";

function EditableText({ editDetails, title, content, reffer, cantEdit }) {
  return (
    <div>
      {!editDetails === false ? (
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          {cantEdit === false ? (
            <Row>
              <Col md="auto">
                <div>
                  <p className={classes.subText} style={{}}>
                    {title} :
                  </p>
                </div>
              </Col>

              <Col xs={10}>
                <Form.Control
                  type="text"
                  placeholder={content}
                  ref={reffer}
                  defaultValue={content}
                />
              </Col>
            </Row>
          ) : (
            <p className={classes.subText}>
              {title} : {content} (This field can't be edited)
            </p>
          )}
        </Form.Group>
      ) : (
        <p className={classes.subText}>
          {title} : {content}
        </p>
      )}
    </div>
  );
}

export default EditableText;
