import { Button, Col, Row, Form } from "react-bootstrap";

function CreateAssignmentOrQuizCompo({
  nameRef,
  makeQuizOrAssignmentHandler,
  creatingStatus,
  placeHolder,
}) {
  return (
    <>
      <Row>
        <Col
          md="auto"
          style={{
            marginBottom: "16px",
          }}
        >
          <Form.Control
            type="text"
            ref={nameRef}
            placeholder= {`${placeHolder} Name`}
          ></Form.Control>
        </Col>

        <Col>
          <Button
            style={{
              marginBottom: "16px",
            }}
            type="submit"
            onClick={makeQuizOrAssignmentHandler}
            disabled={creatingStatus}
          >
            {creatingStatus ? "Creating..." : `Create ${placeHolder}`}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default CreateAssignmentOrQuizCompo;
