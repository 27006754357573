import {
  Container,
  Button,
  Row,
  Col,
  // ToggleButton,
  // ButtonGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import { useState } from "react";

import { makeQuizPageUrl } from "./MakeQuizPage";

import OfflineQuizMakeComponent from "./OfflineQuizMakeTeacher";

// import OnlineQuizMakeComponent from "./OnlineQuizMakeTeacher";

// const QuizMode = {
//   offline: 0,
//   online: 1,
//   nonde: 5,
// };

export const quizMakerComponentUrl = "/quiz-maker";
export function QuizMakerComponent() {
  const navigate = useNavigate(); //navigate to other pages

  // const [radioValue, setRadioValue] = useState(QuizMode.offline);

  function handleGoBack() {
    navigate(makeQuizPageUrl);
  }

  return (
    <div
      style={{
        marginTop: "2%",
      }}
    >
      <Container>
        <Row>
          <Col>
            <Button
              style={{ marginBottom: "8px" }}
              onClick={handleGoBack}
              size="sm"
            >
              Go Back
            </Button>
          </Col>
        </Row>

        <Row>
          <hr />
        </Row>

        {/* <Row> */}
        {/* Show the quiz type selection  */}
        {/* <ButtonGroup>
            <ToggleButton
              id={"radio-0"}
              key={0}
              type="radio"
              checked={radioValue === QuizMode.offline}
              variant={
                radioValue === QuizMode.offline ? "primary" : "outline-primary"
              }
              onChange={(e) => {
                setRadioValue(0);
              }}
            >
              Offline
            </ToggleButton>
            <ToggleButton
              id={"radio-1"}
              key={1}
              type="radio"
              checked={radioValue === QuizMode.online}
              variant={
                radioValue === QuizMode.online ? "primary" : "outline-primary"
              }
              onChange={(e) => {
                setRadioValue(1);
              }}
            >
              Online
            </ToggleButton>
          </ButtonGroup>
        </Row> */}

        <div
          style={{
            marginTop: "8px",
          }}
        >
          <Row>
            <OfflineQuizMakeComponent></OfflineQuizMakeComponent>
          </Row>
          {/* <Row>
            {radioValue === QuizMode.offline ? (
              <OfflineQuizMakeComponent />
            ) : (
              <OnlineQuizMakeComponent />
            )}
          </Row> */}
        </div>
      </Container>
    </div>
  );
}
