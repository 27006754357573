import { Form, Col, Row, Button, Badge } from "react-bootstrap";
import { downloadQuestionPaper } from "../../controllers/quiz_controller";
import { EyeFill } from "react-bootstrap-icons";

function CustomFilePicker({
  fileUploaded,
  qPaperSelectedBool,
  qPaperFileName,
  handleFileChange,
  handleQuestionPaperSelect,
  handleQuestionPaperUpload,
  fileUploading,
  fileRef,
  isQuizUpdatedEver,
  includeQPaper,
  hasQuestionPaper,
  handleIncludeQPaper,
  storagePathCloud,
  labelText,
}) {
  async function questionPaperDownload() {
    if (storagePathCloud) {
      await downloadQuestionPaper(storagePathCloud);
    }
  }

  return (
    <div>
      <Form.Check
        defaultChecked={hasQuestionPaper}
        defaultValue={true}
        label={labelText}
        style={{
          fontSize: "20px",
        }}
        onClick={handleIncludeQPaper}
      />

      {includeQPaper ? (
        <div>
          {fileUploaded === true ? null : (
            <Form.Label>
              <h5>Select the question paper</h5>
            </Form.Label>
          )}

          {qPaperSelectedBool === true ? (
            fileUploaded === true ? (
              <Row>
                <Col md="auto">
                  <Badge
                    bg="light"
                    text="dark"
                    style={{
                      fontSize: "18px",
                      marginTop: "16px",
                    }}
                  >
                    {qPaperFileName}
                  </Badge>
                </Col>
                <Col
                  md="auto"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {/* View the uploaded question paper */}
                  {storagePathCloud ? (
                    <Badge onClick={questionPaperDownload}>
                      <EyeFill
                        size={20}
                        style={{
                          marginRight: "4px",
                          // margin: "0px",
                          // padding: "0px",
                        }}
                      ></EyeFill>
                      <div
                        style={{
                          marginTop: "2px",
                        }}
                      >
                        View File
                      </div>
                    </Badge>
                  ) : null}
                </Col>
                <Col>
                  <Button
                    onClick={handleFileChange}
                    disabled={isQuizUpdatedEver}
                  >
                    Change
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button
                onClick={handleQuestionPaperUpload}
                disabled={fileUploading}
              >
                {fileUploading ? "Uploading file..." : "Upload File"}
              </Button>
            )
          ) : (
            <Form.Control
              onSelect={() => {
                //console.log()("file selected");
              }}
              accept=".doc , .docx , .pdf ,.txt"
              type="file"
              ref={fileRef}
              required
              size="lg"
              onChange={handleQuestionPaperSelect}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}

export default CustomFilePicker;
