// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { Button, Row, Container } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import { handleLogOut } from "../../controllers/auth_Controller";

import useStore from "../../store/WebsiteStore";
import { studentAssignmentUrl } from "../../utils/all_routes";
import { timeTableEditorPageUrl } from "../../pages/teacher side/time-table-editor/TimeTableEditorPage";
// import authStore from "../../store/auth_store";

function StudentNavBar({ sticky, onlyShowBanner }) {
  const navigate = useNavigate();

  const setCurrentUserId = useStore((state) => state.setCurrentUserId);

  const setCurrentUserData = useStore((state) => state.setCurrentUserData);

  // const setUserAuthenticatedLoggedOut = authStore(
  //   (state) => state.setUserAuthenticatedLoggedOut
  // );

  const setShowNavBarComplete = useStore(
    (state) => state.setShowNavBarComplete
  );

  function handleMyAcademics(event) {
    event.preventDefault();
    navigate("/studentAcademics");
  }

  function handlePaymentInvoice(event) {
    event.preventDefault();
    navigate("/paymentInvoice");
  }

  function handleAnnouncement(event) {
    event.preventDefault();
    navigate("/");
  }

  function logoutHandler() {
    //first clear all the data stored inside the useStore
    //firstly the user id
    setShowNavBarComplete(false); //reset to default
    setCurrentUserId("");
    setCurrentUserData({ userRoleType: "student" });

    localStorage.removeItem("ayachi-authentication");
    localStorage.removeItem("ayachi-website-storer");
    localStorage.removeItem("ayachi-student-history");
    localStorage.removeItem("userId");

    handleLogOut();

    navigate("/login");
  }

  function handleMyAccount() {
    navigate("/my-account");
  }

  function handleAssignments() {
    navigate(studentAssignmentUrl);
  }

  function handleTimeTableClick() {
    navigate(timeTableEditorPageUrl);
  }

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      bg="dark"
      variant="dark"
      sticky={sticky}
    >
      <Container
        style={{
          marginLeft: "0px",
        }}
      >
        <Navbar.Brand>
          <Container>
            <Row>Ayachi Academy</Row>
          </Container>
        </Navbar.Brand>

        {onlyShowBanner === false ? (
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        ) : null}

        {onlyShowBanner === false ? (
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={handleAnnouncement}>
                <div>Announcements</div>
              </Nav.Link>

              <Nav.Link onClick={handleTimeTableClick}>
                <div>Time Table</div>
              </Nav.Link>
              <Nav.Link>
                <div>Quizzes</div>
              </Nav.Link>
              <Nav.Link onClick={handleAssignments}>
                <div>Assignments</div>
              </Nav.Link>

              <Nav.Link onClick={handleMyAcademics}>
                <div>My Academics</div>
              </Nav.Link>
              <Nav.Link onClick={handlePaymentInvoice}>
                <div>Payment Invoice</div>
              </Nav.Link>
            </Nav>

            <Nav>
              <NavDropdown title="Account" id="collasible-nav-dropdown">
                <NavDropdown.Item onClick={handleMyAccount}>
                  My Account
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Button onClick={logoutHandler}>Log Out</Button>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        ) : null}
      </Container>
    </Navbar>
  );
}

export default StudentNavBar;
