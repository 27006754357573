// import classes from "../../css_modules/AuthPage.module.css";

// import StudentNavBar from "../../components/layout/StudentNavBar";
import { useEffect, useState } from "react";
import SignUpComponent from "./SignUpComponent";

import googleLogo from "../../logos/google_logo.png";

import emailLogo from "../../logos/email_logo.png";

import LoginComponent from "./LoginComponent";
import { Button, Col, Container, Row } from "react-bootstrap";
import { GoogleAuthProvider } from "firebase/auth";

//App.tsx will decide whether to show the sign up page for the user if they have not
//completed their proper sign up yet
function AuthPage({
  requestForProperSignUp,
}: {
  requestForProperSignUp: boolean;
}) {
  const [isSignUp, setIsSignUp] = useState(requestForProperSignUp);

  useEffect(() => {
    setIsSignUp(requestForProperSignUp);
    return () => {};
  }, [requestForProperSignUp]);

  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: "2%",
      }}
    >
      {/* <StudentNavBar onlyShowBanner={true} /> */}

      {/* <Container>
        <Row>
          <span
            style={{
              fontSize: "30px",
              marginBottom: "16px",
            }}
          >
            Ayachi Academy
          </span>
        </Row>

        <Row
          style={{
            marginBottom: "20px",
          }}
        >
          <Col>
            <Button variant="light">
              <Row>
                <Col md="auto">
                  <img src={googleLogo} width="30px"></img>
                </Col>
                <Col md="auto">
                  <b>Sign In With Google</b>
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button variant="light">
              <Row>
                <Col md="auto">
                  <img src={emailLogo} width="30px"></img>
                </Col>
                <Col md="auto">
                  <b>Sign In With Email and Password</b>
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Container>  */}

      {/* <LoginComponent setClickedSignUp={setIsSignUp} clickedSignUp={isSignUp} /> */}
      {/* <h2>Checking {requestForProperSignUp ? "true" : "false"}</h2> */}
      {/* <h2>IsSignUp {isSignUp ? "true" : "false"}</h2>
      <h2>
        RequestedForProperSignUp {requestForProperSignUp ? "true" : "false"}
      </h2> */}

      {isSignUp ? (
        <SignUpComponent
          setClickedSignUp={setIsSignUp}
          clickedSignUp={isSignUp}
        />
      ) : (
        <LoginComponent />
      )}
    </div>
  );
}

export default AuthPage;
