function ProfilePicCompo({
    userDetails
}) {
  return (
    <div>
      <img
        className="rounded-circle shadow-4-strong"
        height={"200px"}
        width={"200px"}
        alt="avatar2"
        src={userDetails.imageUrl}
        style={{ marginBottom: "8px" }}
      />
    </div>
  );
}

export default ProfilePicCompo;
