import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import {
  AssignmentDataStoreInterface,
  StudentAssignmentData,
} from "../models/assignment_model";

// const customHandler = (set, get) => ({
//   currentAssignmentData: {},
//   currentStudentAssignmentData: {},

//   setCurrentAssignment: (data) => {
//     //console.log()(data)

//     //now if assigned to is null then add that field as well
//     // if (data.assignedTo === undefined)
//     set((state) => {
//       return {
//         currentAssignmentData: data,
//         assignedTo: [],
//       };
//     });
//   },

//   setCurrentStudentAssignmentData: (data) => {
//     set((state) => {
//       return {
//         currentStudentAssignmentData: data,
//       };
//     });
//   },
// });

const assignmentStore = create<AssignmentDataStoreInterface>()(
  devtools(
    persist(
      (set, get) => ({
        currentAssignmentData: {},
        currentStudentAssignmentData: undefined,

        setCurrentAssignment: (data: any) => {
          //console.log()(data)

          //now if assigned to is null then add that field as well
          // if (data.assignedTo === undefined)
          set((state: any) => {
            return {
              currentAssignmentData: data,
              assignedTo: [],
            };
          });
        },

        setCurrentStudentAssignmentData: (data: StudentAssignmentData) => {
          set((state: any) => {
            return {
              currentStudentAssignmentData: data,
            };
          });
        },
      }),

      {
        name: "ayachi-assignment-storer",
      }
    )
  )
);

export default assignmentStore;
