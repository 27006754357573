import { Timestamp } from "firebase/firestore";

export interface TeacherAssignmentData {
  allowLateSubmit: boolean;
  assignedTo: AssingnedUser[];
  classAssignedTo: number;
  createdAt: Timestamp;
  dueDateAt: Timestamp;
  graded: boolean;
  hasQuestionPaper: boolean;
  id: string;
  isUploadedOnStorage: boolean;
  storagePathCloud: string;
  marks: number;
  question: string;
  title: string;
}

export interface StudentAssignmentData {
  dueDateAt: Timestamp;
  id: string;
  marks: number;
  question: string;
  title: string;
  graded: boolean;
  allowLateSubmit: boolean;

  //Question paper related keys
  hasQuestionPaper: boolean;
  storagePathCloud: string;

  //submission related keys
  isSubmitted: boolean;
  submittedAt: Timestamp | null;
  hasAnswerSheetFileAttachment: boolean;
  assignmentStatus: AssignmentStatus;
  answerSheetStoragePath?: string; //can be null in cases where upload is not required
}

export interface AssingnedUser {
  id: string;
  studentSelected: boolean;
  username: string;
}

export enum AssignmentStatus {
  OVERDUE = "Due Date Passed",
  SUBMITTED = "Submitted",
  LATE_SUBMISSION = "Late Submission",
  // when the assignment first gets created then the status unknown(only for code help not in ui)
  STATUS_UNKNOWN = "Status Unknown",
}

export interface AssignmentDataStoreInterface {
  currentAssignmentData: any;
  currentStudentAssignmentData: StudentAssignmentData | undefined;

  setCurrentAssignment: (data: any) => void;
  setCurrentStudentAssignmentData: (data: StudentAssignmentData) => void;
}
