import ScaffoldLayout from "../../../components/layout/Scaffold";
import CustomFormInputUI from "../../../components/ui/CustomFormUI";
import { Button, Form } from "react-bootstrap";

import { useState } from "react";
import { useRef } from "react";

import { uploadMaterial } from "../../../controllers/class_material_uploader";
import { showAlert } from "../../../controllers/toaster";

function UploadMaterialPage() {
  const fileRef = useRef(null);
  const titleRef = useRef(null);
  const classRef = useRef(null);

  const [file, setFile] = useState(null);
  const [multiple, setmultiple] = useState(false); //initially it's false
  const [uploading, setuploading] = useState(false);

  function handleFileSelect(event) {
    //sets the files that are uploaded
    const files = event.target.files;
    setFile(files);
  }

  function handleSetMultiple() {
    setmultiple(!multiple);
  }

  function handleUploadMaterial() {
    if (!check()) {
      console.log("incorrect data not checked yet");
      return;
    }
    console.log("all validations passed now uploading the file as well");

    uploadMaterial(
      file,
      classRef.current.value,
      titleRef.current.value,
      setuploading
    );
    // console.log(file);
  }

  function check() {
    if (titleRef.current.value === "" || classRef.current.value === "") {
      showAlert("Please input all details  ", 3000);
      return false;
    } else if (titleRef.current.value.length < 5) {
      showAlert("Title is too small", 3000);
      return false;
    }
    try {
      const classNumber = parseInt(classRef.current.value);
      if (classNumber > 12 || classNumber < 1) {
        showAlert("Please put a valid class ", 3000);
        return false;
      } else if (file === null) {
        showAlert("Please select a file", 3000);
        return false;
      }
      //check if file has been selected or not
      //TODO: Later do this file selection check
    } catch (error) {}
    return true; //otherwise return true
  }

  return (
    <ScaffoldLayout>
      <h4>Upload Class Material</h4>

      <hr></hr>

      <CustomFormInputUI
        isFormDisabled={false}
        placeholder={"Enter material title ex : Physics Sample paper Class 9th"}
        title="File Title"
        reffer={titleRef}
      />

      <CustomFormInputUI
        isFormDisabled={false}
        placeholder={"Enter class"}
        title="Enter Class to upload"
        reffer={classRef}
      />
      <Form.Check
        onClick={handleSetMultiple}
        label="Multiple Files ? "
        size={"lg"}
      />

      <Form.Control
        multiple={multiple}
        onSelect={() => {
          //console.log()("file selected");
        }}
        accept=".doc , .docx , .pdf ,.txt"
        type="file"
        required
        size="lg"
        onChange={handleFileSelect}
      />

      <Button
        disabled={uploading}
        onClick={handleUploadMaterial}
        style={{
          marginTop: "16px",
        }}
      >
        {uploading ? "Uploading" : "Upload"}
      </Button>
    </ScaffoldLayout>
  );
}

export default UploadMaterialPage;
