//the payment page to navigate the payments of the students
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row, Table, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  getAllCollectedCash,
  getAllStudentsForPayment,
} from "../../../controllers/payments_controller";
import { showAlert } from "../../../controllers/toaster";
// import useStore from "../../../store/WebsiteStore";
import { studentPaymentInfoPageUrl } from "./StudentPaymentInfo";
import { isNumeric } from "../../../utils/utlsFunction.js";
// import authStore from "../../../store/auth_store";
import studentStore from "../../../store/studentStore";

export const paymentPageUrl = "/payments";

export function PaymentsPage() {
  const [students, setStudents] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const setCurrentStudentData = studentStore(
    (state) => state.setCurrentStudentData
  );

  const [totalCash, setTotalCash] = useState(0);

  useEffect(() => {
    async function getUsers() {
      try {
        setLoading(true);
        const data = await getAllStudentsForPayment();

        setStudents(data);

        //console.log()(data);
      } catch (error) {
        showAlert("Some error occured while getting students ");
        //console.log()(error);
        //show aler that some error occured
      } finally {
        setLoading(false);
      }
    }

    //call the getUsers function then
    getUsers();

    getTotalCash();
    return () => {};
  }, []);

  function getTotalCash() {
    getAllCollectedCash().then((cash) => {
      setTotalCash(cash);
      console.log("cash set", cash);
    });
  }

  //handles the students payment information stored in their payments collection ref
  function handleInfoClick(userData) {
    //console.log()("student data");
    //console.log()(userData);
    setCurrentStudentData(userData);
  }

  function searcher(student, searchField) {
    if (searchField.length === 0) return true;

    //first check if string is numeric or not
    if (!isNumeric(searchField)) {
      //if it's not numeric then we will do a regex check only then
      //create the regex to search
      try {
        const regex = new RegExp(`^${searchField}`, "i"); //and also keep it case insensitive
        return regex.test(student.username);
      } catch (error) {
        //console.log()("error in pattern matching");
        //console.log()(error);

        //if some error then return all the results as such
        return true;
      }
    }

    return student.class === parseInt(searchField);
  }

  function paymentIndicator(paymentDate) {
    if (paymentDate === undefined || paymentDate === null)
      return {
        text: "No Data",
        color: "orange",
      };
    //now if the payment date is not there then just return no data

    const millisecondsInOneDay = 1000 * 60 * 60 * 24;

    //we might get errors so try parsing the dates properly
    let date = "No data";
    try {
      //current date
      const currentDate = new Date().getTime();
      const lastPayedDate = paymentDate.toDate().getTime();

      const differenceInDays =
        (currentDate - lastPayedDate) / millisecondsInOneDay;

      //now show the due date with the red color if the days exceed by 30 days
      if (differenceInDays > 30) {
        //console.log()("payment overdue !!!");
        const overdueDays = Math.floor(differenceInDays - 30);
        return {
          text: `Payment overdue by ${overdueDays} days`,
          color: "red",
        };
      }

      date = paymentDate.toDate().toDateString();
    } catch (error) {
      //if there is an error then just simply return a red color and no data
      //console.log()("error in date conversion", error);
      return {
        text: "No data",
        color: "grey",
      };
    }

    //otherwise check the payment date with the current month and check if it's due more than 30 days then return the due date
    //this returns when there are no overdues so return the date and color green
    return {
      text: date,
      color: "green",
    };
  }

  return (
    <div
      style={{
        marginTop: "16px",
        marginLeft: "5%",
        marginRight: "5%",
      }}
    >
      {/* <Row>
          <Col>
            <Button onClick={getUsers}> Get students</Button>
          </Col>
        </Row> */}

      <Row
        style={{
          marginBottom: "16px",
        }}
      >
        <Col md="auto">
          <Form.Control
            style={{
              fontSize: "20px",
            }}
            placeholder="Search..."
            onChange={(event) => {
              ////console.log()("setting class to" + event.currentTarget.value);
              setSearchField(event.currentTarget.value);
            }}
          ></Form.Control>
        </Col>
      </Row>
      <h4>Total cash : {totalCash} </h4>
      <Row>
        {loading ? (
          <div
            style={{
              marginTop: "16px",
              display: "grid",
              alignContent: true,
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <h4>Loading...</h4>
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sno.</th>

                <th>Name</th>
                <th>Class</th>
                <th>Mobile no.</th>

                {/* <th>School</th> */}
                <th>Last Payment</th>
                {/* <th>More Info</th> */}
              </tr>
            </thead>

            <tbody>
              {students.map((student, index) => {
                if (searcher(student, searchField))
                  return (
                    <tr key={student.id}>
                      <td>{index + 1}</td>
                      <td>
                        <h6>
                          <Link
                            onClick={() => handleInfoClick(student)}
                            to={studentPaymentInfoPageUrl}
                          >
                            <h5>{student.username}</h5>
                          </Link>

                          {/* {student.username} */}
                        </h6>
                      </td>
                      <td>{student.class}</td>
                      {/* <td>{student.school}</td> */}

                      <td>{student.mobileNo}</td>
                      <td>
                        <div
                          style={{
                            color: paymentIndicator(student.lastPayed).color,
                          }}
                        >
                          <h6>{paymentIndicator(student.lastPayed).text}</h6>
                        </div>
                      </td>

                      {/* <td>
                        <Link
                          onClick={() => handleInfoClick(student)}
                          to={studentPaymentInfoPageUrl}
                        >
                          Info
                        </Link>
                      </td> */}
                    </tr>
                  );
                else return null;
              })}
            </tbody>
          </Table>
        )}
      </Row>
    </div>
  );
}

export default PaymentsPage;
