import { Navigate, Outlet } from "react-router-dom";
import useStore from "../store/WebsiteStore";

function TeacherRoutes({ children, ...rest }) {
  const userRoleType = useStore((state) => state.userRoleType);

  return userRoleType === "teacher" ? <Outlet /> : <Navigate to="/" />; //redirect to the dashboard if not auth then take them to the auth page
}

export default TeacherRoutes;
