import { useEffect, useState, useRef } from "react";
import {
  Button,
  Row,
  Card,
  Col,
  Form,
  InputGroup,
  // Badge,
} from "react-bootstrap";
// import useStore from "../../../store/WebsiteStore";
import { useNavigate } from "react-router-dom";
import {
  addStudentPayment,
  deletePayment,
  getStudentPaymentInfo,
} from "../../../controllers/payments_controller";
import studentStore from "../../../store/studentStore";
import CustomFormInputUI from "../../../components/ui/CustomFormUI";
import { Timestamp } from "@firebase/firestore";
import { showAlert } from "../../../controllers/toaster";
export const studentPaymentInfoPageUrl = "/payment-info-student";

export function StudentPaymentInfoPage() {
  const navigate = useNavigate();
  const currentStudentData = studentStore((state) => state.currentStudentData);
  function navigateBack() {
    navigate(-1);
  }

  const [loadingPayments, setLoadingPayments] = useState(false);
  const [payments, setPayments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const amountRef = useRef(0);
  const [paymentMode, setPaymentMode] = useState("online");
  const [addingPayment, setAddingPayment] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const allYears = [
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
    2027, 2028,
  ]; //TODO: dude correct it in the future !! lol

  const date = new Date();
  // //console.log()("date", date);
  const allDays = [];
  // const [month, setMonth] = useState(allMonths[date.getMonth()]);
  const [monthIndexSelected, setMonthIndex] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [day, setDay] = useState(date.getDate());

  const currentDate = new Date();
  const [datePaymentAdded, setDatePaymentAdded] = useState(
    `${currentDate.toISOString().split("T")[0]}`
  );

  //console.log()(datePaymentAdded);

  for (var i = 1; i <= 31; i++) {
    allDays.push(i);
  }

  useEffect(() => {
    const getPayments = async () => {
      setLoadingPayments(true);
      // //console.log()(currentStudentData.id);
      const data = await getStudentPaymentInfo(currentStudentData.id);
      // //console.log()("loaded payments data", data);
      setPayments(data);
      setLoadingPayments(false);
    };

    getPayments();

    return () => { };
  }, [refresh, currentStudentData.id]);

  async function handleAddPayment() {
    try {
      const newDate = new Date(year, monthIndexSelected, day);
      const amount = parseInt(amountRef.current.value);
      const paymentData = {
        forMonth: allMonths[monthIndexSelected],
        forYear: year,
        forDay: day,
        addedAt: Timestamp.fromDate(new Date(datePaymentAdded)),
        forBillDate: Timestamp.fromDate(newDate),
        paymentMode: paymentMode,
        amount: amount,
        madeBy: currentStudentData.username,
        payerFirebaseId: currentStudentData.id,
      };

      // console.log(paymentData);
      //also do the validation as well
      if (amountRef.current.value.length === 0 || amount <= 0) {
        showAlert("Please enter the amount !!", 4000);
        return;
      } else {
        await addStudentPayment(
          currentStudentData,
          paymentData,
          setAddingPayment
        );
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log("error in parsing data", error);
    }
  }

  async function handleDeletePayment(paymentData) {
    await deletePayment(paymentData, setDeleting, currentStudentData);
    setRefresh(!refresh); //refresh the fetching of the payment
  }

  return (
    <div
      style={{
        marginTop: "16px",
        marginLeft: "5%",
        marginRight: "5%",
      }}
    >
      <Button onClick={navigateBack}>Go back</Button>

      <Row>
        <Col xs={7}>
          <Card
            style={
              {
                // marginTop: "16px",
                // marginBottom: "8px",
              }
            }
          >
            <Card.Body>
              <h2>{currentStudentData.username}</h2>

              <Row>
                <h6>{currentStudentData.mobileNo}</h6>
              </Row>
            </Card.Body>
            <Row>{/* show uis to add payment of the student  */}</Row>
          </Card>

          {/* <h6>Select the payment month and year</h6> */}
          <CustomFormInputUI
            title="₹"
            placeholder={"Enter amount in ₹ "}
            reffer={amountRef}
          />

          <div
            style={{
              marginTop: "8px",
              marginBottom: "16px",
            }}
          >
            <Row>
              <Col>
                <h5>When was the payment given</h5>
              </Col>
              <Col>
                <input
                  // defaultValue={"2023-01-16"}
                  defaultValue={datePaymentAdded}
                  // defaultValue={datePaymentAdded.toDateString()}
                  type="date"
                  onChange={(event) => {
                    //console.log()(typeof event.currentTarget.value);
                    setDatePaymentAdded(event.currentTarget.value);
                  }}
                />
              </Col>
            </Row>
          </div>

          <h5>Add the payment for month and year</h5>

          <Row>
            <Col>
              <InputGroup
                style={
                  {
                    // marginTop: "16px",
                    // marginBottom: "8px",
                  }
                }
              >
                <InputGroup.Text>Day</InputGroup.Text>
                <Form.Select
                  onChange={(e) => {
                    // //console.log()(e.target.value);
                    // setClassSelected(e.target.value);
                    setDay(e.target.value);
                  }}
                  defaultValue={day}
                >
                  {allDays.map((singleDay, dayIndex) => {
                    return (
                      <option key={singleDay} value={singleDay}>
                        {singleDay}
                      </option>
                    );
                  })}
                </Form.Select>
              </InputGroup>
            </Col>

            <Col>
              <InputGroup>
                <InputGroup.Text>Month</InputGroup.Text>
                <Form.Select
                  onChange={(e) => {
                    // //console.log()(e.target.value);
                    setMonthIndex(e.target.value);
                    console.log(e.target.value);
                    // setClassSelected(e.target.value);
                  }}
                  defaultValue={monthIndexSelected}
                >
                  {allMonths.map((singleMonth, monthIndex) => {
                    return (
                      <option
                        key={monthIndex}
                        value={monthIndex}
                        onChange={() => {
                          // //console.log()(singleMonth);
                        }}
                      >
                        {allMonths[monthIndex]}
                      </option>
                    );
                  })}
                </Form.Select>
              </InputGroup>
            </Col>

            <Col>
              <InputGroup
                style={
                  {
                    // marginTop: "16px",
                    // marginBottom: "8px",
                  }
                }
              >
                <InputGroup.Text>Year</InputGroup.Text>
                <Form.Select
                  onChange={(e) => {
                    // //console.log()(e.target.value);
                    // setClassSelected(e.target.value);
                    setYear(e.target.value);
                  }}
                  defaultValue={year}
                >
                  {allYears.map((singleYear, yeaIndex) => {
                    return (
                      <option key={singleYear} value={singleYear}>
                        {singleYear}
                      </option>
                    );
                  })}
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>

          <InputGroup
            style={{
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            <InputGroup.Text>Payment mode</InputGroup.Text>
            <Form.Select
              onChange={(e) => {
                // //console.log()(e.target.value);
                // setClassSelected(e.target.value);
                setPaymentMode(e.target.value);
              }}
              defaultValue={year}
            >
              <option key={"online"} value={"online"}>
                Online payment
              </option>
              <option key={"cash"} value={"cash"}>
                Cash payment
              </option>
            </Form.Select>
          </InputGroup>
          <Button
            onClick={handleAddPayment}
            variant={addingPayment ? "secondary" : "success"}
            disabled={addingPayment}
          >
            {addingPayment ? "Adding payment please wait..." : "Add Payment"}
          </Button>
        </Col>

        <Col>
          <div>
            {loadingPayments ? (
              <p>Loading payments...</p>
            ) : payments.length === 0 ? (
              <h4>No payments to show</h4>
            ) : (
              <Row>
                {payments.map((payment, index) => {
                  return (
                    <div key={payment.id}>
                      <Card key={payment.id}>
                        <Card.Body>
                          <Row>
                            <h3
                              style={{
                                color: "green",
                              }}
                            >
                              ₹ {payment.amount}
                            </h3>
                          </Row>
                          <Row>
                            <Col>
                              <h6>
                                {payment.forMonth} - {payment.forYear}
                              </h6>
                            </Col>
                            <Col md="auto">
                              <h6>{payment.paymentMode}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <b>{payment.addedAt.toDate().toDateString()}</b>
                            </Col>
                            <Col>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleDeletePayment(payment)}
                                disabled={deleting}
                              >
                                Archive Payment
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default StudentPaymentInfoPage;
