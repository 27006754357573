import { MouseEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/WebsiteStore";
import { showAlert } from "../../controllers/toaster";
import {
  handleGooglSignUpOrLogin,
  handleSignUp,
} from "../../controllers/auth_Controller";
import "react-toastify/dist/ReactToastify.css";
import { Button, Row, Col, Form } from "react-bootstrap";
import InputField from "../../components/ui/InputField";
import { sendUserDetailsToFirebase } from "../../controllers/student_controller";
import classes from "../../css_modules/AuthPage.module.css";
import { Timestamp } from "firebase/firestore";

import {
  // sendLoginAnalyticsToDiscord,
  sendSignUpEvent,
} from "../../controllers/discord_sender";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase-config";
import { connectStorageEmulator } from "firebase/storage";

function SignUpComponent({ clickedSignUp, setClickedSignUp }) {
  const userNameRef = useRef();
  const passwordRef = useRef();
  const schoolRef = useRef();
  const mobileNoRef = useRef();
  const nameRef = useRef();

  const [classSelected, setClassSelected] = useState(8);
  const [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showPasswordHelpText, setShowPasswordHelpText] = useState(false);


  const navigate = useNavigate();

  const setCurrentUserId = useStore((state) => state.setCurrentUserId);
  const currentUserData = useStore((state) => state.currentUserData);
  const setShowNavBarComplete = useStore(
    (state) => state.setShowNavBarComplete
  );

  const setCurrentUserData = useStore((state) => state.setCurrentUserData);

  const classNumbers = [];

  for (var c = 2; c < 13; c++) {
    classNumbers.push(c);
  }

  //checks the data if it's correct
  function checkData() {
    //console.log()("checking details");
    // const username = userNameRef.current.value.length;
    // const password = passwordRef.current.value.length;
    const school = schoolRef.current.value.length;
    const mobile = mobileNoRef.current.value.length;
    const name = nameRef.current.value.length;

    if (school === 0 || mobile === 0 || name === 0) {
      showAlert("Please fill all details", 2000);
      return false;
    }

    return true;
  }

  const saveSignUpDetails = async (event) => {
    //change to the login page
    event.preventDefault();

    setLoading(true);

    try {
      if (checkData() && currentUserData) {
        // const signUpResponse = await handleGooglSignUpOrLogin();

        // const ifNewUser = signUpResponse.isNewUser;
        // if (!ifNewUser) {
        //   showAlert("You already have an account please login ", 10000);
        //   return;
        // }
        // if (signUpResponse !== "error") {
        console.log("Saving the provided configuration");

        const userData = {
          class: parseInt(classSelected, 10),
          email: currentUserData.user.email,
          mobileNo: mobileNoRef.current.value,
          role: "student", //cause right now sign up is being done for students only
          school: schoolRef.current.value,
          username: nameRef.current.value,
          id: currentUserData.user.uid,
          createdAt: Timestamp.fromDate(new Date()),
          activeUser: true,
          lastPayed: null,
          imageUrl: currentUserData.user.photoURL,
        };
        //and then go to the home page
        //send the user data to the firebase
        await sendUserDetailsToFirebase(userData);

        //these are analytics related things
        await sendSignUpEvent(currentUserData.uid, userData);
        logEvent(analytics, `User signed up ${currentUserData.uid} ${userData}`);

        //TODO: Buggy not working
        // sendSignUpLog(signUpResponse.id, nameRef.current.value); //TODO: Enable in production app

        setCurrentUserData({ ...userData, loggedIn: true });
        setCurrentUserId(currentUserData.user.uid); //set the uid of that user

        setShowNavBarComplete(true); //show the complete navbar
        console.log("navigated to the dashboard page now");
        navigate("/"); //also set the current user data
        // }
      }
    } catch (error) {
      showAlert("Some error occured while sign up");
      console.log(error);
      logEvent(analytics, "Error occurred in sign up");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="container">
        <div className={classes.container}>
          {/* <h1>Ayachi Academy</h1> */}
          <h3>Please fill your details before continuing</h3>
        </div>
        <div className={classes.inputContainer}>
          <form>
            <InputField
              refer={nameRef}
              type="text"
              hint="Enter your full name"
              title={"Your name"}
            />
            <InputField
              refer={schoolRef}
              type="text"
              hint="Enter school name"
              title={"School Name"}
            />

            <InputField
              refer={mobileNoRef}
              type="number"
              hint="Enter mobile number"
              title={"Mobile Number"}
            />

            <p className={classes.classPadder}>Choose your class</p>

            <div className={classes.padder}>
              <Form.Select
                onChange={(e) => {
                  //console.log()(e.target.value);
                  setClassSelected(e.target.value);
                }}
                defaultValue={classSelected}
              >
                {classNumbers.map((classNumber) => {
                  return (
                    <option
                      key={classNumber}
                      value={classNumber}
                      onChange={() => {
                        //console.log()(classNumber);
                      }}
                    >
                      {classNumber}
                    </option>
                  );
                })}
              </Form.Select>
            </div>

            <div className={classes.signUpButton}>
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                onClick={(event) => {
                  saveSignUpDetails(event);
                }}
              >
                {loading ? "Saving account information..." : "Continue"}
              </Button>
            </div>
            {/* make the sigup button for this page */}
            <Row>
              <Col xs={5}>
                <hr />
              </Col>
              <Col>
                <p
                  style={{
                    marginTop: "2%",
                    textAlign: "center",
                  }}
                >
                  Or
                </p>
              </Col>
              <Col xs={5}>
                <hr />
              </Col>
            </Row>

            <div
              className={classes.container}
              style={{
                marginBottom: "10%",
              }}
            >
              <Button
                className="btn btn-danger"
                onClick={(event) => {
                  event.preventDefault();
                  setClickedSignUp(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpComponent;
