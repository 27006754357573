import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { Button, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import { handleLogOut } from "../../controllers/auth_Controller";

import { makeQuizPageUrl } from "../../pages/teacher side/quiz maker/MakeQuizPage";

import { teacherMakeAnnouncementUrl } from "../../pages/teacher side/announcements/MakeAnnouncementPage";

import { paymentPageUrl } from "../../pages/teacher side/payments/PaymentPage";

import {
  onlineQuizMakerUrl,
  showAllStudentsPageUrl,
  uploadMaterialPageUrl,
} from "../../utils/all_routes";

import useStore from "../../store/WebsiteStore";
import { makeAssignmentUrl } from "../../utils/all_routes";
import { timeTableEditorPageUrl } from "../../pages/teacher side/time-table-editor/TimeTableEditorPage";
import { updateAttendancePageUrl } from "../../pages/teacher side/attendance/AttendanceUpdatePage";
// import authStore from "../../store/auth_store";

function TeacherNavBar({ sticky, onlyShowBanner }) {
  const navigate = useNavigate();

  const setCurrentUserId = useStore((state) => state.setCurrentUserId);

  const setCurrentUserData = useStore((state) => state.setCurrentUserData);

  const setShowNavBarComplete = useStore(
    (state) => state.setShowNavBarComplete
  );

  function handleStudentsAcamdeics(event) {
    event.preventDefault();
    navigate(showAllStudentsPageUrl);
  }

  function handleAnnouncement(event) {
    event.preventDefault();
    navigate(teacherMakeAnnouncementUrl);
  }

  function handleTimeTableEdit(event) {
    event.preventDefault();
    navigate(timeTableEditorPageUrl);
  }

  function handleMakeQuiz(event) {
    event.preventDefault();

    navigate(makeQuizPageUrl);
  }

  function handlePageNavigation(event, url) {
    event.preventDefault();
    navigate(url);
  }

  async function logoutHandler() {
    //first clear all the data stored inside the useStore
    //firstly the user id
    setShowNavBarComplete(false); //reset to default
    setCurrentUserId("");
    setCurrentUserData({ userRoleType: "student" });

    localStorage.removeItem("ayachi-authentication");
    localStorage.removeItem("ayachi-website-storer");
    localStorage.removeItem("ayachi-student-history");
    localStorage.removeItem("userId");

    navigate("/login");
    await handleLogOut();
  }

  function handleMyAccount() {
    navigate("/my-account");
  }

  function handlePaymentClick() {
    navigate(paymentPageUrl);
  }

  function handleMakeAssignment() {
    navigate(makeAssignmentUrl);
  }

  function handleOnlineQuizMake() {
    navigate(onlineQuizMakerUrl);
  }

  function handleUploadMaterial() {
    navigate(uploadMaterialPageUrl);
  }

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      bg="dark"
      variant="dark"
      sticky={sticky}
    >
      <Container
        style={{
          marginLeft: "0px",
        }}
      >
        <Navbar.Brand>
          <Container>
            <Row>Ayachi Academy</Row>

            {!onlyShowBanner ? (
              <Row
                style={{
                  fontSize: "15px",
                  color: "grey",
                }}
              >
                (Teacher)
              </Row>
            ) : null}
          </Container>
        </Navbar.Brand>

        {onlyShowBanner === false ? (
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        ) : null}

        {onlyShowBanner === false ? (
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">

              {/* Not enabling it right now */}
              {/* <Nav.Link onClick={handleAnnouncement}> */}
              {/*   <div>Make Announcement</div> */}
              {/* </Nav.Link> */}

              <Nav.Link onClick={(event) => { handlePageNavigation(event, updateAttendancePageUrl) }}>
                <div>Attendance</div>
              </Nav.Link>


              <Nav.Link onClick={handleTimeTableEdit}>
                <div>Edit TimeTable</div>
              </Nav.Link>

              <NavDropdown title="Quizzes">
                <NavDropdown.Item onClick={handleMakeQuiz}>
                  Upload Offline Quiz
                </NavDropdown.Item>

                <NavDropdown.Item onClick={handleOnlineQuizMake}>
                  Create Online Quiz
                </NavDropdown.Item>
                {/* <NavDropdown.Divider></NavDropdown.Divider> */}

                {/* <NavDropdown.Item>Assign Quiz</NavDropdown.Item> */}
              </NavDropdown>

              <Nav.Link onClick={handleMakeAssignment}>
                Make an assignment
              </Nav.Link>

              {/* <NavDropdown title="Assignments">
                <NavDropdown.Item>Create Assignment</NavDropdown.Item>
                <NavDropdown.Item>Evaluate Assignments</NavDropdown.Item>
              </NavDropdown> */}

              {/* <Nav.Link onClick={handleMakeQuiz}>
                <div>Make Quizzes</div>
              </Nav.Link> */}
              {/* <Nav.Link>
                <div>Make Assignments</div>
              </Nav.Link> */}

              <Nav.Link onClick={handleUploadMaterial}>
                <div>Upload Material</div>
              </Nav.Link>

              <Nav.Link onClick={handleStudentsAcamdeics}>
                <div>Student Academics</div>
              </Nav.Link>

              <Nav.Link onClick={handlePaymentClick}>
                <div>Payments</div>
              </Nav.Link>
            </Nav>

            <Nav>
              <NavDropdown title="Account" id="collasible-nav-dropdown">
                <NavDropdown.Item onClick={handleMyAccount}>
                  My Account
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Button onClick={logoutHandler}>Log Out</Button>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        ) : null}
      </Container>
    </Navbar>
  );
}

export default TeacherNavBar;
