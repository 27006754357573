import { collection, doc, DocumentReference, Firestore, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase-config";
import { AttendanceRecord } from "../models/student_attendance_model";

export function getStudentAttendanceDetails() {

}

export async function getStudentsAttendanceDetailsForDate(date: string) {
  console.log("the date is ", date)
  const usersRef = collection(db, "users");

  // Query with orderBy and where clause
  // const q = query(usersRef, where("activeUser", "==", true), orderBy("class", "desc"));
  const q = query(usersRef, where("activeUser", "==", true), where("role", "==", "student"),
    orderBy("class", "desc")
  );
  const usersSnapshot = await getDocs(q);

  const attendanceResults: AttendanceRecord[] = [];

  // Use Promise.all to fetch attendance records concurrently
  const attendancePromises = usersSnapshot.docs.map(async (userDoc) => {
    try {
      const userId = userDoc.id;
      const userData = userDoc.data();

      // Reference to the user's attendance document for the specific date

      // const docRef = `users/${userId}/attendance/${date}`;
      const docRef = `users/${userId}/attendance/${date}`;
      // console.log("doc ref", docRef)
      const attendanceRef = doc(db, docRef);
      const attendanceDoc = await getDoc(attendanceRef);

      // console.log("the doc ref is", docRef);

      if (attendanceDoc.exists()) {
        const attendanceData = attendanceDoc.data();
        attendanceResults.push({
          userId: userId,
          userName: userData.username,
          email: userData.email,
          class: userData.class,
          isPresent: attendanceData.isPresent,
          notes: attendanceData!.notes || null,
          isHoliday: attendanceData.isHoliday
        });
      } else {
        const attendanceData =
        {
          userId: userId,
          userName: userData.username,
          email: userData.email,
          class: userData.class,
          isPresent: false,  // or 'absent' if you assume no record means absent
          notes: null,
          isHoliday: false
        }


        attendanceResults.push(attendanceData);

        //also update the documents of those students whose data is not there 
        setDoc(attendanceRef, attendanceData)
      }
    } catch (error) {
      console.log("some error occurred while fetching attendance records", error);
    }
  });
  // Wait for all attendance records to be fetched
  await Promise.all(attendancePromises);
  return attendanceResults;
}


/**
 * Update the attendance of the students
 * */
export async function updateAttendanceOfStudent(userId: string, attendanceDate: string, _isPresent: boolean, _isHoliday: boolean) {
  const docRef = `users/${userId}/attendance/${attendanceDate}`;
  const attendanceRef = doc(db, docRef);

  //then update the attendance of the student 
  updateDoc(attendanceRef, {
    isPresent: _isHoliday ? null : _isPresent,
    isHoliday: _isHoliday
  })
}
