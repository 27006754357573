import React from "react";
import { AssingnedUser } from "../../../models/assignment_model";
import { Card, Row, Col, Badge, Button } from "react-bootstrap";

interface AssignedUserProps {
  assignedPerson: AssingnedUser;
  assignmentId: string;
  isRemoveOperation: boolean;
  isAssignmentPristine: boolean;
  handleRemoveAssignmentFromStudent: (
    assignmentId: string,
    studentId: string,
    isAddBack: boolean
  ) => void;
  handleEditAssignmentForStudent: (
    assignmentId: string,
    studentId: string
  ) => void;
}

const AssignedUserCard: React.FC<AssignedUserProps> = ({
  assignedPerson,
  assignmentId,
  isRemoveOperation,
  isAssignmentPristine,
  handleRemoveAssignmentFromStudent,
  handleEditAssignmentForStudent,
}) => {
  //   console.log("edit function is ?", handleEditAssignmentForStudent);
  return (
    <div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Badge>
                <div
                  style={{
                    fontSize: "20px",
                  }}
                >
                  {assignedPerson.username}
                </div>
              </Badge>
            </Col>

            {isRemoveOperation ? (
              <Col md="auto">
                <Button
                  size="sm"
                  disabled={isAssignmentPristine}
                  variant="warning"
                  onClick={() => {
                    handleEditAssignmentForStudent(
                      assignmentId,
                      assignedPerson.id
                    );
                  }}
                >
                  Modify
                </Button>
              </Col>
            ) : null}

            <Col md="auto">
              <Button
                size="sm"
                disabled={isAssignmentPristine}
                variant={isRemoveOperation ? "danger" : "success"}
                color="white"
                onClick={() => {
                  handleRemoveAssignmentFromStudent(
                    assignmentId,
                    assignedPerson.id,
                    isRemoveOperation
                  );
                }}
              >
                {isRemoveOperation ? "Unassign" : "Re-Assign"}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AssignedUserCard;
