function InputField({ refer, type, hint, title, style, onClickFunction }) {
  return (
    <div
      className="form-group"
      style={{
        marginTop: "8px",
        marginBottom: "4px",
      }}
    >
      <label htmlFor="exampleInputEmail1">{title}</label>
      <input
        onClick={onClickFunction}
        ref={refer}
        type={type}
        className="form-control"
        placeholder={hint}
        autoComplete="on"
      />
    </div>
  );
}

export default InputField;
