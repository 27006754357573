import { Modal, Container, Row, Button, Form } from "react-bootstrap";

import {
  addNewQuizToDB,
  deleteQuizFromDB,
} from "../../controllers/quiz_controller";

import { useRef } from "react";

import { showAlert } from "../../controllers/toaster";

import useStore from "../../store/WebsiteStore";

export const ShowQuizAddModal = ({ showModal, setShowModal }) => {
  const addNewQuiz = useStore((state) => state.addNewQuiz);
  const quizTitleRef = useRef("");

  //   const [showModal, setShowModal] = useState(false);

  function handleClose() {
    setShowModal(false);
  }

  async function handleConfirm(event) {
    //check quiz title length
    
    event.preventDefault();
    if (quizTitleRef.current.value.length > 3) {
      const title = quizTitleRef.current.value;

      //also send it to the backend
      setShowModal(false);
      const firebaseQuizId = await addNewQuizToDB(title);
      //console.log()("quiz id received in modal shower " + firebaseQuizId);

      addNewQuiz({
        title: title,
        firebaseQuizId: firebaseQuizId,
      });
    } else {
      showAlert("Please add some title to the quiz ", 2000);
    }
  }

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeLabel="Close">
        <Modal.Title>Enter Title to proceed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <p>Enter quiz name </p>
          </Row>

          <Row>
            <Form.Control
              type="text"
              placeholder="Quiz Name"
              ref={quizTitleRef}
            />
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleConfirm} type="submit">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ShowQuizDeleteModal = ({
  showQuizDelete,
  setShowQuizDelete,
  quizTitle,
  firebaseQuizId,
}) => {
  const deleteQuiz = useStore((state) => state.deleteQuiz);
  async function handleConfirm() {
    //check quiz title length
    setShowQuizDelete(false);

    showAlert("Deleting quiz please wait...", 3000);

    const deleteStatus = await deleteQuizFromDB(firebaseQuizId);

    if (deleteStatus === "ok") {
      deleteQuiz(firebaseQuizId);
    } else {
      showAlert("Please check your internet connection !!", 4000);
    }
  }

  function handleClose() {
    setShowQuizDelete(false);
  }

  return (
    <Modal show={showQuizDelete} onHide={handleClose}>
      <Modal.Header closeLabel="Close">
        <Modal.Title
          style={{
            color: "red",
          }}
        >
          You are about to delete a quiz
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <p>Are you really sure to delete this quiz ?? </p>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
