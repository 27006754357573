// import React, { useRef, useEffect } from "react";
// import {
//   Button,
//   Container,
//   Form,
//   FormControl,
//   Modal,
//   Row,
// } from "react-bootstrap";
// import { Days, TimeTableSlotEntry } from "../../../models/time_table_model";

// interface ShowModalProps {
//   showModal: boolean;
//   forClass: string;
//   forDay: Days;
//   setShowModal: (modalShow: boolean) => void;
//   onConfirm: (slotEntry: TimeTableSlotEntry) => void;
//   existingEntry: TimeTableSlotEntry | null;
// }

// const AddSlotEntryModal: React.FC<ShowModalProps> = ({
//   showModal,
//   forClass,
//   forDay,
//   setShowModal,
//   onConfirm,
//   existingEntry,
// }) => {
//   // Refs
//   const subjectNameRef = useRef<HTMLInputElement>(null);
//   const teacherNameRef = useRef<HTMLInputElement>(null);
//   const fromTimingRef = useRef<HTMLInputElement>(null);
//   const toTimingRef = useRef<HTMLInputElement>(null);

//   useEffect(() => {
//     if (existingEntry) {
//       if (subjectNameRef.current)
//         subjectNameRef.current.value = existingEntry.subjectName;
//       if (teacherNameRef.current)
//         teacherNameRef.current.value = existingEntry.teacherName;
//       if (fromTimingRef.current)
//         fromTimingRef.current.value = existingEntry.fromTime;
//       if (toTimingRef.current) toTimingRef.current.value = existingEntry.toTime;
//     }
//   }, [existingEntry]);

//   function handleClose() {
//     setShowModal(false);
//   }

//   function handleConfirm() {
//     // Access the current values of the refs
//     const subjectName = subjectNameRef.current?.value || "";
//     const teacherName = teacherNameRef.current?.value || "";
//     const fromTime = fromTimingRef.current?.value || "";
//     const toTime = toTimingRef.current?.value || "";

//     // Call the onConfirm callback with the input data
//     const slotEntry: TimeTableSlotEntry = {
//       teacherName: teacherName,
//       subjectName: subjectName,
//       fromTime: fromTime,
//       class: forClass,
//       forDay: forDay,
//       toTime: toTime,
//     };
//     onConfirm(slotEntry); //entry will be passed to the parent funtion and then the
//     //parent function can get the class and the weekday to edit the timeslot entry
//     // Close the modal
//     handleClose();
//   }

//   return (
//     <div>
//       <Modal show={showModal} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             {existingEntry ? "Edit Slot Details" : "Add Slot Details"}
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Container>
//             <Row>
//               <p>
//                 {existingEntry ? "Edit slot details" : "Enter slot details"}
//               </p>
//             </Row>
//             <Row>
//               <FormControl
//                 ref={subjectNameRef}
//                 placeholder="Subject Name"
//               ></FormControl>
//               <Form.Control
//                 ref={teacherNameRef}
//                 type="text"
//                 placeholder="Teacher Name"
//               />
//               <Form.Control
//                 ref={fromTimingRef}
//                 type="time"
//                 placeholder="From Time"
//               />
//               <Form.Control
//                 ref={toTimingRef}
//                 type="time"
//                 placeholder="To Time"
//               />
//             </Row>
//           </Container>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleConfirm} type="button">
//             Confirm
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default AddSlotEntryModal;

import React, { useRef, useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
  Alert,
  Col,
} from "react-bootstrap";
import { Days, TimeTableSlotEntry } from "../../../models/time_table_model";

interface ShowModalProps {
  showModal: boolean;
  forClass: string;
  forDay: Days;
  setShowModal: (modalShow: boolean) => void;
  onConfirm: (slotEntry: TimeTableSlotEntry, isNewEntry: boolean) => void;
  existingEntry: TimeTableSlotEntry | null;
}

const AddSlotEntryModal: React.FC<ShowModalProps> = ({
  showModal,
  forClass,
  forDay,
  setShowModal,
  onConfirm,
  existingEntry,
}) => {
  // Refs
  const subjectNameRef = useRef<HTMLInputElement>(null);
  const teacherNameRef = useRef<HTMLInputElement>(null);
  const fromTimingRef = useRef<HTMLInputElement>(null);
  const toTimingRef = useRef<HTMLInputElement>(null);

  // State for validation errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (existingEntry) {
      if (subjectNameRef.current)
        subjectNameRef.current.value = existingEntry.subjectName;
      if (teacherNameRef.current)
        teacherNameRef.current.value = existingEntry.teacherName;
      if (fromTimingRef.current)
        fromTimingRef.current.value = existingEntry.fromTime;
      if (toTimingRef.current) toTimingRef.current.value = existingEntry.toTime;
    }
    setErrors({});
  }, [existingEntry, showModal]);

  function handleClose() {
    setShowModal(false);
  }

  function handleConfirm() {
    // Access the current values of the refs
    const subjectName = subjectNameRef.current?.value || "";
    const teacherName = teacherNameRef.current?.value || "";
    const fromTime = fromTimingRef.current?.value || "";
    const toTime = toTimingRef.current?.value || "";

    // Validate inputs
    const newErrors: { [key: string]: string } = {};
    if (!subjectName) newErrors.subjectName = "Subject Name is required";
    if (!teacherName) newErrors.teacherName = "Teacher Name is required";
    if (!fromTime) newErrors.fromTime = "From Time is required";
    if (!toTime) newErrors.toTime = "To Time is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsValid(false);
    } else {
      setErrors({});
      setIsValid(true);

      // Call the onConfirm callback with the input data
      const slotEntry: TimeTableSlotEntry = {
        teacherName: teacherName,
        subjectName: subjectName,
        fromTime: fromTime,
        class: forClass,
        forDay: forDay,
        toTime: toTime,
      };
      onConfirm(slotEntry, existingEntry ? false : true);

      // Close the modal
      handleClose();
    }
  }

  /**
   * Parses the date in the required format for the input field
   * Given the date in the format 2:15 PM or as such it will properly parse it 
   */
  function parseDate(date: string) {

  }

  return (
    <Container>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {existingEntry ? "Edit Slot Details" : "Add Slot Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* <Row>
              <p>
                {existingEntry ? "Edit slot details" : "Enter slot details"}
              </p>
            </Row> */}
            <Row>
              <Col>
                <FormControl
                  ref={subjectNameRef}
                  placeholder="Subject Name"
                  isInvalid={!!errors.subjectName}
                />
                <Form.Control
                  ref={teacherNameRef}
                  type="text"
                  placeholder="Teacher Name"
                  isInvalid={!!errors.teacherName}
                />
                <Container>
                  <Row>
                    <Col>
                      <Form.Control
                        ref={fromTimingRef}
                        type="time"
                        placeholder="From Time"
                        isInvalid={!!errors.fromTime}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        ref={toTimingRef}
                        type="time"
                        placeholder="To Time"
                        isInvalid={!!errors.toTime}
                      />
                    </Col>
                  </Row>
                </Container>
                {/* Validation errors */}
                {Object.keys(errors).length > 0 && (
                  <Alert variant="danger">
                    {Object.values(errors).map((error, index) => (
                      <div key={index}>{error}</div>
                    ))}
                  </Alert>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            type="button"
          // disabled={!isValid}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AddSlotEntryModal;
