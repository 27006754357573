import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase-config";
import { TimeTable } from "../models/time_table_model";

export async function getTimeTable(): Promise<TimeTable | null> {
  try {
    const docRef = doc(db, "timeTable", "timeTable"); // Document name is "timeTable"
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const timeTableData = docSnap.data() as TimeTable;
      return timeTableData;
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting time table:", error);
    // throw error; // You might want to handle the error in your UI
    return null;
  }
}

export async function saveTimeTable(timeTable: TimeTable): Promise<boolean> {
  try {
    const docRef = doc(db, "timeTable", "timeTable"); // Document name is "timeTable"
    await setDoc(docRef, timeTable);
    console.log("Time table saved successfully!");
    return true;
  } catch (error) {
    console.error("Error saving time table:", error);
    // throw error; // You might want to handle the error in your UI
    return false;
  }
}
