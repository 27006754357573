export const studentAssignmentUrl = "/assignments";
export const makeAssignmentUrl = "/make-assignment";
export const editAssignmentUrl = "/edit-assignment";

export const discordLoginHook =
  "https://discord.com/api/webhooks/1062097076890128524/_d0Jxis_lU0L_nzu4OWBNDUdHsXT7Udh5ZeI_9vMA4Cj-8ks7ytvjqP3DmeZMV8OLLCt";

export const discordSignUpHook =
  "https://discord.com/api/webhooks/1062733653312032771/HYZEfQr_sWONDJpkjCTFZQYZWIqgxzE89j7tBtIPaAWZ5b5mAyxrpSpXNOpmyjE8DwLJ";

export const discordSubmittedAssignmentHook = "";

export const showAllStudentsPageUrl = "/all-students";

export const singleStudentAcaademicInfoUrl = "/single-student-info";

export const onlineQuizMakerUrl = "/online-quiz-main-page";

export const onlineQuizMakerCompoUrl = "/make-online-quiz";

export const landingPage = "/"; //the base route for the first time users to see

export const uploadMaterialPageUrl = "/upload-material"



//send webhook notification for a payment that get's added or generated
export const discordPaymentsURL =
  "https://discord.com/api/webhooks/1063590733614952520/wfLJ615nm0o5XZ0HnxvptGQ-dtzraC9aaGbXGzwPoUEbuOZ09oaOpC8ca2jV6LtyatDB";

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
