import { Days } from "../models/time_table_model";

export const weekDays: Days[] = [
  Days.MON,
  Days.TUE,
  Days.WED,
  Days.THU,
  Days.FRI,
  Days.SAT,
  Days.SUN
];
