import { logEvent } from "firebase/analytics";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { analytics, db } from "../firebase-config";
import { showAlert } from "./toaster";

export async function uploadMaterial(files, forClass, title, setUploading) {
  try {
    // setUploading(true);
    const hasMultiple = files.length > 1 ? true : false;

    // //first create the document then get that id and make the class material from it
    const uploadMaterialData = {
      forClass: forClass,
      multipleFiles: hasMultiple,
      createdAt: Timestamp.fromDate(new Date()),
      title: title,
    };
    console.log(uploadMaterialData);
    const uploadMaterialCollection = collection(
      db,
      "classMaterials",
      forClass,
      "materials"
    );

    const docData = await addDoc(uploadMaterialCollection, uploadMaterialData);

    console.log("doc added", docData.id);

    //also update the document with the number of uploads

    const classDoucment = doc(db, "classMaterials", forClass);

    const checkIfDoc = await checkIdDocThere(classDoucment);
    console.log("is doc there ? ", checkIfDoc);

    if (checkIfDoc === false) {
      //create a new document and set the material count as 1
      await setDoc(classDoucment, {
        materialCount: 1,
      });
    } else {
      //else get that materialcount field and update that value
      const docData = await getDoc(classDoucment);
      const materialCount = docData.data().materialCount;

      await updateDoc(classDoucment, {
        materialCount: materialCount + 1,
      });
    }

    // console.log(docData.id);

    // //first upload it on the storage
    // //   const uploadRef = ref(`class materials/${forClass}`);
  } catch (error) {
    console.log("upload material error ", error);
    showAlert("Some error occured while uploading the material", 3000);

    logEvent(analytics, "upload-material-error", {
      error: error,
      message: "Error occured in uploadng the material",
    });
    //log the error as well
  } finally {
    setUploading(false);
  }
}

async function checkIdDocThere(docRef) {
  const document = await getDoc(docRef);
  const docData = document.data();
  return docData === undefined ? false : true;
}
