import { Container, Form, Row, Button, Col } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { showSuccess, showAlert } from "../../../controllers/toaster";
import CustomFormInputUI from "../../../components/ui/CustomFormUI";
import useStore from "../../../store/WebsiteStore";
import { assignQuizToStudentUrl } from "./AssignQuizToStudents";
import { useNavigate } from "react-router-dom";
import {
  updateQuizDetailsOnDB,
  uploadQuestionPaper,
} from "../../../controllers/quiz_controller";
// import { handleFileSelection } from "../../../controllers/file_chooser";
import CustomFilePicker from "../../../components/ui/CustomFilePicker";

import DatePicker from "../../../components/ui/DatePicker";

const rowStyle = {
  marginBottom: "16px",
};

function OfflineQuizMakeComponent() {
  //============================================================ all quiz refs  =======================================================================
  const fileRef = useRef();
  const quizMarksRef = useRef();
  const durationRef = useRef();
  const chaptersRef = useRef();
  const classRef = useRef();
  const subjectRef = useRef();
  const quizTitleRef = useRef();

  ///=================================================== all quiz refs  =======================================================================

  //=================================================== state management start ============================================================

  const updateQuiz = useStore((state) => state.updateQuiz);
  const currentQuizIndex = useStore((state) => state.currentQuizIndex);
  const currentQuizData = useStore((state) => state.currentQuizData);
  const setSingleQuizData = useStore((state) => state.setSingleQuizData);

  const [pickedDate, setPickedDate] = useState(currentQuizData.startsAt);

  const readableDate = currentQuizData.editableDate;

  // const [pickedDate, setPickedDate] = useState(currentQuizData.editableDate);

  //=================================================== state management end ============================================================

  //=================================================== local state start ============================================================

  const [validated, setValidated] = useState(
    currentQuizData.isUploadedOnStorage
  );

  const [hasQuestionPaper, setHasQuestionPaper] = useState(
    currentQuizData.hasQuestionPaper !== undefined
      ? currentQuizData.hasQuestionPaper
      : true
  );

  // const [uploadProgress, setUploadProgress] = useState(0); //show the question paper upload progress

  const [fileUploaded, setFileUploaded] = useState(
    currentQuizData.isUploadedOnStorage
  ); //use this boolean itself to just check if the quiz has been already updated earlier on the firebase storage

  const [qPaperSelectedBool, setQPaperSelectedBool] = useState(
    currentQuizData.isUploadedOnStorage
  );

  const [isQuizUpdatedEver, setIsQuizUpdatedEver] = useState(
    currentQuizData.isUploadedOnStorage !== undefined
      ? currentQuizData.isUploadedOnStorage
      : false
  ); // we will use this to check that if the quiz has anytime been saved to the storage and to the backedn

  const [fileUploading, setFileUploading] = useState(false);

  const [storagePath, setStoragePath] = useState(
    currentQuizData.storagePathCloud
  );

  const [qPaperFileName, setqPaperFileName] = useState(
    currentQuizData.fileName
  );

  const [qPaperFile, setqPaperFile] = useState("nothin");

  const [updatigQuiz, setUpdatigQuiz] = useState(false);

  //=================================================== local state end ============================================================

  //==============================navigation start ==================================

  const navigate = useNavigate(); //navigate to other pages

  //=================================== navigation end ====================

  useEffect(() => {
    //this function will only get called once the data array chagnes
    //console.log()("use effect fired");
    return () => { };
  }, []);

  // function handleFileUpload() {
  //   //console.log()("printing file");
  //   //console.log()(fileRef.current.value);

  //   //console.log()("converting date");

  //   //console.log()(new Date(pickedDate.toString()));
  // }

  const handleSubmit = async (event) => {
    setUpdatigQuiz(true);
    //console.log()("handle submit");
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        //console.log()("form not valid ");
        event.preventDefault();
        event.stopPropagation();

        showAlert("Please fill all the details in order to proceed", 3000);
      } else {
        if (fileUploaded === false && hasQuestionPaper) {
          showAlert("Please re-upload the file again");
          return;
        }
        //all validation checks have been cleared
        //else the quiz is valid
        event.preventDefault();

        //also check the date that the user has picked if it's empty then also show the error
        if (pickedDate === undefined) {
          //picked date has to be valid on
          showAlert("Please pick the date as well", 3000);
        } else {
          await stateUpdateQuizandInDB();
          setUpdatigQuiz(false);
        }
      }

      setValidated(true);
    } catch (error) {
      //console.log()(error);
    } finally {
      setUpdatigQuiz(false);
    }
  };

  const stateUpdateQuizandInDB = async () => {
    //create the data object

    try {
      const chapterArray = chaptersRef.current.value
        .split(",")
        .map((element) => element.trim())
        .filter((s) => s.length !== 0);

      //   const numberMarks = parseInt(quizMarksRef.current.value, 10);

      //   const numberDuration = parseInt(durationRef.current.value, 10);
      //console.log()("data mutated");
      const data = {
        ...currentQuizData,
        title: quizTitleRef.current.value,
        class: parseInt(classRef.current.value, 10),
        chapters: chapterArray,
        marks: parseInt(quizMarksRef.current.value, 10),
        duration: durationRef.current.value, //TODO: While sending to the backend convert it to seconds
        // localFilePath: fileRef.current.files,
        subject: subjectRef.current.value,
        startsAt: pickedDate,
        mode: "offline",
        updatedAt: Date().toString(),
        storagePathCloud: hasQuestionPaper ? storagePath : null,
        isUploadedOnStorage: fileUploaded,
        fileName: qPaperFileName === undefined ? null : qPaperFileName,
        hasQuestionPaper: hasQuestionPaper,
      };

      //console.log()("data made");
      //console.log()(data);
      delete data['editableDate'] //was causing the null value issue

      const updateStatus = await updateQuizDetailsOnDB(data);

      if (updateStatus === "ok") {
        updateQuiz(data, currentQuizIndex);
        setSingleQuizData(data); //saves the updates locally
        showSuccess(
          "Your quiz has been created !!\n Now click on the assign now button to assign the quizzes to the students of your class",
          4000
        );

        setIsQuizUpdatedEver(true);
      } else {
        showAlert(
          "Updating quiz failed , check your internet connection !! ",
          4000
        );
      }

      //also update that the quiz has been again saved to the backend
    } catch (error) {
      //console.log()(
      // "some error occured on handle submit of offline quiz component"
      // );
      //console.log()(error);
    }
  };

  const handleAssignNow = (event) => {
    //now by passing the assign quiz portion directly do it from the
    //first check the form validity

    //console.log()("assign to students");
    if (validated) {
      //then only proceed else return
      navigate(assignQuizToStudentUrl);
    } else {
      showAlert("Click on the Save Quiz Button first ", 5000);
    }
  };

  async function handleQuestionPaperSelect(event) {
    const files = event.target.files;
    setQPaperSelectedBool(true);
    setqPaperFile(files[0]);
    setqPaperFileName(files[0].name);
    //try to upload the file to the firebase storage
    //console.log()(files[0]);
  }

  async function handleQuestionPaperUpload() {
    const response = await uploadQuestionPaper(
      qPaperFile,
      // setUploadProgress,
      currentQuizData,
      setFileUploading
    );

    setStoragePath(response);

    setFileUploaded(true);

    //console.log()(response);
  }

  //re-upload feature
  async function handleFileChange() {
    setQPaperSelectedBool(false); //give the user to again upload the file
    setFileUploaded(false);
  }

  function editQuizAgain(event) {
    //change the state for the quiz to that of the quiz is not updated on the storage

    event.preventDefault();
    //console.log()("editing quiz again");
    setIsQuizUpdatedEver(false);
    // setFileUploaded(false); //set that the file has not beedn uploaded to the storage
    // setQPaperSelectedBool(false); //again give the option to repload the file
  }

  function handleIncludeQPaper() {
    //toggles for includeing question paper or not
    setHasQuestionPaper(!hasQuestionPaper);
  }

  return (
    <div>
      <Container>
        <Row style={rowStyle}>
          <Col>
            <Form
              noValidate
              validated={validated}
              onSubmit={
                isQuizUpdatedEver === false ? handleSubmit : editQuizAgain
              }
            >
              <Form.Group as={Col} md={8} controlId="validationCustomUsername">
                <CustomFormInputUI
                  title={"Title"}
                  type="text"
                  placeholder="Enter quiz title"
                  reffer={quizTitleRef}
                  someDefaultValue={currentQuizData.title}
                  isFormDisabled={isQuizUpdatedEver}
                />
                <CustomFormInputUI
                  title={"Marks"}
                  type="number"
                  placeholder="Enter quiz max marks"
                  reffer={quizMarksRef}
                  someDefaultValue={currentQuizData.marks}
                  isFormDisabled={isQuizUpdatedEver}
                />

                <CustomFormInputUI
                  title={"Class"}
                  type="number"
                  placeholder="Enter the class for quiz"
                  reffer={classRef}
                  someDefaultValue={currentQuizData.class}
                  isFormDisabled={isQuizUpdatedEver}
                />

                <CustomFormInputUI
                  title={"Chapters"}
                  type="text"
                  placeholder="Structre of atom , motion , speed"
                  reffer={chaptersRef}
                  isFormDisabled={isQuizUpdatedEver}
                  someDefaultValue={currentQuizData.chapters}
                />

                <CustomFormInputUI
                  title={"Duration"}
                  type="number"
                  placeholder="120 (in minutes)"
                  reffer={durationRef}
                  someDefaultValue={currentQuizData.duration}
                  isFormDisabled={isQuizUpdatedEver}
                />

                <CustomFormInputUI
                  title={"Subject"}
                  type="text"
                  placeholder="Economics"
                  reffer={subjectRef}
                  someDefaultValue={currentQuizData.subject}
                  isFormDisabled={isQuizUpdatedEver}
                />

                <CustomFilePicker
                  labelText={"Include Question Paper"}
                  handleIncludeQPaper={handleIncludeQPaper}
                  hasQuestionPaper={hasQuestionPaper}
                  includeQPaper={hasQuestionPaper}
                  fileUploaded={fileUploaded}
                  qPaperSelectedBool={qPaperSelectedBool}
                  qPaperFileName={qPaperFileName}
                  handleFileChange={handleFileChange}
                  handleQuestionPaperSelect={handleQuestionPaperSelect}
                  handleQuestionPaperUpload={handleQuestionPaperUpload}
                  fileUploading={fileUploading}
                  fileRef={fileRef}
                  storagePathCloud={storagePath}
                  isQuizUpdatedEver={isQuizUpdatedEver}
                />
                <DatePicker
                  labelText={"Select Quiz Date"}
                  initialValue={readableDate}
                  isQuizUpdatedEver={isQuizUpdatedEver}
                  setPickedDate={setPickedDate}
                  pickedDate={pickedDate}
                />
              </Form.Group>
              <Button variant="success" type="submit">
                {isQuizUpdatedEver === true
                  ? "Edit Quiz Again"
                  : updatigQuiz
                    ? "Updating quiz..."
                    : "Update Quiz"}
              </Button>
              {/* only render this when the quiz was already uploaded some time ago then only */}

              {isQuizUpdatedEver ? (
                <Button
                  variant="warning"
                  type="button"
                  style={{
                    marginLeft: "16px",
                  }}
                  onClick={handleAssignNow}
                >
                  Assign Now
                </Button>
              ) : null}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OfflineQuizMakeComponent;
