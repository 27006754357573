import { Route, Routes, useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import AuthPage from "./pages/auth side/AuthPage";
import StudentsListPage from "./pages/teacher side/students view page/StudentsList";
import StudentInfoPage from "./pages/teacher side/students view page/StudentInfoPage";
import CompletedQuizPageOnlineMode from "./components/quiz specific/CompletedQuizPageOnlineMode";
import StudentMyAcademics from "./pages/student side/MyAcademics";
import StudentHomePage from "./pages/student side/StudentHomePage";
import StudentMyAccount from "./pages/student side/MyAccount";
// import StudentNavBar from "./components/layout/StudentNavBar";

import ForgotPasswordComponent from "./pages/auth side/ForgotPassword";
// import TeacherNavBar from "./components/layout/TeacherNavBar";
import {
  makeQuizPageUrl,
  QuizDashBoard,
} from "./pages/teacher side/quiz maker/MakeQuizPage";

import {
  quizMakerComponentUrl,
  QuizMakerComponent,
} from "./pages/teacher side/quiz maker/QuizMakerComponent";

import {
  teacherMakeAnnouncementUrl,
  MakeAnnouncementPage,
} from "./pages/teacher side/announcements/MakeAnnouncementPage";

import {
  AssignQuizToStudentsPage,
  assignQuizToStudentUrl,
} from "./pages/teacher side/quiz maker/AssignQuizToStudents";

import {
  completedQuizPageOfflineModeUrl,
  CompletedQuizPageOfflineMode,
} from "./components/quiz specific/CompletedQuizPageOfflineMode";

import {
  paymentPageUrl,
  PaymentsPage,
} from "./pages/teacher side/payments/PaymentPage";

import {
  studentPaymentInfoPageUrl,
  StudentPaymentInfoPage,
} from "./pages/teacher side/payments/StudentPaymentInfo";
import PrivateRoutes from "./utils/PrivateRoute";
import TeacherRoutes from "./utils/TeacherRoute";

import {
  studentAssignmentUrl,
  makeAssignmentUrl,
  editAssignmentUrl,
  showAllStudentsPageUrl,
  singleStudentAcaademicInfoUrl,
  onlineQuizMakerUrl,
  onlineQuizMakerCompoUrl,
  uploadMaterialPageUrl,
} from "./utils/all_routes";
import StudentAssignmentList from "./pages/student side/assignments/StudentsAssignmentListPage";
import MakeAssignmentPage from "./pages/teacher side/make assignments/MakeAssignmentPage";
import EditAssignmentPage from "./pages/teacher side/make assignments/EditAssignmentPage";
import { getStudentDetails } from "./controllers/student_controller";
import ShowAllStudentsComponent from "./pages/teacher side/student academics/AllStudentsPage";
import SingleStudentInfoPage from "./pages/teacher side/student academics/SingleStudentInfoPage";
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  User,
} from "firebase/auth";
import LoadingPage from "./pages/landing side/loadingPage";

import OnlineQuizMakeComponent from "./pages/teacher side/quiz maker/online/OnlineQuizMakeTeacher";
import OnlineQuizMakerMainCompo from "./pages/teacher side/quiz maker/online/OnlineQuizMakerCompo";
import { logEvent } from "firebase/analytics";
import { analytics, onMessageListener, tokenGetter } from "./firebase-config";
import { showSuccess } from "./controllers/toaster";
import useStore from "./store/WebsiteStore";

import { sendLoginAnalyticsToDiscord } from "./controllers/discord_sender";
import UploadMaterialPage from "./pages/teacher side/class materials upload/UploadPage";
import PaymentInvoiceDashboard from "./pages/student side/payment-invoice/PaymentInvoiceDashboard";
import { UserAuthDetails } from "./models/user_auth_deatils";
import { isNewUser } from "./controllers/auth_Controller";
import TimeTableEditorPage, {
  timeTableEditorPageUrl,
} from "./pages/teacher side/time-table-editor/TimeTableEditorPage";
import AssignmentDetailPage, {
  studentViewAssignmentUrl,
} from "./pages/student side/assignments/AssignmentDetailPage";
import { addNotificationIdToFirebase } from "./controllers/notification_controller";
import AttendanceUpdatePage, { updateAttendancePageUrl } from "./pages/teacher side/attendance/AttendanceUpdatePage";
// import { Container } from "react-bootstrap";
// import WelcomePage from "./pages/landing side/WelcomePage";

//log the website opening event

function App() {
  //also render this component then only if the user has already signed in then fetch from the stored string
  //from local storage

  const navigate = useNavigate();
  const [authUser, setUser] = useState<User>();
  const [authLoading, setAuthLoading] = useState(true); //just set it to false till the auth is loading
  const [isTokenFound, setTokenFound] = useState(false);
  const [token, setToken] = useState("");
  const setCurrentUserData = useStore((state) => state.setCurrentUserData);
  const [requstForProperSignup, setRequstForProperSignup] = useState(false); //initially it will be false

  const currentUserData = useStore<UserAuthDetails | undefined>(
    (state) => state.currentUserData
  );

  useEffect(() => {
    console.log("app page loaded first");

    // set the persistence here of the user

    const auth = getAuth();
    const userPersisterandLoader = async () => {
      await setPersistence(auth, browserLocalPersistence);
      //first set te persistence
      onAuthStateChanged(auth, async (user) => {
        //console.log()("user status changed");
        if (user) {
          //gets the token only if the user has logged in
          tokenGetterForUs();
          //console.log()(user);
          //also log in the event
          // analytics.setUserId(user.uid);
          const getUserData: UserAuthDetails = await getStudentDetails(
            user.uid
          );
          //checking if user has not completed their sign up
          if (isNewUser(getUserData)) {
            console.log("setting the new user flow check");

            setRequstForProperSignup(true);
            console.log(
              "the req for proper sign up variable value is ",
              requstForProperSignup
            );
          } else {
            console.log("this user already exists");

            setUser(user);
            //this is a proper user do the rest of the process as same
            console.log("the user data is", getUserData);
            setCurrentUserData(getUserData);
            //also add the notify token of the user in fireabse
            sendLoginAnalyticsToDiscord(
              getUserData,
              token
            );
            logEvent(analytics, "login", {
              userId: user.uid,
              name: getUserData.username,
            });
            if (isTokenFound) {
              logEvent(analytics, "token-granted", {
                name: getUserData.username,
                id: getUserData.id,
                token: token,
              });
            }
            //irrespective of users add the notification ids of the users into firestore
            //send this token to the firebasee to use it later
            // addNotificationIdToFirebase(getUserData.id, token);

            navigate("/"); //also set the current user data
          }
          //console.log()("event logged");
        } else {
          // const ip = await getClientIp();
          // console.log(ip);
          // try {
          //   logEvent(analytics, "websiteVisit", {
          //     ip: ip,
          //   });
          // } catch (error) {
          //   console.log("error in ip fetch or event logging", error);
          // }
        }
        setAuthLoading(false); //just set the auth loading to false until the auth is loading
      });
    };

    async function tokenGetterForUs() {
      try {
        await tokenGetter(setTokenFound, setToken);
        if (isTokenFound) {
          //console.log()("token has been found");
        }
      } catch (error) {
        //console.log()("token error ", error);
      }
    }

    onMessageListener()
      .then((payload) => {
        // setNotification({
        //   title: payload.notification.title,
        //   body: payload.notification.body,
        // });
        //console.log()("notification received ", payload);
        showSuccess(payload.notification.title, 10000);
      })
      .catch((err) => { });
    // .catch((err) => //console.log()("failed: ", err));
    userPersisterandLoader();
    return () => { };
  }, [
    isTokenFound,
    setCurrentUserData,
    // currentUserData,
    currentUserData && currentUserData?.username,
    // currentUserData!.id,
    token,
    requstForProperSignup,
  ]);

  return (
    <>
      {/* <button onClick={() => navigator.clipboard.writeText(token)}>COPY</button> */}
      {authLoading ? (
        <LoadingPage></LoadingPage>
      ) : (
        <div className="App">
          <Routes>
            <Route
              element={
                <PrivateRoutes isUserAuth={authUser} children={undefined} />
              }
            >
              <Route path="/" element={<StudentHomePage />} />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordComponent />}
              />
              {/* =======================  STUDENT SIDE ROUTE ===================== */}
              <Route
                path="/studentAcademics"
                element={<StudentMyAcademics />}
              />

              <Route
                path="/paymentInvoice"
                element={<PaymentInvoiceDashboard />}
              />
              {/* Show the payment invoice for the students only teacher has the dashboard itself */}
              <Route path="/my-account" element={<StudentMyAccount />} />
              {/* =======================  SHARED ROUTE (STUDENT AND TEACHER) ===================== */}
              <Route
                path="/completedQuizPage"
                element={<CompletedQuizPageOnlineMode goBackUrl={undefined} />}
              />
              <Route
                path={completedQuizPageOfflineModeUrl}
                element={<CompletedQuizPageOfflineMode />}
              />
              <Route path="/studentsList" element={<StudentsListPage />} />

              <Route
                path={studentAssignmentUrl}
                element={<StudentAssignmentList />}
              />

              <Route
                path={studentViewAssignmentUrl}
                element={<AssignmentDetailPage />}
              />

              {/* All the teacher routes come under the private routes */}
              <Route element={<TeacherRoutes children={undefined} />}>
                {/* =======================  TEACHER SIDE ROUTE ===================== */}
                <Route path="/student-info" element={<StudentInfoPage />} />

                <Route
                  path={makeAssignmentUrl}
                  element={<MakeAssignmentPage />}
                />
                <Route
                  path={editAssignmentUrl}
                  element={<EditAssignmentPage />}
                />
                <Route path={makeQuizPageUrl} element={<QuizDashBoard />} />
                <Route
                  path={quizMakerComponentUrl}
                  element={<QuizMakerComponent />}
                />

                <Route
                  path={onlineQuizMakerUrl}
                  element={<OnlineQuizMakeComponent />}
                />

                <Route
                  path={onlineQuizMakerCompoUrl}
                  element={<OnlineQuizMakerMainCompo />}
                />

                <Route
                  path={teacherMakeAnnouncementUrl}
                  element={<MakeAnnouncementPage />}
                />
                <Route
                  path={assignQuizToStudentUrl}
                  element={<AssignQuizToStudentsPage />}
                />
                <Route path={paymentPageUrl} element={<PaymentsPage />} />

                <Route
                  path={showAllStudentsPageUrl}
                  element={<ShowAllStudentsComponent />}
                />
                <Route
                  path={singleStudentAcaademicInfoUrl}
                  element={<SingleStudentInfoPage />}
                />

                <Route
                  path={studentPaymentInfoPageUrl}
                  element={<StudentPaymentInfoPage />}
                />

                <Route
                  path={uploadMaterialPageUrl}
                  element={<UploadMaterialPage />}
                />

                <Route
                  path={updateAttendancePageUrl}
                  element={<AttendanceUpdatePage />}
                />

              </Route>

              <Route
                path={timeTableEditorPageUrl}
                element={<TimeTableEditorPage />}
              />
            </Route>

            <Route
              path="/login"
              element={
                <AuthPage requestForProperSignUp={requstForProperSignup} />
              }
            />

            {/* <Route path="/" element={<WelcomePage />} /> */}
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
