//gets the student details given the user id

//call these functions in a useEffect hook

import { db } from "../firebase-config";

import { analytics } from "../firebase-config";

import {
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  orderBy,
  setDoc,
  updateDoc,
  Timestamp,
  DocumentReference,
  DocumentData,
} from "firebase/firestore";
import { formattedDate } from "./quiz_controller";
import { UserAuthDetails } from "../models/user_auth_deatils";
import { logEvent } from "firebase/analytics";

///Fetches the student details
export const getStudentDetails = async (
  userUID: string
): Promise<UserAuthDetails> => {
  //console.log()("Fetchin user data");
  const userDocument: DocumentReference<DocumentData> = doc(
    db,
    "users",
    userUID
  );
  // const q = query<UserAuthDetails>();
  const docData = await getDoc(userDocument);
  const filteredData: UserAuthDetails = {
    ...(docData.data() as UserAuthDetails),
    id: docData.id,
    loggedIn: true,
  };
  //print the doc data for some reference
  //console.log()(docData);
  //console.log()("User data fetched");
  return filteredData;
};

export const getStudentCompletedQuizzes = async (userId: string) => {
  //console.log()("fetching user completed quizzes");

  const coll = collection(db, "users", userId, "completedQuizzes");

  const q = query(coll, orderBy("startsAt", "desc"));

  const docs = await getDocs(q);

  const docDataFiltered = docs.docs.map((doc) => {
    try {
      const readableStartDate = doc.data().startsAt.toDate();
      return {
        ...doc.data(),
        id: doc.id,
        readableDate: formattedDate(readableStartDate),
      };
    } catch (error) {
      console.log("error in dates -> student controller", error);
      return null;
    }
  });

  // const docDataFiltered = docs.docs.map((doc) => {

  // })

  return docDataFiltered;
};

/**
 * This function is responsible for saving the user details in firebase
 *
 * @param userData This
 */
export const sendUserDetailsToFirebase = async (userData: UserAuthDetails) => {
  if (userData && userData.id) {
    const document = doc(db, "users", userData.id);
    logEvent(analytics, "new_user_added", { userData: userData });
    await setDoc(document, userData);
  }
};

export const updateStudentProfile = async (profileData: UserAuthDetails) => {
  try {
    if (profileData && profileData.id) {
      //always add in the try catch block
      const document = doc(db, "users", profileData.id);

      await updateDoc(document, {
        ...profileData,
        profileUpdatedAt: Timestamp.fromDate(new Date()),
      });
    }
  } catch (error) {
    console.log("updateStudentProfile error");
    logEvent(analytics, "failed_update_student_profile", { error: error });
  }
};

//the speaker to avoid confusion
//basically the speaker collection is the announcement channel

// var sub = "";
// export const announcementHandler = {
//   subber: (userId : string, setAnnouncements) => {
//     //console.log()("listening to announcements");
//     sub = onSnapshot(
//       collection(db, "users", userId, "speaker"),
//       (querySnapshot) => {
//         //console.log()("received snapshot");
//         // //console.log()(querySnapshot);
//         const announceMents = [];
//         querySnapshot.forEach((doc) => {
//           announceMents.push({ ...doc.data(), id: doc.id });
//         });

//         setAnnouncements(announceMents);
//         //console.log()("loaded announcements");
//         //console.log()(announceMents);
//       }
//     );
//   },

//   canceller: async () => {
//     //console.log()("cancelling");
//     await sub();
//     //console.log()(sub);
//   },
// };
