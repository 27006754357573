import { Button, Container, Row, Col, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEffect, useState, useRef } from "react";

import useStore from "../../../store/WebsiteStore";

import {
  getAllStudentsFromClassNumber,
  uploadAnswerSheet,
} from "../../../controllers/quiz_controller";
import { showAlert, showSuccess } from "../../../controllers/toaster";

export const assignQuizToStudentUrl = "/assign-quiz";
export function AssignQuizToStudentsPage() {
  //==================== navigation ===================

  const navigate = useNavigate(); //navigate to other pages

  // ================ state management

  const currentQuizData = useStore((state) => state.currentQuizData);

  //================================== local state
  const [students, setStudents] = useState([]);

  const [answersUploading, setAnswersUploading] = useState(false);

  const [fileRefs, setFileRef] = useState([]); //this will contain the OJBECT  of the files selected by the students so copy data from the student portion and add a file ref part in it

  const [
    enableAnswerUploadOfStudentsButton,
    setEnableAnswerUploadOfStudentsButton,
  ] = useState(false);

  // const inputFile = useRef("");

  const formRef = useRef();

  // const [selectedStudents, setSelectedStudents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      getAllStudentsFromClassNumber(currentQuizData.class).then((data) => {
        //map through all of the data and add a new file ref to it
        //console.log()(data);

        setStudents(data);
        setFileRef(new Array(data.length));
      });

      //console.log()("logging fresh current quiz data");
      //console.log()(currentQuizData);
    };

    fetchData();

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //===================================== handler functions
  function handleGoBack() {
    navigate(-1);
  }

  function handleConfirmSelection() {
    //handles the selection confirmation to upload the answer sheets of the students to the backend

    //console.log()("handleConfirmSelection");
    const checkAllStudentsFiles = checkStudentsFileSelectedOrNot();

    if (checkAllStudentsFiles.checkFiles === false) {
      setEnableAnswerUploadOfStudentsButton(false);
      return;
    }

    if (checkAllStudentsFiles.checkAtLeastOneStudentSelected === false) {
      setEnableAnswerUploadOfStudentsButton(false);
      showAlert("Please select at least one student", 3000);
      return;
    }
    //else proceed further
    //enable the file upload button option then
    setEnableAnswerUploadOfStudentsButton(true);
    //console.log()(formRef);
  }

  function checkStudentsFileSelectedOrNot() {
    var utilData = {
      checkFiles: true,
      checkAtLeastOneStudentSelected: false,
    };
    for (var i = 0; i < students.length; i++) {
      utilData.checkAtLeastOneStudentSelected =
        utilData.checkAtLeastOneStudentSelected || students[i].studentSelected; //or check to check it at least one is selected or not

      if (students[i].studentSelected) {
        //console.log()("students selected " + students[i].username);

        if (!students[i].studentFileSelected) {
          //console.log()("Select file for " + students[i].username);

          utilData.checkFiles = false;
          const content = (
            <div>
              Please select the file for <h3>{students[i].username}</h3>
            </div>
          );

          showAlert(content);
        }
      }
    }
    return utilData;
  }

  async function handleUploadAnswerSheets() {
    //first get the list of students whose answer sheets we have from the fileRefs array

    //this array will contain their files as well
    // const selectedStudents =
    //console.log()(fileRefs);

    //let the uploading begin !!
    setAnswersUploading(true);

    //console.log()(fileRefs);
    try {
      const resp = await uploadAnswerSheet(fileRefs, currentQuizData);

      if (resp === "error") {
        showAlert("Some error occured in uploading the file ", 3000);
      } else {
        showSuccess("All answer Sheets uploaded");
        navigate(-2); //just go to the quiz screen then
      }

      //iterate through the array of the file refs and only upload for the ones whos answers we need to upload

      //first uploading will be done and then that uploaded path will be sent to the firebase firstore function to update those fields in their quiz collection
    } catch (error) {
      //console.log()("error occured while uploading the answers ");
      //console.log()(error);
    } finally {
      setAnswersUploading(false); //even if no error just set it to false
    }
  }

  return (
    <div
      style={{
        marginTop: "16px",
      }}
    >
      <Container>
        {/* Assign Quiz to Students */}
        <Row>
          <Col>
            <Button onClick={handleGoBack}>Go back</Button>
          </Col>
        </Row>
        <hr />

        <Row
          style={{
            marginBottom: "16px",
          }}
        >
          <h2>{currentQuizData.title}</h2>
        </Row>
        <Button onClick={handleConfirmSelection}>Confirm Selection</Button>

        <Form
          //   key={}
          ref={formRef}
          style={{
            marginTop: "16px",
            marginBottom: "8px",
          }}
        >
          {students.map((student, index) => {
            return (
              <Card
                style={{
                  padding: "16px",
                  marginBottom: "16px",
                  fontSize: "18px",
                }}
              >
                <Row>
                  <Col>
                    <Form.Check
                      id={student.id}
                      value={student.id}
                      type="switch"
                      //   id="custom-switch"
                      label={student.username}
                      onClick={() => {
                        students[index].studentSelected =
                          !students[index].studentSelected;
                        setStudents([...students]);
                        setEnableAnswerUploadOfStudentsButton(false); //always toggle if off if a new user he tries to add

                        if (students[index].studentSelected === false) {
                          const newArray = [
                            ...fileRefs.slice(0, index),
                            undefined,
                            ...fileRefs.slice(index + 1),
                          ];

                          setFileRef(newArray);
                        }
                      }}
                    />
                  </Col>
                  {student.studentSelected === true ? (
                    <Row
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      <Col md="auto">
                        <Form.Control
                          placeholder="Enter marks"
                          type="number"
                          key={student.id}
                          onChange={(event) => {
                            //console.log()("marks" + event.currentTarget.value);

                            if (student.studentSelected) {
                              const scored = parseInt(
                                event.currentTarget.value,
                                10
                              );

                              //console.log()("scored");
                              setFileRef([
                                ...fileRefs.slice(0, index),
                                {
                                  ...fileRefs[index],
                                  scored: scored,
                                },
                                ...fileRefs.slice(index + 1),
                              ]);
                            }
                          }}
                        />
                      </Col>
                      <Col md="auto">
                        <Form.Control
                          onSelect={() => {
                            //console.log()("file selected");
                          }}
                          accept=".doc , .docx , .pdf , .txt"
                          type="file"
                          // ref={fileRef}
                          required
                          onChange={(event) => {
                            //this function is only then fired when there has been a file selection
                            setEnableAnswerUploadOfStudentsButton(false); //always toggle if off if a new user he tries to add
                            const files = event.target.files;

                            if (files[0] !== undefined) {
                              student.studentFileSelected = true; //make true for the students whose files got selected
                            } else {
                              student.studentFileSelected = false; //also make it false if the teacher cancels file selection
                            }

                            // setStudents([...students]); //also update the state to do the check
                            //console.log()(students);

                            //console.log()(files[0]);

                            //only update it for the ones who we had selected

                            var newArray = [
                              ...fileRefs.slice(0, index),
                              {
                                ...fileRefs[index],
                                id: student.id,
                                file: files[0],
                                name: student.username,
                              },
                              ...fileRefs.slice(index + 1),
                            ];

                            setFileRef(newArray);
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Row>
              </Card>
            );
          })}
          {enableAnswerUploadOfStudentsButton ? (
            <Button
              onClick={handleUploadAnswerSheets}
              disabled={answersUploading}
              variant={answersUploading ? "dark" : "primary"}
            >
              {answersUploading
                ? "Uploading answer sheets please wait..."
                : "Upload Answer Sheets now"}
            </Button>
          ) : null}
        </Form>
      </Container>
    </div>
  );
}

// export default AssignQuizToStudentsPage;
