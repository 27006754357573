import create from "zustand";

import { devtools, persist } from "zustand/middleware";
import { showSuccess } from "../controllers/toaster";
import { WebsiteStoreInterface } from "../models/website_global_state";
import { UserAuthDetails } from "../models/user_auth_deatils";

// const customHandler = (
//   set: (arg0: {
//     (state: any): { quizzesMade: any[] };
//     (state: any): { quizzesMade: any[] };
//     (state: any): { currentQuizIndex: number };
//     (state: any): { quizzesMade: any };
//     (state: any): { quizzesMade: any[] };
//     (state: any): { currentQuizData: any };
//     (state: any): { userRoleType: string };
//     (state: any): { showAllItemsOfNavBar: any };
//     (state: any): { currentUserId: string };
//     (state: any): any;
//     (state: any): { currentUserData: any; userRoleType: any };
//     (state: any): any;
//   }) => void,
//   get: () => { (): any; new (): any; currentUserId: any }
// ) => ({
//   currentUserData: {},

//   currentUserId: "",
//   completedQuizData: {
//     title: "Rishabh",
//   },

//   showAllItemsOfNavBar: false,
//   userRoleType: "student",

//   //===============================================================================================
//   quizzesMade: [],
//   assignQuizzesToStudents: [],
//   currentQuizTitle: "",
//   currentQuizIndex: 0,
//   currentQuizData: {},

//   setQuizzesMade: (quizzesData: any[]) => {
//     set((state: any) => {
//       return {
//         quizzesMade: quizzesData,
//       };
//     });
//   },

//   addNewQuiz: (quizData: any) => {
//     //adding the quiz of title
//     //console.log()("adding quiz of data " + quizData);

//     showSuccess("Quiz added !!", 2000);

//     set((state: any) => {
//       return {
//         quizzesMade: [quizData, ...state.quizzesMade],
//       };
//     });
//   },

//   setCurrentQuizIndex: (index: number) => {
//     //console.log()(`current quiz index selected ${index}`);
//     // //console.log()(`quiz title ${title}`);

//     set((state: any) => ({
//       currentQuizIndex: index,
//     }));
//   },

//   deleteQuiz: (firebaseId: any) => {
//     set((state: any) => ({
//       quizzesMade: state.quizzesMade.filter(
//         (element: { firebaseQuizId: any }) =>
//           element.firebaseQuizId !== firebaseId
//       ),
//     }));
//   },

//   updateQuiz: (quizData: any, index: number) => {
//     //console.log()("quiz update done");
//     //console.log()(quizData);
//     //console.log()("on index " + index);

//     set((state: any) => {
//       return {
//         quizzesMade: [
//           ...state.quizzesMade.slice(0, index),
//           quizData,
//           ...state.quizzesMade.slice(index + 1),
//         ],
//       };
//     });
//   },

//   setSingleQuizData: (quizData: any) => {
//     //console.log()("setting complete quiz data");
//     //console.log()(quizData);
//     set((state: any) => {
//       return { currentQuizData: quizData };
//     });
//   },

//   //===============================================================================================

//   setUserRoleToTeacher: () => {
//     set((state: any) => ({ userRoleType: "teacher" }));
//   },

//   setShowNavBarComplete: (status: any) => {
//     set((state: any) => ({ showAllItemsOfNavBar: status }));
//   },

//   getCurrentUserid: () => {
//     //console.log()("user requested user id");
//     //console.log()(get().currentUserId);
//     return get().currentUserId;
//   },

//   setCurrentUserId: (userId: string) => {
//     //console.log()("setting the user id given");

//     //set in local storage

//     //console.log()(userId);
//     //store in local storageset
//     localStorage.setItem("userId", userId);

//     //check local storage
//     const id = localStorage.getItem("userId");
//     //console.log()("id");
//     //console.log()(id);

//     set((state: any) => {
//       return { currentUserId: userId };
//     });
//   },

//   setNotifyToken: (notifyToken: any) => {
//     //console.log()("notify token set");
//     //console.log()(notifyToken);
//     set((state: any) => {
//       return {
//         ...state.currentUserData,
//         notifyToken: notifyToken,
//       };
//     });
//   },

//   setCurrentUserData: (userData: { role: any }) => {
//     //console.log()("user data set");
//     //console.log()(userData);
//     set((state: any) => {
//       return {
//         currentUserData: userData,

//         userRoleType: userData.role,
//       };
//     });
//   },

//   setCompletedQuizData: (quizData: any) => {
//     //console.log()("data set");
//     //console.log()(quizData);
//     set((state: any) => {
//       return { ...state, completedQuizData: quizData };
//     });
//   },
// });

// const useStore = create<WebsiteStoreInterface>(
//   // customHandler,
//   persist((set, get) => {

//   }, {
//     name: "ayachi-website-storer",
//     // storage: localStorage,
//   })
// );

// const useStore = create<WebsiteStoreInterface>(persist<WebsiteStoreInterface>(set, get) => ({
//   currentUserId: "",
// }));
const userStore = create<WebsiteStoreInterface>()(
  devtools(
    persist(
      (set, get) => ({
        //initial values of the store
        currentUserData: undefined,
        currentUserId: "",
        completedQuizData: {
          title: "Rishabh",
        },

        showAllItemsOfNavBar: false,
        userRoleType: "student",

        //===============================================================================================
        quizzesMade: [],
        assignQuizzesToStudents: [],
        currentQuizTitle: "",
        currentQuizIndex: 0,
        currentQuizData: {},

        setQuizzesMade: (quizzesData: any[]) => {
          set((state: any) => {
            return {
              quizzesMade: quizzesData,
            };
          });
        },

        addNewQuiz: (quizData: any) => {
          //adding the quiz of title
          //console.log()("adding quiz of data " + quizData);

          showSuccess("Quiz added !!", 2000);

          set((state: any) => {
            return {
              quizzesMade: [quizData, ...state.quizzesMade],
            };
          });
        },

        setCurrentQuizIndex: (index: number) => {
          //console.log()(`current quiz index selected ${index}`);
          // //console.log()(`quiz title ${title}`);

          set((state: any) => ({
            currentQuizIndex: index,
          }));
        },

        deleteQuiz: (firebaseId: any) => {
          set((state: any) => ({
            quizzesMade: state.quizzesMade.filter(
              (element: { firebaseQuizId: any }) =>
                element.firebaseQuizId !== firebaseId
            ),
          }));
        },

        updateQuiz: (quizData: any, index: number) => {
          //console.log()("quiz update done");
          //console.log()(quizData);
          //console.log()("on index " + index);

          set((state: any) => {
            return {
              quizzesMade: [
                ...state.quizzesMade.slice(0, index),
                quizData,
                ...state.quizzesMade.slice(index + 1),
              ],
            };
          });
        },

        setSingleQuizData: (quizData: any) => {
          //console.log()("setting complete quiz data");
          //console.log()(quizData);
          set((state: any) => {
            return { currentQuizData: quizData };
          });
        },

        //===============================================================================================

        setUserRoleToTeacher: () => {
          set((state: any) => ({ userRoleType: "teacher" }));
        },

        setShowNavBarComplete: (status: any) => {
          set((state: any) => ({ showAllItemsOfNavBar: status }));
        },

        getCurrentUserid: () => {
          //console.log()("user requested user id");
          //console.log()(get().currentUserId);
          return get().currentUserId;
        },

        setCurrentUserId: (userId: string) => {
          //console.log()("setting the user id given");

          //set in local storage

          //console.log()(userId);
          //store in local storageset
          localStorage.setItem("userId", userId);

          //check local storage
          const id = localStorage.getItem("userId");
          //console.log()("id");
          //console.log()(id);

          set((state: any) => {
            return { currentUserId: userId };
          });
        },

        setNotifyToken: (notifyToken: any) => {
          //console.log()("notify token set");
          //console.log()(notifyToken);
          set((state: any) => {
            return {
              ...state.currentUserData,
              notifyToken: notifyToken,
            };
          });
        },

        setCurrentUserData: (userData: UserAuthDetails) => {
          console.log("user data set is ", userData);
          //console.log()(userData);
          set((state: any) => {
            return {
              currentUserData: userData,
              userRoleType: userData.role,
            };
          });
        },

        setCompletedQuizData: (quizData: any) => {
          //console.log()("data set");
          //console.log()(quizData);
          set((state: any) => {
            return { ...state, completedQuizData: quizData };
          });
        },
      }),
      {
        name: "bear-storage",
      }
    )
  )
);
// customHandler,

export default userStore;

/* 

class: "20",
      createdAt: "2022-01-16T20:28:23.460329",
      email: "rishabhmishra23599@gmail.com",
      id: "rshE2EHAg8Qe4MVTqDsIdrOPOmI3",
      mobileNo: "9560196736",
      role: "student",
      school: "PDPS",
      username: "Rishabh Mishra",
*/
