import { logEvent } from "@firebase/analytics";
import { analytics } from "../firebase-config";
import {
  discordSignUpHook,
  discordLoginHook,
} from "../utils/all_routes";
import { UserAuthDetails } from "../models/user_auth_deatils";
import { addNotificationIdToFirebase } from "./notification_controller";
import { discordNotiBlackList } from "../utils/constants";

export const sendLoginAnalyticsToDiscord = (
  // userId?: string,
  // userName?: string,
  userDetails: UserAuthDetails,
  token?: string
) => {
  // try {
  //   // logEvent(analytics, `user logged in => ${userId} and ${userName}`);
  // } catch (error) {
  //   //console.log()("log event error");
  //   //console.log()(error);
  // }
  if (userDetails.id && discordNotiBlackList.includes(userDetails.id)) {
    //console.log()("don't send user is a testing user");
    return; //then don't send the noti
  }
  const loginEvent = new Date();

  const description = `${userDetails.username}(${userDetails.id}) - [${
    userDetails.email
  } - Class ${
    userDetails.class
  }]  logged in at ====> \n${loginEvent.toDateString()}-${loginEvent.toTimeString()} \n Notification Token : ${token}`;

  try {
    fetch(discordLoginHook, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        embeds: [
          {
            title: "User Logged in",
            description: description,
          },
        ],
      }),
    });

    //after sending to discord add into firebasde
    addNotificationIdToFirebase(userDetails.id, token);
  } catch (error) {
    logEvent(analytics, "discord sending failed ");
    logEvent(analytics, "discord_sending_failed", { error: error });

    //console.log()("error in sending to discord");
    //console.log()(error);
  }
};

export const sendSignUpEvent = async (userId: string, userData: any) => {
  try {
    logEvent(analytics, "sign_up", {
      ...userData,
    });
    // logEvent(analytics, `user logged in => ${userId} and ${userData}`);
  } catch (error) {
    // //console.log()("log event error");
    // //console.log()(error);
    logEvent(analytics, "Error in signing up");
    logEvent(analytics, "SignUp-Error", { error: error });
  }

  if (discordNotiBlackList.includes(userId)) {
    //console.log()("don't send user is a testing user");
    return; //then don't send the noti
  }
  const signUpEvent = new Date();

  const title = `${
    userData.username
  } joined the coaching with the following details} at ===> ${signUpEvent.toDateString()} - ${signUpEvent.toTimeString()}`;

  const description = `Class : ${userData.class}\nName : ${userData.username}\nSchool: ${userData.school}\nMobile : ${userData.mobileNo}`;

  try {
    fetch(discordSignUpHook, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        embeds: [
          {
            title: title,
            // description : userData
            description: description,
            // description: JSON.stringify(userData),
          },
        ],
      }),
    });
  } catch (error) {
    //console.log()("error in sending sign up event to discord");
    //console.log()(error);
  }
};
