import { useState } from "react";
import useStore from "../../store/WebsiteStore";
import classes from "../../css_modules/AuthPage.module.css";
import { showAlert, showSuccess } from "../../controllers/toaster";
import { Row, Col, Button } from "react-bootstrap";
// import { getStudentDetails } from "../../controllers/student_controller";
import googleLogo from "../../logos/google_logo.png";
import {
  handleGooglSignUpOrLogin,
  isNewUser,
} from "../../controllers/auth_Controller";

import { useNavigate } from "react-router-dom";
import { UserAuthDetails } from "../../models/user_auth_deatils";

function LoginComponent() {
  const setCurrentUserId = useStore((state) => state.setCurrentUserId);
  const setUserRoleToTeacher = useStore((state) => state.setUserRoleToTeacher);

  const currentUserData = useStore((state) => state.currentUserData);
  const setCurrentUserData = useStore((state) => state.setCurrentUserData);
  const [loading, setLoading] = useState(false);
  const setShowNavBarComplete = useStore(
    (state) => state.setShowNavBarComplete
  );
  const navigate = useNavigate(); //navigate to other pages

  const loginOrSignUpHandler = async (event: {
    preventDefault: () => void;
  }) => {
    console.log("login page loaded first");

    try {
      console.log("signing in ");
      setLoading(true);
      event.preventDefault();
      //but first check if all the values are present
      if (true) {
        const response: UserAuthDetails = await handleGooglSignUpOrLogin();

        if (response) {
          //this flow is for users who have not saved their details
          const isProvidedUserNew: boolean = isNewUser(response);
          if (isProvidedUserNew) {
            //then don't navigate to the home page first make the user navigate to the
            //sign up page (which is actually the details saving page)
            //however set the user details so that the sign up page can access
            //to create the user account
            setCurrentUserData(response);
          } else {
            setCurrentUserId(response.user.uid); //what I think is that by setting the user id we make a fetch call get the new user data
            setLoading(false);
            // sendLoginAnalyticsToDiscord(response.user.uid, getUserData.username);
            if (currentUserData && currentUserData.role === "teacher") {
              setUserRoleToTeacher();
              //avoid navigation and just change the state in app.js
            } else {
            }
            setCurrentUserData({ ...response, loggedIn: true });
            // sendLogInLog(userNameRef.current.value); //TODO: Enable in production environment
            showSuccess(`Welcome to Ayachi Academy !!`, 3000);
          }

          setShowNavBarComplete(true);
        }
      }
    } catch (error) {
      //console.log()("some error occured");
      showAlert("Some error occured while logging you in ", 4000);
      console.log(error);
      //console.log()(error);
    } finally {
      setLoading(false); //just stop the loading then
      navigate("/"); //even if the user is not logged in he will be redirected to this route and will go the login page
    }
  };

  return (
    <div className="container">
      <div className={classes.container}>
        <h1>Ayachi Academy</h1>
      </div>
      <div className={classes.inputContainer}>
        <form>
          <div className={classes.container}>
            <Button
              variant="light"
              disabled={loading}
              onClick={loginOrSignUpHandler}
            >
              <Row>
                <Col md="auto">
                  <img src={googleLogo} width="30px"></img>
                </Col>
                <Col md="auto">
                  <b>Sign In With Google</b>
                </Col>
              </Row>
            </Button>
          </div>

          {/* make the sigup button for this page */}
          {/* <Row>
              <Col xs={5}>
                <hr />
              </Col>
              <Col>
                <p
                  style={{
                    marginTop: "2%",
                    textAlign: "center",
                  }}
                >
                  Or
                </p>
              </Col>
              <Col xs={5}>
                <hr />
              </Col>
            </Row> */}

          {/* <div className={classes.container}>
              <button
                type="submit"
                className="btn btn-warning"
                onClick={(event) => {
                  event.preventDefault();
                  setClickedSignUp(true);

                  //TODO: SIGN UP IMPLEMENTATION HERE
                }}
              >
                Sign Up
              </button>
            </div> */}
        </form>
      </div>
    </div>
  );
}

export default LoginComponent;
