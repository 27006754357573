//the payment page to navigate the payments of the students
import { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Row, Table, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getStudentsFromClassGroup } from "../../../controllers/payments_controller";
import { showAlert } from "../../../controllers/toaster";
import studentStore from "../../../store/studentStore";
import { singleStudentAcaademicInfoUrl } from "../../../utils/all_routes";

export default function ShowAllStudentsComponent() {
  const [students, setStudents] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);
  const setCurrentStudentData = studentStore(
    (state) => state.setCurrentStudentData
  );

  useEffect(() => {
    async function getUsers() {
      try {
        setLoading(true);
        const data = await getStudentsFromClassGroup();

        setStudents(data);

        //console.log()(data);
      } catch (error) {
        showAlert("Some error occured while getting students ");
        //console.log()(error);
        //show aler that some error occured
      } finally {
        setLoading(false);
      }
    }

    //call the getUsers function then
    getUsers();
    return () => {};
  }, []);

  //handles the students payment information stored in their payments collection ref

  //searches the given parametrs (for ex => a class or a student's name)
  //and returns true for mathcing searches
  function searcher(student, searchField) {
    if (searchField.length === 0) return true;

    //first check if string is numeric or not
    if (!isNumeric(searchField)) {
      //if it's not numeric then we will do a regex check only then
      //create the regex to search
      try {
        const regex = new RegExp(`^${searchField}`, "i"); //and also keep it case insensitive
        return regex.test(student.username);
      } catch (error) {
        //console.log()("error in pattern matching");
        //console.log()(error);

        //if some error then return all the results as such
        return true;
      }
    }

    return student.class === parseInt(searchField);
  }
  function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  function handleStudentSelected(data) {
    setCurrentStudentData(data);
    //I need to make a different selection button for the student
  }

  return (
    <div
      style={{
        marginTop: "16px",
      }}
    >
      <Container>
        {/* <Row>
          <Col>
            <Button onClick={getUsers}> Get students</Button>
          </Col>
        </Row> */}
        <h4>Students registered {students.length}</h4>

        <Row
          style={{
            marginBottom: "16px",
          }}
        >
          <Col md="auto">
            <Form.Control
              style={{
                fontSize: "20px",
              }}
              placeholder="Search..."
              onChange={(event) => {
                ////console.log()("setting class to" + event.currentTarget.value);
                setSearchField(event.currentTarget.value);
              }}
            ></Form.Control>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <div
              style={{
                marginTop: "16px",
                display: "grid",
                alignContent: true,
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <h4>Loading...</h4>
            </div>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Roll no.</th>

                    <th>Name</th>
                    <th>Class</th>
                    <th>Mobile no.</th>

                    {/* <th>School</th> */}
                    {/* <th>Last Payment</th> */}
                    <th>More Info</th>
                  </tr>
                </thead>

                <tbody>
                  {students.map((student, index) => {
                    if (searcher(student, searchField))
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{student.username}</td>
                          <td>{student.class}</td>
                          {/* <td>{student.school}</td> */}

                          <td>{student.mobileNo}</td>
                          {/* <td>
                          <div>
                            {student.lastPayed === undefined
                              ? "No data"
                              : student.lastaPayed}
                          </div>
                        </td> */}

                          <td>
                            <Link
                              onClick={() => {
                                handleStudentSelected(student);
                              }}
                              to={singleStudentAcaademicInfoUrl}
                            >
                              Info
                            </Link>
                            {/* <Link
                            onClick={() => handleInfoClick(student)}
                            to={studentPaymentInfoPageUrl}
                          >
                            Info
                          </Link> */}
                            {/* <Button size="sm">More Info</Button> */}
                          </td>
                        </tr>
                      );
                    else return null;
                  })}
                </tbody>
              </Table>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
}
