import create from "zustand";
import { persist } from "zustand/middleware";

const customHandler = (set, get) => ({
  onlineQuizzes: [],
  currentQuizIndex: 0,

  currentOnlineQuizIndex: 0,

  setQuizIndex: (quizIndex) => {
    set((state) => {
      return {
        currentOnlineQuizIndex: quizIndex,
      };
    });
  },

  addOnlineQuiz: (quizData) => {
    set((state) => {
      return {
        onlineQuizzes: [...state.onlineQuizzes, quizData],
      };
    });
  },

  deleteQuiz: (quizIndex) => {
    set((state) => {
      return {
        onlineQuizzes: state.onlineQuizzes.filter(
          (ele, index) => index !== quizIndex
        ),
      };
    });
  },

  addQuestion: () => {
    set((state) => {
      return {
        onlineQuizzes: [
          ...state.onlineQuizzes.slice(0, state.currentOnlineQuizIndex),
          {
            ...state.onlineQuizzes[state.currentOnlineQuizIndex],
            questions: [
              ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions,
              {
                questionAddedAt: new Date().toISOString(),
                options: [],
              },
            ],
          },
          ...state.onlineQuizzes.slice(state.currentOnlineQuizIndex + 1),
        ],
      };
    });
  },

  editQuestionFields: (index, key, value) => {
    // //console.log()("editing index => ", index, title);
    set((state) => {
      return {
        onlineQuizzes: handleEditQuestion(state, index, key, value),
        // onlineQuizzes: [
        //   ...state.onlineQuizzes.slice(0, state.currentOnlineQuizIndex),
        //   {
        //     ...state.onlineQuizzes[state.currentOnlineQuizIndex],
        //     questions: [
        //       ...state.onlineQuizzes[
        //         state.currentOnlineQuizIndex
        //       ].questions.slice(0, index),
        //       {
        //         ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
        //           index
        //         ],
        //         questionText: title,
        //       },

        //       ...state.onlineQuizzes[
        //         state.currentOnlineQuizIndex
        //       ].questions.slice(index + 1),
        //     ],
        //   },

        //   ...state.onlineQuizzes.slice(state.currentOnlineQuizIndex + 1),
        // ],
      };
    });
  },

  deleteQuestion: (index) => {
    set((state) => {
      return {
        onlineQuizzes: [
          ...state.onlineQuizzes.slice(0, state.currentOnlineQuizIndex),
          {
            ...state.onlineQuizzes[state.currentOnlineQuizIndex],
            questions: state.onlineQuizzes[
              state.currentOnlineQuizIndex
            ].questions.filter((quiz, qIndex) => qIndex !== index),
          },
          ...state.onlineQuizzes.slice(state.currentOnlineQuizIndex + 1),
        ],
      };
    });
  },

  addOption: (index) => {
    set((state) => {
      return {
        onlineQuizzes: [
          ...state.onlineQuizzes.slice(0, state.currentOnlineQuizIndex),
          {
            ...state.onlineQuizzes[state.currentOnlineQuizIndex],
            questions: [
              ...state.onlineQuizzes[
                state.currentOnlineQuizIndex
              ].questions.slice(0, index),
              {
                ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
                index
                ],
                options: [
                  ...state.onlineQuizzes[state.currentOnlineQuizIndex]
                    .questions[index].options,
                  {
                    optionCreatedAt: new Date().toISOString(),
                    isCorrectOption: false,
                  },
                ],
              },

              ...state.onlineQuizzes[
                state.currentOnlineQuizIndex
              ].questions.slice(index + 1),
            ],
          },

          ...state.onlineQuizzes.slice(state.currentOnlineQuizIndex + 1),
        ],
      };
    });
  },

  deleteOption: (questionIndex, optionIndex) => {
    //console.log()("deleting option ", optionIndex);
    set((state) => {
      return {
        onlineQuizzes: handleDelete(state, questionIndex, optionIndex),
      };
    });
  },

  editOptionFields: (questionIndex, optionIndex, key, value) => {
    set((state) => {
      return {
        onlineQuizzes: handleEditOption(
          state,
          questionIndex,
          optionIndex,
          key,
          value
        ),
      };
    });
  },
});

function handleEditQuestion(state, index, key, value) {
  return [
    ...state.onlineQuizzes.slice(0, state.currentOnlineQuizIndex),
    {
      ...state.onlineQuizzes[state.currentOnlineQuizIndex],
      questions: [
        ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions.slice(
          0,
          index
        ),
        {
          ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[index],
          [key]: value,
        },

        ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions.slice(
          index + 1
        ),
      ],
    },

    ...state.onlineQuizzes.slice(state.currentOnlineQuizIndex + 1),
  ];
}

function handleEditOption(state, questionIndex, optionIndex, key, value) {
  return [
    ...state.onlineQuizzes.slice(0, state.currentOnlineQuizIndex),
    {
      ...state.onlineQuizzes[state.currentOnlineQuizIndex],
      questions: [
        ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions.slice(
          0,
          questionIndex
        ),

        {
          ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
          questionIndex
          ],

          options: [
            ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
              questionIndex
            ].options.slice(0, optionIndex),

            {
              ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
                questionIndex
              ].options[optionIndex],
              [key]: value,
            },

            ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
              questionIndex
            ].options.slice(optionIndex + 1),
          ],

          // options: state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
          //   questionIndex
          // ].options.filter((ele, index) => index !== optionIndex),
        },
        ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions.slice(
          questionIndex + 1
        ),
      ],
    },
    ...state.onlineQuizzes.slice(state.currentOnlineQuizIndex + 1),
  ];
}

function handleDelete(state, questionIndex, optionIndex) {
  return [
    ...state.onlineQuizzes.slice(0, state.currentOnlineQuizIndex),
    {
      ...state.onlineQuizzes[state.currentOnlineQuizIndex],
      questions: [
        ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions.slice(
          0,
          questionIndex
        ),

        {
          ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
          questionIndex
          ],

          options: state.onlineQuizzes[state.currentOnlineQuizIndex].questions[
            questionIndex
          ].options.filter((ele, index) => index !== optionIndex),
        },
        ...state.onlineQuizzes[state.currentOnlineQuizIndex].questions.slice(
          questionIndex + 1
        ),
      ],
    },
    ...state.onlineQuizzes.slice(state.currentOnlineQuizIndex + 1),
  ];
}

export const quizStore = create(
  persist(customHandler, {
    name: "ayachi-online-quiz-storer",
  })
);
