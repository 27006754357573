//This controller will be responsible for generating the
//pdf reports of the payment invoices of the students
import jsPDF from "jspdf";
import "jspdf-autotable";

// Company logo in Base64 format

export async function generatePDFInvoice() {
  const logo = await getBase64FromFile("../assets/dad.png"); // Truncated for brevity
  console.log("logo", logo);

  const data = [
    {
      amount: 1000,
      payerFirebaseId: "vRwv5SZ2pgb6jLbsf35KM9Q5K742",
      forBillDate: { seconds: 1683829800, nanoseconds: 0 },
      addedAt: { seconds: 1683763200, nanoseconds: 0 },
      madeBy: "Anuj",
      forYear: 2023,
      paymentMode: "cash",
      forDay: 12,
      forMonth: "May",
      id: "2023-May",
    },
    // Add other entries here...
  ];

  // Function to format the date from seconds
  const formatDate = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString();
  };

  // Create the PDF
  const createPDF = () => {
    const doc = new jsPDF();

    // Add the company logo
    console.log("the logo base 64 is", logo);
    doc.addImage(logo, "PNG", 10, 10, 50, 20);

    // Add the title
    doc.setFontSize(18);
    doc.text("Payment Invoice", 70, 20);

    // Table columns
    const columns = [
      { header: "ID", dataKey: "id" },
      { header: "Date", dataKey: "forBillDate" },
      { header: "Amount", dataKey: "amount" },
      { header: "Payment Mode", dataKey: "paymentMode" },
      { header: "Made By", dataKey: "madeBy" },
    ];

    // Table data
    const rows = data.map((item) => ({
      id: item.id,
      forBillDate: formatDate(item.forBillDate.seconds),
      amount: item.amount,
      paymentMode: item.paymentMode,
      madeBy: item.madeBy,
    }));

    // Add the table
    doc.autoTable({
      startY: 40,
      head: [columns],
      body: rows,
    });

    // Save the PDF
    doc.save("invoice.pdf");
  };

  // Generate the PDF
  createPDF();
}

// utils.js
export const getBase64FromFile = (filePath) => {
    return new Promise((resolve, reject) => {
        fetch(filePath)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onloadend = function() {
                    resolve(reader.result);
                };
                reader.onerror = function(error) {
                    reject(error);
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                reject(error);
            });
    });
};
