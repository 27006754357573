import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAnalytics } from "firebase/analytics";

import { getMessaging, onMessage } from "@firebase/messaging";

import { getToken } from "@firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

export const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const tokenGetter = async (setTokenFound, setToken) => {
  var currentToken = "";
  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    if (currentToken) {
      //console.log()("current token for client: ", currentToken);
      setTokenFound(true);
      // console.log("token" , currentToken)
      setToken(currentToken);
      return currentToken;
    } else {
      // //console.log()(
      //   "No registration token available. Request permission to generate one."
      // );
      setTokenFound(false);
      return "error";
    }
  } catch (err) {
    // //console.log()("An error occurred while retrieving token. ", err);
  }
  return currentToken;
};

export const db = getFirestore(app);

export const analytics = getAnalytics(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
