import { Button, Container, Toast } from "react-bootstrap";
import { useState } from "react";

export const teacherMakeAnnouncementUrl = "/make-announcement";

//this page enables the teacher to make announcements to the class groups the
//teacher wants to make an announcnemtn

export function MakeAnnouncementPage() {
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        marginTop: "8px",
      }}
    >
      <Container>
        <h1> Make announcements as a teacher</h1>

        <Button
          onClick={() => {
            setShow(true);
          }}
        >
          Sho noti
        </Button>

        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          animation
          style={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Notification</strong>
            <small>12 mins ago</small>
          </Toast.Header>
          <Toast.Body>
            <h2>There are some new updates that you might love!</h2>
          </Toast.Body>
        </Toast>
      </Container>
    </div>
  );
}
