import { Button, Col, Container, Table, Row } from "react-bootstrap";
import studentStore from "../../../store/studentStore";
import { Link, useNavigate } from "react-router-dom";

import { getStudentCompletedQuizzes } from "../../../controllers/student_controller";

import { useEffect, useState } from "react";

import useStore from "../../../store/WebsiteStore";
import { completedQuizPageOfflineModeUrl } from "../../../components/quiz specific/CompletedQuizPageOfflineMode";

import { showAllStudentsPageUrl } from "../../../utils/all_routes";

function SingleStudentInfoPage() {
  const currentStudentData = studentStore((state) => state.currentStudentData);
  const setCompletedQuizData = useStore((state) => state.setCompletedQuizData);
  const navigate = useNavigate(); //navigate to other pages

  const [loading, setLoading] = useState(false);
  const [userCompletedQuizzes, setUserCompletedQuizzes] = useState([]);

  useEffect(() => {
    // getCurrentUserid()

    const fetchUserQuizzes = async () => {
      setLoading(true);
      const dataFetched = await getStudentCompletedQuizzes(
        currentStudentData.id
      );

      // console.log(dataFetched);
      setUserCompletedQuizzes(dataFetched);
      //console.log()(dataFetched);

      setLoading(false);
    };

    fetchUserQuizzes();
    return () => {};
  }, [currentStudentData.id]);

  function handleInforClick(quiz) {
    setCompletedQuizData(quiz);
    if (quiz.mode === "online") {
      navigate("/completedQuizPage");
    } else {
      navigate(completedQuizPageOfflineModeUrl);
    }
  }
  return (
    <div
      style={{
        marginTop: "16px",
      }}
    >
      <Container>
        <Button
          style={{
            marginBottom: "16px",
          }}
          onClick={() => {
            navigate(showAllStudentsPageUrl);
          }}
        >
          Go Back
        </Button>

        <h2>{currentStudentData.username}</h2>

        <Row>
          <Col>
            <h5>School : {currentStudentData.school}</h5>
          </Col>
          <Col>
            <h5>Phone : {currentStudentData.mobileNo}</h5>
          </Col>

          <Col>
            <h5>Class : {currentStudentData.class}</h5>
          </Col>
        </Row>

        <hr></hr>
        {loading ? (
          <h4>Loading...</h4>
        ) : (
          <>
            {/* show the table here with the students data */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Title</th>
                  <th>Date Given</th>
                </tr>
              </thead>
              <tbody>
                {userCompletedQuizzes.map((quiz, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1} </td>
                      <td>
                        <Link
                          onClick={() => {
                            handleInforClick(quiz);
                          }}
                          to={
                            quiz.mode === "online"
                              ? "/completedQuizPage"
                              : completedQuizPageOfflineModeUrl
                          }
                        >
                          {quiz.title}
                        </Link>
                      </td>
                      {/* <td>{quiz.title}</td> */}
                      <td>{quiz.readableDate}</td>
                    </tr>
                  );
                  // return (
                  //   <div key={index}>
                  //     <Card
                  //       style={{
                  //         marginBottom: "8px",
                  //         paddingLeft: "8px",
                  //         paddingTop: "8px",
                  //         // margin: "8px",
                  //       }}
                  //     >
                  //       <h5>
                  //       </h5>
                  //     </Card>
                  //   </div>
                  // );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Container>
    </div>
  );
}

export default SingleStudentInfoPage;
