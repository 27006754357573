import { useRef } from "react";
import { useState } from "react";
import CreateAssignmentOrQuizCompo from "../../../../components/quiz specific/CreateOnlineQuizOrAssignmentCompo";
import { quizStore } from "../../../../store/quizStore";

import QuizzesMadeListShower from "../../../../components/list components/QuizzesMadeList";
import { useNavigate } from "react-router-dom";
import { onlineQuizMakerCompoUrl } from "../../../../utils/all_routes";
import { Col, Row } from "react-bootstrap";

function OnlineQuizMakeComponent() {
  const [creatingQuiz, setCreatingQuiz] = useState(false);
  const quizNameRef = useRef();

  const addOnlineQuiz = quizStore((state) => state.addOnlineQuiz);
  const onlineQuizzes = quizStore((state) => state.onlineQuizzes);
  const setQuizIndex = quizStore((state) => state.setQuizIndex);
  const deleteQuiz = quizStore((state) => state.deleteQuiz);

  const navigate = useNavigate(); //navigate to other pages

  function handleMakeOnlineQuiz() {
    setCreatingQuiz(true);
    const quizData = {
      title: quizNameRef.current.value,
      quizAddedAt: new Date().toISOString(),
      questions: [],
    };
    addOnlineQuiz(quizData);
    setCreatingQuiz(false);
  }

  function handleEditQuizButton(index, quiz) {
    setQuizIndex(index); //sets the current quiz index
    // //then navigates to the next page for making the quiz
    navigate(onlineQuizMakerCompoUrl);
    //console.log()(index, quiz);
  }

  //right now this is acting as the delete button
  function handleArchiveQuiz(quizIndex, quizData) {
    deleteQuiz(quizIndex);
  }

  return (
    <div
      style={{
        marginTop: "16px",
        marginLeft: "5%",
      }}
    >
      <Row>
        <Col md="auto">
          <h3>Create Online Quiz</h3>
        </Col>

        <Col>
          <CreateAssignmentOrQuizCompo
            placeHolder={"Online Quiz"}
            makeQuizOrAssignmentHandler={handleMakeOnlineQuiz}
            creatingStatus={creatingQuiz}
            nameRef={quizNameRef}
          />
        </Col>
      </Row>

      <QuizzesMadeListShower
        quizzesMade={onlineQuizzes}
        handleEditQuizButton={handleEditQuizButton}
        handleArchiveQuiz={handleArchiveQuiz}
      />
    </div>
  );
}

export default OnlineQuizMakeComponent;
