// import React from 'react'

import { Container, Button, Row, Col, Badge } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getStudentPaymentInfo } from "../../../controllers/payments_controller";
import useStore from "../../../store/WebsiteStore";
import { generatePDFInvoice } from "../../../controllers/pdf_controller";

function PaymentInvoiceDashboard() {
  const [payments, setPayments] = useState([]);
  const currentStudentData = useStore((state) => state.currentUserData);

  useEffect(() => {
    /**
     * Tries to fetch the payment information of that respective user
     */
    const getPayments = async () => {
      console.log("the student data is ", currentStudentData);

      // //console.log()(currentStudentData.id);
      const data = await getStudentPaymentInfo(currentStudentData.id);
      // //console.log()("loaded payments data", data);
      setPayments(data);
      console.log("the payments are", payments);
    };

    getPayments();

    return () => {};
  }, []);

  function generateInvoice() {
    console.log("generating the payment invoice");
    generatePDFInvoice();
  }

  return (
    <div
      style={{
        // marginLeft: "2%",
        // marginRight: "2%",
        marginTop: "2%",
      }}
    >
      <Container>
        <Button onClick={generateInvoice}>Generate Invoice</Button>
      </Container>
    </div>
  );
}

export default PaymentInvoiceDashboard;
