import { toast } from "react-toastify";

toast.configure();

export function showSuccess(message, duration) {
  toast.success(message, {
    autoClose: duration,
    // position: toast.POSITION.TOP_CENTER,
  });
}

export function showAlert(message, duration) {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: duration,
  });
}


export function showInfo(message, duration) {
  toast.info(message, {
    autoClose: true,
    // position: toast.POSITION.TOP_CENTER,
  });
}

