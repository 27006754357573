import { Row, Col, Container, Badge, Button } from "react-bootstrap";

// import classes from "../css_modules/HomePage.module.css";

import classes from "../../css_modules/HomePage.module.css";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { completedQuizPageOfflineModeUrl } from "./CompletedQuizPageOfflineMode";
import useStore from "../../store/WebsiteStore";

//this is show to the students of their previous records of quizzes
//it's show with the accordian
//also this is a extensible component so I will be using this component at more places

import { getStudentCompletedQuizzes } from "../../controllers/student_controller";

function QuizHistoryViewList({ quiz }) {
  const setCompletedQuizData = useStore((state) => state.setCompletedQuizData);
  const currentUserData = useStore((state) => state.currentUserData);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [userCompletedQuizzes, setUserCompletedQuizzes] = useState([]);

  useEffect(() => {
    // getCurrentUserid()

    const fetchUserQuizzes = async () => {
      setLoading(true);
      const dataFetched = await getStudentCompletedQuizzes(currentUserData.id);
      setUserCompletedQuizzes(dataFetched);

      setLoading(false);
    };

    fetchUserQuizzes();
    return () => {};
  }, [currentUserData.id]);

  function handleInforClick(quiz) {
    setCompletedQuizData(quiz);
    if (quiz.mode === "online") {
      navigate("/completedQuizPage");
    } else {
      navigate(completedQuizPageOfflineModeUrl);

      // navigate(singleStudentAcaademicInfoUrl);
    }
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : userCompletedQuizzes.length === 0 ? (
        <h3>You do not have any quiz history please attend some quizzes !!</h3>
      ) : (
        userCompletedQuizzes.map((quiz) => {
          return (
            <div
              className="shadow p-2 mb-1  rounded"
              style={{
                marginBottom: "0px",
              }}
              key={quiz.id}
            >
              <Container fluid>
                <Row>
                  <Col md="auto">
                    <Row>
                      <Col>
                        <Row>
                          <div
                            style={{
                              fontSize: "22px",
                              color: "#353935",
                            }}
                          >
                            {quiz.title} ({quiz.mode})
                          </div>
                        </Row>
                        <Row>
                          <Col>
                            <small className={classes.timeStyle}>
                              {/* {quiz.startsAt.toDate().toString()} */}

                              {quiz.startsAt.toDate().toDateString()}
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      <Col md="auto">
                        <Badge
                          bg="light"
                          style={{
                            fontSize: "13px",
                            color: "black",
                          }}
                        >
                          {quiz.subject}
                        </Badge>
                      </Col>
                    </Row>

                    {/* now show the chapters */}

                    <Row
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      {quiz.chapters.map((chapter, index) => {
                        return (
                          <Col key={index}>
                            <Badge
                              bg="dark"
                              style={{
                                fontSize: "9px",
                              }}
                            >
                              {chapter}
                            </Badge>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>

                  {/* show marks  */}
                  <Col>
                    <Badge
                      bg="success"
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      {quiz.scored} / {quiz.marks}
                    </Badge>
                  </Col>
                </Row>

                <Row
                  style={{
                    paddingLeft: "2%",
                    paddingRight: "2%",
                  }}
                >
                  <Button
                    variant="primary"
                    size="sm"
                    style={{
                      marginTop: "7px",

                      marginBottom: "8px",
                    }}
                    onClick={() => {
                      handleInforClick(quiz);
                    }}
                  >
                    Info
                  </Button>
                </Row>
              </Container>
            </div>
          );
        })
      )}
    </div>
  );
}

export default QuizHistoryViewList;
