import useStore from "../../../store/WebsiteStore";

// import ScaffoldLayout from "../../components/layout/Scaffold.js";

import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { db } from "../../../firebase-config";

import { collection, getDocs, query, orderBy } from "firebase/firestore";

function StudentInfoPage() {
  const navigate = useNavigate();
  const currentUserData = useStore((state) => state.currentUserData);

  const [completedQuizzes, setCompletedQuizzes] = useState([]);

  const setCompletedQuizData = useStore((state) => state.setCompletedQuizData);

  // useEffect(() => {
  //   return () => {};
  // }, []);

  useEffect(() => {
    const getCompletedQuizzes = async () => {
      const q = query(
        collection(db, "users", currentUserData.id, "completedQuizzes"),
        orderBy("startsAt", "desc")
      );

      const queryData = await getDocs(q);

      setCompletedQuizzes(
        queryData.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );

      //console.log()(queryData);
    };

    getCompletedQuizzes();

    return () => {};
  }, [currentUserData.id]);

  return (
    <div
      style={{
        marginTop: "10px",
        marginLeft: "10px",
      }}
    >
      <div className="container">
        <div className="row">
          <h1>
            <b>{currentUserData.username}</b>
          </h1>
        </div>

        <div className="row">
          <h4>{currentUserData.class}th class</h4>
        </div>

        <div className="row">
          <h4>{currentUserData.school}</h4>
        </div>

        <div className="row">
          <h6>{currentUserData.mobileNo}</h6>
        </div>

        <hr />

        <hr />

        <h3>Academic History</h3>

        {/* let's show his completed quizzes */}

        {completedQuizzes.map((quiz) => {
          return (
            <div
              key={quiz.id}
              className="card"
              style={{
                marginTop: "2%",
                padding: "8px",
                marginLeft: "5%",
                marginRight: "5%",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h3>{quiz.title}</h3>
                  </div>

                  <div
                    className="col"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <h3
                      style={{
                        color: "green",
                      }}
                    >
                      {quiz.scored} / {quiz.marks} marks
                    </h3>
                  </div>
                </div>

                <div style={{ color: "blue", fontSize: "20px" }}>
                  <b> {quiz.mode.toUpperCase()} </b>
                </div>
                <hr />

                <div className="row">
                  <div
                    className="col"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {quiz.subject}
                  </div>
                  <div
                    className="col"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {quiz.startsAt.toDate().toDateString()}
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <button
                    style={{
                      color: "white",
                      fontWeight: "bolder",
                    }}
                    type="button"
                    className="btn btn-info"
                    onClick={() => {
                      // //console.log()(quiz)
                      setCompletedQuizData(quiz);
                      navigate("/completedQuizPage");
                    }}
                  >
                    Info
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StudentInfoPage;
