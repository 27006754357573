import useStore from "../../store/WebsiteStore";

import { useState, useEffect } from "react";
// import dadImage from "../logos/dad.png";
import classes from "../../css_modules/HomePage.module.css";
// import { collection, onSnapshot } from "firebase/firestore";
// import { announcementHandler } from "../../controllers/student_controller";

import AnnouncementComponent from "../../components/list components/AnnouncementComponent";
// import { sendLoginAnalyticsToDiscord } from "../../controllers/discord_sender";

function StudentHomePage() {
  const currentUserId = useStore((state) => state.currentUserId);
  const currentUserData = useStore((state) => state.currentUserData);

  const [announcements, setAnnouncements] = useState([]);

  //since this page is the landing page we can do one thing is load the user data on this landing page itself
  useEffect(() => {
    // TimeAgo.addLocale("en-US");
    //TODO: uncomment and fix the announcement code
    // announcementHandler.subber(currentUserId, setAnnouncements);

    //also send the data to discord
    return () => {
      //TODO: uncomment and fix the announcement code
      // announcementHandler.canceller();
    };
  }, [currentUserId, currentUserData.username]);

  return (
    <div>
      <AnnouncementComponent announcements={announcements} classes={classes} />
    </div>
  );
}

export default StudentHomePage;
