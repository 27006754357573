import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  StudentAssignmentData,
} from "../../../models/assignment_model";
import assignmentStore from "../../../store/assignment_store";
import classes from "../../../css_modules/StudentAssignmentListPage.module.css";
import { Timestamp } from "firebase/firestore";
import { convertToIndianISOString } from "../../../controllers/time_zone_controller";
import {
  Paperclip,
  PencilSquare,
} from "react-bootstrap-icons";
import {
  downloadQuestionPaper,
} from "../../../controllers/quiz_controller";
import { assignmentSubmitByStudent, isDueDatePassed, uploadSolvedAssignmentFile } from "../../../controllers/assignment_controller";
import { showSuccess, showAlert } from "../../../controllers/toaster";
// import studentStore from "../../../store/studentStore";
import { UserAuthDetails } from "../../../models/user_auth_deatils";
import userStore from "../../../store/WebsiteStore";

export const studentViewAssignmentUrl = "/view-assignment";

/**
 * Note:
 *
 * 1. Once the user selects the file that needs to be uploaded, he/she can change the file selected
 * 2. After selecting the user can still make the changes
 * 3. After the submission based on the condition the user can see the , uploaded file
 * 4. If the submission is before the due date then the user can still make the changes to the file
 *
 */
const AssignmentDetailPage: React.FC = ({ }) => {
  const navigate = useNavigate();

  // ============== UI States
  //get current student assignment data from store
  const currStudentAssignment: StudentAssignmentData | undefined =
    assignmentStore<StudentAssignmentData | undefined>(
      (state) => state.currentStudentAssignmentData
    );
  const currentUserData: UserAuthDetails | undefined = userStore((state) => state.currentUserData);
  const fileRef = useRef<HTMLInputElement>(null);

  //============== FILE Related states
  //These variables are loaded from the firebase data , when the page is first loaded
  const [aPaperFileName, setAPaperFileName] = useState(
    currStudentAssignment?.answerSheetStoragePath
  );

  //These variables are only temporary and are only there for the page itself
  const [aPaperFile, setAPaperFile] = useState<globalThis.File | null>(); //this is only for the ui
  const [aPaperSelectedBool, setAPaperSelectedBool] = useState(true);
  const [isFileUploading, setisFileUploading] = useState(false);
  const [assignmentDueDate, setAssignmentDueDate] = useState("");

  // ==========================================

  function navigateBack() {
    navigate(-1);
  }

  function getDueDate(time: Timestamp): string {
    if (time) return convertToIndianISOString(time, "DD-MM-YYYY - hh:mm a");
    return ""
  }

  function handleAssignmentQuestionClick() {
    if (
      currStudentAssignment &&
      currStudentAssignment.hasQuestionPaper &&
      currStudentAssignment.storagePathCloud
    ) {
      downloadQuestionPaper(currStudentAssignment.storagePathCloud);
    }
  }

  function handleAssignmentSubmissionDownload() {
    if (
      currStudentAssignment &&
      currStudentAssignment.hasAnswerSheetFileAttachment &&
      currStudentAssignment.answerSheetStoragePath
    ) {
      downloadQuestionPaper(currStudentAssignment.answerSheetStoragePath);
    }

  }

  /**
   * In this case we are directly going to upload the selected file to the firebase ,
   * Checks : the file size should only be 10 MB of size no more than that
   *
   * After the file is uploaded there should be two options ,
   * One of submit and one of , change the file selection
   * TODO:Khushi please take care of how the UI will look
   * 
   */
  async function handleQuestionPaperSelect(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const file: globalThis.File = event.target.files![0];
    console.log("selected file is", file);
    // Check if the file is less than 5 MB (5 * 1024 * 1024 bytes)
    if (file.size > 5 * 1024 * 1024) {
      showAlert("File size should be less than 5 MB");
    } else {
      //start uploading the selected file on firebase as well and show the message , afte
      showSuccess("File has been selected now please submit the assignment", 5000)
      setAPaperSelectedBool(true);
      setAPaperFile(file);
      setAPaperFileName(file.name);
    }
  }

  /**
   * This function is responsible for uploading the assignment on firebase
   * */
  async function handleAssignmentUpload() {
    try {
      console.log("Assignment upload initiated")
      const answerSheetStoragePath = await uploadSolvedAssignmentFile(aPaperFile, currStudentAssignment, setisFileUploading)
      console.log("Answer sheet storage path is ", answerSheetStoragePath);

      //update the assignment fields in firebase now 
      console.log("Current studenta assignment data after upload", currStudentAssignment);

      if (currStudentAssignment?.id && currentUserData?.id) {
        const currTimeStamp = Timestamp.now();
        //update the storage path as well for the answerSheetStoragePath
        const assignmentDoc: StudentAssignmentData = {
          ...currStudentAssignment,
          hasAnswerSheetFileAttachment: true,
          answerSheetStoragePath: answerSheetStoragePath,
          isSubmitted: true,
          submittedAt: currTimeStamp
        }
        assignmentSubmitByStudent(currStudentAssignment?.id, currentUserData?.id, assignmentDoc, setisFileUploading);
        //set answerSheetStoragePath to show -> View Submission Button
        currStudentAssignment.answerSheetStoragePath = answerSheetStoragePath;
        //also set the submitted at date as well
        setAssignmentDueDate(getDueDate(currTimeStamp));
      }
      //if no errors are there then we can proceed to udpate the database
    } catch (error) {
      console.log("Some error occurred while uploading the assignment file")
      showAlert(`Some error occurred while uploading the assignment file , please contact admin ${error}`, 10000)
    } finally {
      setisFileUploading(false)
    }
  }

  /**
   * This is a utility function
   */
  const handleUploadClick = () => {
    //always make the apper select bool and aPaperFileName so empty 
    // setAPaperSelectedBool(false);
    // setAPaperFileName("")
    if (fileRef && fileRef.current) {
      fileRef.current.click();
    }
  };

  /**
   * This functions helps in changing the submission of the already uploaded assignment
   * */
  const handleChangeSubmission = () => {
    if (currStudentAssignment) {
      currStudentAssignment.answerSheetStoragePath = "";
      currStudentAssignment.isSubmitted = false;
    }
    handleUploadClick();
  }

  /**
   * This function will check whether the answer sheet was already uploaded on the storage
   * */
  function isAnswerSheetAlreadyUploadedOnStorage() {
    if (!currStudentAssignment) return false
    return (currStudentAssignment.answerSheetStoragePath || currStudentAssignment.isSubmitted) ? true : false
  }

  /**
   *
   * */

  /**
   * This function is going to display the 
   * TODO: Change this re-usable component
   * */
  function AnswerSheetPicker(): React.JSX.Element {
    if (isAnswerSheetAlreadyUploadedOnStorage()) {
      return (
        <Row>
          <Col md="auto">
            {/* Show the change button if assignment is before the due date */}
            <Button variant="success" onClick={handleAssignmentSubmissionDownload}>
              View Submission
            </Button>
          </Col>
          <Col>
            <CustomFilePickerControl></CustomFilePickerControl>
            {
              currStudentAssignment?.dueDateAt && !isDueDatePassed(currStudentAssignment?.dueDateAt) ?
                <Button variant="warning" onClick={handleChangeSubmission}>Change Submission</Button> : null
            }
          </Col>
        </Row >
      )
    } else {
      //else show the uploade option to the user
      return (
        <div>
          {isFileUploading ? (
            <progress value={undefined}></progress>
          ) : (
            <div>
              <div>
                <CustomFilePickerControl></CustomFilePickerControl>
                <Row>
                  <Col>
                    {aPaperSelectedBool && aPaperFileName ?
                      <Row>
                        <Col md="auto">
                          <Row>
                            <h5>{aPaperFileName}</h5>
                          </Row>
                        </Col>
                        <Col md="auto">
                          {/* show the edit and remove option */}
                          <Button onClick={handleUploadClick}>
                            Change {" "}
                            <PencilSquare></PencilSquare>
                          </Button>
                        </Col>
                        <Col md="auto">
                          <Button variant="success" onClick={handleAssignmentUpload}>Submit Assignment</Button>
                        </Col>
                      </Row>
                      :
                      <Button onClick={handleUploadClick}>
                        Select file
                      </Button>
                    }
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      )
    }
  }


  function CustomFilePickerControl(): React.JSX.Element {
    return <Form.Control
      className={classes.answerSheetInputFile}
      onSelect={() => {
        //console.log()("file selected");
      }}
      accept=".doc, .docx, .pdf, .txt"
      type="file"
      ref={fileRef}
      required
      size="lg"
      onChange={handleQuestionPaperSelect}
      style={{ display: "none" }} // Hide the file input
    ></Form.Control>
  }

  useEffect(() => {
    //just make anoth call once the isFileUploading is done
    //
    if (currStudentAssignment?.submittedAt) {
      setAssignmentDueDate(getDueDate(currStudentAssignment?.submittedAt));
    }
    return () => {
    }
  }, [isFileUploading])


  return (
    <Container
      style={{
        marginTop: "2%",
      }}
    >
      <Button onClick={navigateBack}>Go back</Button>
      {currStudentAssignment ? (
        <div className={classes.assignmentBody}>
          {/* Assignment title */}
          <h4>{currStudentAssignment.title}</h4>
          {/* Assignment marks  if graded*/}
          <Row>
            {currStudentAssignment.graded ? (
              <Col md="auto">
                <Badge bg="dark">{currStudentAssignment.marks} marks</Badge>
              </Col>
            ) : null}
            {/* Also show the due date as well */}
            {
              assignmentDueDate ?
                <Col md="auto">
                  <Badge bg="info">
                    Submitted At: {"  "}
                    {assignmentDueDate}
                  </Badge>
                </Col>
                : null
            }
          </Row>
          {/* Assignment question or instructions */}
          <p className={classes.instruction}>
            {currStudentAssignment.question}
          </p>
          <Row>
            <Col>
              {/* Show the assignment attachment file */}
              {currStudentAssignment.hasQuestionPaper ? (
                <Button variant="dark" onClick={handleAssignmentQuestionClick}>
                  <Paperclip className={classes.paperClipIcon}></Paperclip>
                  View Assignment Paper
                </Button>
              ) : null}
            </Col>
            <Col md="auto">
              {/* Show answer upload option */}
              <AnswerSheetPicker></AnswerSheetPicker>
            </Col>
          </Row>
        </div>
      ) : (
        <h4>No data present Contact admin !!</h4>
      )}
    </Container>
  );
};

export default AssignmentDetailPage;
