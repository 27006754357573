import { Row, Col, Table, Container } from "react-bootstrap";

function QuizzesMadeListShower({
  quizzesMade,
  handleEditQuizButton,
  handleArchiveQuiz,
}) {
  return (
    <Container>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Title</th>
            <th>Subject</th>
            <th>Class</th>
            <th>Created</th>
          </tr>
        </thead>

        <tbody>
          {quizzesMade.map((quiz, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <ClickableText
                  text={quiz.title}
                  clicker={() => handleEditQuizButton(index, quiz)}
                  hider={() => handleArchiveQuiz(index, quiz.firebaseQuizId)}
                />
                <td>{quiz.subject}</td>
                <td>{quiz.class}</td>

                <td>{quiz.readableDate}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}

export default QuizzesMadeListShower;

function ClickableText({ text, clicker, hider }) {
  return (
    <td
      style={{
        color: "blue",
      }}
    >
      <Row>
        <Col
          onClick={clicker}
          style={{
            cursor: "cell",
            marginBottom: "8px",
          }}
        >
          {text}
        </Col>
        <Col
          md="auto"
          onClick={hider}
          style={{
            cursor: "pointer",
          }}
        >
          Hide
        </Col>
      </Row>
    </td>
  );
}

// {quizzesMade.map((quiz, index) => {
//   return (
//     <Row
//       style={{
//         marginBottom: "8px",
//       }}
//       key={index}
//     >
//       <Card>
//         <Card.Body>
//           <Card.Title>{quiz.title}</Card.Title>

//           {/* <Col>
//                   <Badge>
//                     {dateString(quiz.startsAt)}
//                   </Badge>
//                 </Col> */}
//           <Card.Text>
//             <Row
//               style={{
//                 marginBottom: "8px",
//               }}
//             >
//               <Col md="auto">
//                 <Badge pill bg="dark">
//                   Subject : {quiz.subject}
//                 </Badge>
//               </Col>

//               <Col md="auto">
//                 <Badge pill bg="dark">
//                   Class : {quiz.class}
//                 </Badge>
//               </Col>

//               <Col md="auto">
//                 <Badge pill bg="dark">
//                   Marks : {quiz.marks}
//                 </Badge>
//               </Col>

//               {/* <Col md="auto">
//                 <Badge pill bg="dark">
//                   Duration : {quiz.duration}
//                 </Badge>
//               </Col> */}

//               <Col md="auto">
//                 <Badge pill bg="dark">
//                   Mode : {quiz.mode}
//                 </Badge>
//               </Col>
//             </Row>

//             {/* <Row>
//                     {quiz.chapters.map((chapter) => {
//                       <Col>
//                         <Badge>chapter</Badge>
//                       </Col>;
//                     })}
//                   </Row> */}

//             <Row
//               style={{
//                 marginBottom: "16px",
//                 marginTop: "20px",
//               }}
//             >
//               {quiz.chapters !== undefined ? (
//                 <Col md="auto">
//                   <Badge bg="success">Chapters</Badge>
//                 </Col>
//               ) : null}

//               {quiz.chapters !== undefined
//                 ? quiz.chapters.map((chapter, chapterIndex) => {
//                     return (
//                       <Col md="auto" key={chapterIndex}>
//                         <Badge pill size="sm">
//                           {chapter}
//                         </Badge>
//                       </Col>
//                     );
//                   })
//                 : null}
//             </Row>

//             <Row></Row>

//             <Row>
//               <Col
//                 md="auto"
//                 style={
//                   {
//                     // marginTop: "8px",
//                     // textAlign: "left",
//                   }
//                 }
//               >
//                 <Button
//                   size="sm"
//                   style={{
//                     marginBottom: "8px",
//                   }}
//                   onClick={() => {
//                     handleEditQuizButton(index, quiz);
//                   }}
//                 >
//                   Edit Quiz
//                 </Button>
//               </Col>

//               <Col>
//                 <Button
//                   size="sm"
//                   variant="secondary"
//                   onClick={() => {
//                     // //console.log()(quiz.firebaseQuizId)
//                     handleArchiveQuiz(index, quiz.firebaseQuizId);
//                   }}
//                 >
//                   Archive Quiz
//                 </Button>
//               </Col>

//               <Col
//                 md="auto"
//                 style={{
//                   textAlign: "left",
//                   alignContent: "center",
//                 }}
//               ></Col>
//             </Row>
//           </Card.Text>
//         </Card.Body>
//       </Card>
//     </Row>
//   );
// })}
