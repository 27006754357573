import { collection, doc, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";

// ============================ USERS RELATED CONSTANTS ========

export const userCollectionString = "users";
export const getUserCollection = (userId) => {
  return doc(db, userCollectionString, userId);
};

// ============================ NOTIFICATION RELATED CONSTANTS =========

const notifyTokenCollectionString = "notifyTokens";

export const getNotificationCollection = (userId) => {
  return collection(
    db,
    userCollectionString,
    userId,
    notifyTokenCollectionString
  );
};

export const getNotificationDocument = (userId, notifyToken) => {
  return doc(
    db,
    userCollectionString,
    userId,
    notifyTokenCollectionString,
    notifyToken
  );
};

// ============================ TIME REALTED CONSTANTS ==========

export const getCurrentTimeStamp = () => {
  return Timestamp.fromDate(new Date());
};

//don't send the discord notifications for these accounts as there
//are testing accounts
export const discordNotiBlackList = [
  // "rshE2EHAg8Qe4MVTqDsIdrOPOmI3",
  "UMEWjiTkDQNyvkNnzvfLdqyKP603",
  "DwaCbXVIDmU1r0frhNhllq7GmlH3",
  "rshE2EHAg8Qe4MVTqDsIdrOPOmI3",
  "j8moxHPmNTOWYU9MV5xOZ5xeEUL2" //TODO: Remove in production
];