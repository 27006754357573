//The most important page to make

import { Button, Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import useStore from "../../../store/WebsiteStore";
import { quizMakerComponentUrl } from "./QuizMakerComponent";

import {
  archiveQuizFromDB,
  getAllCreatedQuizzes,
} from "../../../controllers/quiz_controller";

import {
  ShowQuizAddModal,
  // ShowQuizDeleteModal,
} from "../../../components/ui/ModalShower";
import QuizzesMadeListShower from "../../../components/list components/QuizzesMadeList";
// import { Timestamp } from "firebase/firestore";

//this the page which is shown once we click the create quiz option
//Using this component for multiple purposes
//For editing a new quiz and for assigning the quiz to the users

export const makeQuizPageUrl = "/make-quiz";
export function QuizDashBoard() {
  const quizzesMade = useStore((state) => state.quizzesMade);

  const setQuizzesMade = useStore((state) => state.setQuizzesMade);
  const [refresh, setRefresh] = useState(false);

  // const [showQuizDelete, setShowQuizDelete] = useState(false);

  //================================================ STATE MANAGEMENT VARIABLES START ================================================

  const setCurrentQuizIndex = useStore((state) => state.setCurrentQuizIndex);

  const setSingleQuizData = useStore((state) => state.setSingleQuizData);

  //================================================ STATE MANAGEMENT VARIABLES END ================================================

  // const [quizTitle, setQuizTitle] = useState("nothing");

  // const [quizFireBaseid, setQuizFirebaseId] = useState("nothing");

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate(); //navigate to other pages

  function handleEditQuizButton(index, quizData) {
    //also set the current index as well
    setCurrentQuizIndex(index);
    navigate(quizMakerComponentUrl); //we will be now using this component for multiple purposes

    setSingleQuizData(quizData);
  }

  //load the quiz from the backend and save it in the persistence for further use
  useEffect(() => {
    const fetchQuiz = async () => {
      const data = await getAllCreatedQuizzes();
      // //console.log()(data);
      setQuizzesMade(data); //set the quizzes made gives a seamless experience to the user
      // //console.log()(data);
    };

    fetchQuiz();

    return () => {};
  }, [setQuizzesMade, refresh]);

  // function dateString(dateStr){
  //   return dateStr.toDate().toDateString()
  // }

  async function handleArchiveQuiz(index, quizId) {
    setRefresh(!refresh);
    await archiveQuizFromDB(quizId);
  }

  return (
    <div
      style={{
        marginTop: "2%",
        marginLeft: "5%",
        marginRight: "5%",
      }}
    >
      {/* <Container> */}
      {/* <Row>Make Quiz Page</Row> */}
      <Row>
        <Col>
          <Button
            onClick={() => {
              setShowModal(true);
            }}
            style={{
              marginBottom: "16px",
            }}
          >
            Add A Quiz
          </Button>
        </Col>
      </Row>

      {quizzesMade.length === 0 ? (
        "Add Some Quizzes"
      ) : (
        <div>
          <Row>
            <h4>Total quizzes made {quizzesMade.length}</h4>
          </Row>
          <QuizzesMadeListShower
            quizzesMade={quizzesMade}
            handleEditQuizButton={handleEditQuizButton}
            handleArchiveQuiz={handleArchiveQuiz}
          />
        </div>
      )}

      <ShowQuizAddModal showModal={showModal} setShowModal={setShowModal} />
      {/* </Container> */}
    </div>
  );
}
