import { db } from "../firebase-config";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { showAlert, showSuccess } from "./toaster";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  setPersistence,
  sendPasswordResetEmail,
  // confirmPasswordReset,
  // browserLocalPersistence,
  // connectAuthEmulator,
  // browserSessionPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  UserCredential,
} from "firebase/auth";

import "react-toastify/dist/ReactToastify.css";
import { UserAuthDetails } from "../models/user_auth_deatils";

// import { isValidTimestamp } from "@firebase/util";

// import Notifications from "rc-notification";

//now let's create the two login and sign up functions

const authentication = getAuth();

export const handleGooglSignUpOrLogin = async (): Promise<UserAuthDetails> => {
  //sign in with google
  console.log("handling google sign in");
  const googleAuth = new GoogleAuthProvider();
  try {
    // await setPersistence(authentication, browserLocalPersistence);
    const userCredententials: UserCredential = await signInWithPopup(
      authentication,
      googleAuth
    );
    const isNewUser = getAdditionalUserInfo(userCredententials)?.isNewUser;
    const additionalUserDetails: UserAuthDetails = {
      ...userCredententials,
      isNewUser: isNewUser!,
    } as UserAuthDetails;
    return additionalUserDetails;
  } catch (err) {
    console.log(err);
  }
  throw "Error occurred while logging in";
};

export const handleLogin = async (emailId: string, password: string) => {
  let responseData: UserCredential;

  //wait for the persistence to handle first
  //handle persistence first
  //console.log()("setting persisence ");
  //console.log()("persisence set ");
  let errorMessage: string = "";

  await signInWithEmailAndPassword(authentication, emailId, password)
    .then((response) => {
      responseData = response;
    })
    .catch((err) => {
      errorMessage = err;
      switch (err.code) {
        case "auth/wrong-password":
          showAlert("Wrong Password", 1500);
          break;
        case "auth/invalid-email":
          showAlert("Invalid Email Id !!", 1500);
          break;
        case "auth/user-not-found":
          showAlert("User not found !!", 1500);
          break;
        case "auth/too-many-requests":
          showAlert(
            "You have attempted to login many times your account is blocked temporarily",
            3500
          );
          //TODO: Send this analytics to the admin for action
          break;

        default:
          showAlert(`${err.code}`, 5000);
      }
    });

  return errorMessage;
};

export const handleLogOut = async () => {
  try {
    const authentication = getAuth();
    await authentication.signOut();
  } catch (error) {
    console.log("some error occurred while sining out", error);
  }
  // //console.log()("user signed out from auth controller");
};

//WARN: Theere is no sign up flow anymore , the user logging in will be directed with the
//detail entering page
// export const handleSignUp = async (emailId : string, password : string) => {
//   const authentication = getAuth();

//   //set the persistence of the user then do any account creation
//   // //console.log()("setting persistence in sign up")
//   await setPersistence(authentication, browserLocalPersistence);
//   // //console.log()("persistence set up in sign up")

//   await createUserWithEmailAndPassword(authentication, emailId, password)
//     .then((response) => {
//       // //console.log()("tried logging in");
//       // //console.log()(response);

//       showSuccess(`Account created successfully`, 3000);

//       // //console.log()("returning response to caller");

//       responseData = response.user;

//       // signUpLog(response.user.uid);  //TODO: Sign Up Log

//       //then return the response back to the component that called it
//       //also store in the useStore method
//     })
//     .catch((err) => {
//       //console.log()("error");
//       //console.log()(err.code);

//       responseData = "error";

//       //create a switch state here
//       switch (err.code) {
//         case "auth/email-already-in-use":
//           showAlert("Email is already in use !!", 1500);
//           break;

//         case "auth/invalid-email":
//           showAlert("Invalid Email Id !!", 1500);
//           break;

//         case "auth/user-not-found":
//           showAlert("User not found !!", 1500);
//           break;

//         case "auth/too-many-requests":
//           showAlert(
//             "You have attempted to login many times your account is blocked temporarily",
//             3500
//           );
//           //TODO: Send this analytics to the admin for action
//           break;

//         // default:
//         //   alert("some error occured");
//         default:
//           showAlert(`${err.code}`, 5000);
//       }
//     });

//   return responseData;
// };

//to avoid repetitive logins
export function getCurrentUser() {
  const auth = getAuth();

  // //console.log()("logged in as " + auth.currentUser);

  return auth.currentUser;
}

export async function sendLogInLog(userId: string) {
  //sends the login log to the database for better

  const data = {
    loggedInAt: Timestamp.fromDate(new Date()),
    id: userId,
  };

  const coll = collection(db, "logLoginEvent");

  await addDoc(coll, data).catch((err) => {
    console.log("Some error occurred ", err);
    // //console.log()("some error occured while logging");
  });

  // //console.log()("logged log in details ");
}

export async function sendSignUpLog(userId: string, userName: string) {
  //sends the login log to the database for better

  const data = {
    signedUpAt: Timestamp.fromDate(new Date()),
    id: userId,
    name: userName,
  };

  const coll = collection(db, "logSignUpEvent");

  await addDoc(coll, data).catch((err) => {
    console.log("Some error occurred ", err);
    // //console.log()("some error occured while logging");
  });

  // //console.log()("logged sign up details ");
}

export async function resetPassword(userEmail: string) {
  const authentication = getAuth();

  //console.log()("sending password reset email ");
  await sendPasswordResetEmail(authentication, userEmail);
  //console.log()("email sent");
}

/**
 * Checks whether the user who is logging in ,
 */
export function isNewUser(response: UserAuthDetails): boolean {
  console.log("checking the user", { response });

  if (!response.username || response.isNewUser) {
    console.log("provided user is a new user initiating data saving flow");
    return true;
  }
  return false;
}
