import { useRef, useState, useEffect } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  createAssignment,
  deleteAssignment,
  getAllMadeAssignments,
} from "../../../controllers/assignment_controller";

import { showAlert } from "../../../controllers/toaster";
import { editAssignmentUrl } from "../../../utils/all_routes";
import assignmentStore from "../../../store/assignment_store";
import CreateAssignmentOrQuizCompo from "../../../components/quiz specific/CreateOnlineQuizOrAssignmentCompo";
function MakeAssignmentPage() {
  const navigate = useNavigate(); //navigate to other pages

  //local states

  const assignmentNameRef = useRef();
  const [assignments, setAssignments] = useState([]);
  const [loadingAssignments, setLoadingAssignments] = useState([]);
  const [creatingAssignment, setCreatingAssignment] = useState(false);
  const setCurrentAssignment = assignmentStore(
    (state) => state.setCurrentAssignment
  );

  const [reload, setReload] = useState(false);

  useEffect(() => {
    function fetcher() {
      getAllMadeAssignments(setLoadingAssignments).then((data) => {
        setAssignments(data);
      });
    }

    fetcher();
    return () => { };
  }, [reload]);

  async function handleMakeAssignmentButton(event) {
    event.preventDefault();
    if (assignmentNameRef.current.value.length <= 4) {
      showAlert("Please enter a valid name for the assignment ", 4000);
      return;
    }
    //call to the firebase for assignment creation
    await createAssignment(
      assignmentNameRef.current.value,
      setCreatingAssignment
    );
    setReload(!reload);
  }

  //show the delete assignment dialog once only if the user clicks the delete assignment option
  async function handleDeleteAssignment(assignmentId) {
    await deleteAssignment(assignmentId);
    setReload(!reload);
  }

  function handleEditButton(assignmentData) {
    //navigate to the edit assignment page
    setCurrentAssignment(assignmentData);
    // console.log("current assignment data being set as ", assignmentData);
    navigate(editAssignmentUrl);
  }

  return (
    <div
      style={{
        marginTop: "16px",
        marginLeft: "5%",
        marginRight: "5%",
      }}
    >
      <>
        <h4>Create an assignment</h4>

        <CreateAssignmentOrQuizCompo
          placeHolder={"Assignment"}
          makeQuizOrAssignmentHandler={handleMakeAssignmentButton}
          creatingStatus={creatingAssignment}
          nameRef={assignmentNameRef}
        />

        {loadingAssignments ? (
          <h5>Loading assignments...</h5>
        ) : (
          <>
            {assignments.map((assignment, index) => {
              return (
                <Card
                  key={index}
                  style={{
                    paddingTop: "8px",
                    paddingLeft: "16px",
                    marginBottom: "8px",
                  }}
                >
                  <Card.Body>
                    <Row>
                      <h4
                        style={{
                          marginBottom: "16px",
                        }}
                      >
                        {assignment.title}
                      </h4>
                      <Col>
                        <Button
                          onClick={() => {
                            handleEditButton(assignment);
                          }}
                          style={{
                            marginRight: "16px",
                          }}
                        >
                          Edit Assignment
                        </Button>

                        <Button
                          variant="danger"
                          onDoubleClick={() => {
                            //console.log()("double clicked");
                          }}
                          onClick={() => {
                            handleDeleteAssignment(assignment.id);
                          }}
                        >
                          Delete Assignment
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          </>
        )}
      </>
    </div>
  );
}

export default MakeAssignmentPage;
