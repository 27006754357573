function LoadingPage() {
  return (
    <div>
      <p
        style={{
          textAlign: "center",
          alignContent: "center",
          fontFamily: "Raleway",
          fontSize: "40px",
        }}
      >
        Welcome back to Ayachi Academy
      </p>
    </div>
  );
}

export default LoadingPage;
