import { db } from "../firebase-config";

import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  doc,
  query,
  Timestamp,
  where,
  updateDoc,
  setDoc,
  orderBy,
  // orderBy,
} from "firebase/firestore";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { showAlert, showSuccess } from "./toaster";
import { MONTHS } from "../utils/all_routes";

export const quizReferenceString = "newQuizzes"; //if in the future we want to change the location for storing new quiz documents

const storage = getStorage();

export const getAllStudentsFromClassNumber = async (classNumber) => {
  //console.log()("fetching all students from given class number");
  //console.log()(classNumber);

  const userCollection = collection(db, "users");

  const w = where("class", "==", parseInt(classNumber));

  const q = query(userCollection, w);

  const documents = await getDocs(q);

  const filteredData = documents.docs
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
      studentSelected: false,
      studentFileSelected: false, //just a check to make sure that the students file has been selected or nor
      uploadingFile: false,
      fileUploaded: false,
    }))
    .filter((element) => element.activeUser === true);

  return filteredData;
};

//adds a new quiz to the database with some basic functionalities

export const addNewQuizToDB = async (quizTitle) => {
  //console.log()("adding new quiz to firebase");

  const quizCollection = collection(db, quizReferenceString);

  const q = query(quizCollection);

  const quizResponse = await addDoc(q, {
    title: quizTitle,
    createdAt: Timestamp.fromDate(new Date()),
    hideQuiz: false,
  });

  const quizId = quizResponse.id;

  //console.log()("quiz made on firebase");
  //console.log()(quizId);

  return quizId;
};

export const deleteQuizFromDB = async (quizId) => {
  var status = "ok";
  try {
    //console.log()("deleteing quiz");
    const quizDoc = doc(db, quizReferenceString, quizId);
    const q = query(quizDoc);
    await deleteDoc(q);
    //console.log()("deleted");
  } catch (error) {
    //console.log()("error occured while deleting ");
    //console.log()(error);
    status = "error";
  }

  return status;
};

export async function archiveQuizFromDB(quizId) {
  try {
    const quizDoc = doc(db, quizReferenceString, quizId);
    await updateDoc(quizDoc, {
      hideQuiz: true,
    });
    showSuccess("Quiz has been archived", 3000);
  } catch (error) {
    showAlert("Some error occured while arhiving the quiz", 3000);
    //console.log()("Error occured in archiving qiuiz", error);
  }
}

export const updateQuizDetailsOnDB = async (quizData) => {
  var status = "ok";
  try {
    //console.log()("updating quiz to firebase");
    //console.log()(quizData);
    //console.log()(quizData.firebaseQuizId);

    const quizDocument = doc(db, quizReferenceString, quizData.firebaseQuizId);
    //console.log()(quizData.startsAt)

    const newData = {
      ...quizData,
      updatedAt: Timestamp.fromDate(new Date()),
      duration: parseInt(quizData.duration, 10) * 60,
      startsAt: Timestamp.fromDate(new Date(quizData.startsAt)),
      //localFilePath: quizData.localFilePath.toString(),
    };

    // const quizResponse =
    await updateDoc(quizDocument, newData);

    //console.log()("quiz updated on firebase ");
    //console.log()(quizResponse);
  } catch (error) {
    //console.log()("error occured while updating the quiz ");
    //console.log()(error);
    status = "erorr";
    console.log("update quiz error", error);
  }

  return status;
};

/**
 * Question paper file selected from input tag
 * setUploadStatus `useState` hook for rendering a visual feedback of the file being uploaded
 * @param {*} questionPaperFile
 * @param {*} setUploadStatus
 * @param {any}
 */

export async function uploadQuestionPaper(
  questionPaperFile,
  // setUploadStatus,
  quizData,
  setFileUploading
) {
  setFileUploading(true);
  //console.log()("uploading the question paper ");

  const fileExtenstion = questionPaperFile.name.split(".")[1];

  const questionPaperRef = ref(
    storage,
    `quizzes/${quizData.firebaseQuizId}/questionPaper/${quizData.firebaseQuizId}.${fileExtenstion}`
  );

  var fullPath = "";
  try {
    const uploadSnapshot = await uploadBytes(
      questionPaperRef,
      questionPaperFile
    );

    fullPath = uploadSnapshot.metadata.fullPath;
  } catch (error) {
    //console.log()("error occured while uploading the file ");
    fullPath = "error/404";
  }
  setFileUploading(false);
  return fullPath;
}

/**
 * Send the answer sheet to the completedQuizzes of the student
 * @param {*} questionPaperFile
 * @param {*} setUploadStatus
 * @param {any}
 */

export async function uploadAnswerSheet(students, quizData) {
  //iterate through the students and upload their answer sheets then update the quizzes for them

  //console.log()("the quiz data ");
  //console.log()(quizData);

  //console.log()("The students");
  //console.log()(students);

  var status = "error";
  try {
    for (var i = 0; i < students.length; i++) {
      if (students[i] === undefined) continue; //just skip the undefined ones

      try {
        const studentId = students[i].id;
        const file = students[i].file;
        // const studentName = students[i].name;

        const fileExtenstion = file.name.split(".")[1];

        const answerSheetRef = ref(
          storage,
          `quizzes/${quizData.firebaseQuizId}/answerSheets/${studentId}.${fileExtenstion}`
        );

        //console.log()(`uploading answer sheet of ${studentName} `);
        const uploadResponse = await uploadBytes(answerSheetRef, file);

        const answerPath = uploadResponse.metadata.fullPath;
        //console.log()(`uploaded , path ===> ${uploadResponse.metadata.fullPath}`);

        const quizRef = doc(
          db,
          "users",
          studentId,
          "completedQuizzes",
          quizData.firebaseQuizId
        );

        await setDoc(quizRef, {
          ...quizData,
          scored: students[i].scored,
          answerPath: answerPath,
          updatedAt: Timestamp.fromDate(new Date()),
          startsAt: Timestamp.fromDate(new Date(quizData.startsAt)),
        });

        // //console.log()(docResponse);

        status = "uploaded"; //just mark the status

        // //console.log()(`quiz doc id => ${docResponse.id}`);

        //now get the firestore collection and upload
      } catch (singleUploadError) {
        //console.log()("error occured in single file upload");
        //console.log()(singleUploadError);
      }
    }
  } catch (error) {
    //console.log()("error occured while uploading some files");
    //console.log()(error);
  }

  return status;
}

export async function downloadAnswerSheet(answerPath) {
  var fileDownloaded = "ok";

  try {
    const answerRef = ref(storage, answerPath);

    //console.log()("file downloading");

    const downloadUrl = await getDownloadURL(answerRef);

    window.open(downloadUrl);
  } catch (error) {
    fileDownloaded = "error";
    //console.log()("error occured while downnloading ");
    showAlert("Some error occured while downloading your file ", 3000);

    //console.log()(error);
  }

  return fileDownloaded;
}

export async function downloadQuestionPaper(answerPath) {
  var fileDownloaded = "ok";

  try {
    const answerRef = ref(storage, answerPath);

    //console.log()("file downloading");

    const downloadUrl = await getDownloadURL(answerRef);

    window.open(downloadUrl);

    //console.log()("file url fetched " + downloadUrl);
  } catch (error) {
    fileDownloaded = "error";
    //console.log()("error occured while downnloading ");
    showAlert("Some error occured while downloading your file ", 3000);

    //console.log()(error);
  }

  return fileDownloaded;
}

//hide the quizzes which are uploaded right now to avoid too much of quizzes
export async function getAllCreatedQuizzes() {
  //console.log()("fetching quizzees");

  const quizRef = collection(db, "newQuizzes");

  const q = query(quizRef, orderBy("createdAt", "desc"));

  const data = await getDocs(q);

  // orderBy("startsAt", "desc"));

  const filterData = data.docs.map((doc) => {
    const date = doc.data().createdAt.toDate();
    const formatDate = formattedDate(date);

    let onlyDate;

    var reqDate = undefined;
    if (doc.data().startsAt !== undefined) {
      reqDate = doc.data().startsAt.toDate().toISOString().split(".")[0];
      onlyDate = doc.data().startsAt.toDate();
      // //console.log()(reqDate)
    }
    return {
      ...doc.data(),
      firebaseQuizId: doc.id,
      readableDate: formatDate,
      editableDate: reqDate,
      startsAt: onlyDate,
      duration: doc.data().duration / 60,
    };
  });

  // return filterData;

  const unHidedQuizzes = filterData.filter((quiz) => quiz.hideQuiz === false);
  console.log("quiizzes data " , unHidedQuizzes);
  return unHidedQuizzes;

  // return unHidedQuizzes.reverse();
}

export function formattedDate(date) {
  const month = MONTHS[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}
