import { Container, Row, Col, Badge } from "react-bootstrap";

function AnnouncementComponent({ announcements, classes }) {
  return (
    <div>
      {announcements.length === 0 ? (
        <Container
          style={{
            marginTop: "8px",
          }}
        >
          <p
            style={{
              fontSize: "25px",
              color: "#1B2430",
            }}
          >
            There are no announcements yet
          </p>
        </Container>
      ) : null}

      {announcements.map((message) => {
        return (
          <div className={classes.announcementContainer} key={message.id}>
            <div className={classes.padderContainer}>
              <div class="shadow p-3 mb-5 bg-white rounded">
                <Container fluid>
                  <Row>
                    <Col md="auto">
                      <img
                        class="rounded-circle shadow-4-strong"
                        height={"50px"}
                        width={"50px"}
                        alt="avatar2"
                        src={message.senderImage}
                        style={{ marginBottom: "8px" }}
                      />
                    </Col>
                    <Col>
                      <div className={classes.messageAuthor}>
                        {message.senderName}
                      </div>
                      <small className={classes.timeStyle}>
                        {message.createdAt.toDate().toDateString()}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <p className={classes.messageStyle}>{message.text}</p>
                  </Row>
                  <Badge>{message.notificationType}</Badge>

                  <Row>
                    <Col>
                      <Badge bg="dark">
                        {message.fromTime} - {message.toTime}
                      </Badge>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AnnouncementComponent;
