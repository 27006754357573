import { Row, InputGroup, Form } from "react-bootstrap";

const textSiz = "18px";

function CustomFormInputUI({
  type,
  placeholder,
  title,
  reffer,
  someDefaultValue,
  isFormDisabled,
  customTextSize = "18px",
  ...rest
}) {
  return (
    <div>
      <Row
        style={{
          marginBottom: "16px",
          marginTop: "16px",
        }}
      >
        <InputGroup>
          <InputGroup.Text id="inputGroupPrepend" style={{ fontSize: customTextSize }}>
            {title}
          </InputGroup.Text>
          <Form.Control
            disabled={isFormDisabled}
            autoComplete="on"
            defaultValue={someDefaultValue}
            ref={reffer}
            required
            type={type}
            placeholder={placeholder}
            // aria-describedby="inputGroupPrepend"
            style={{
              fontSize: textSiz,
            }}
            {...rest}
          />
        </InputGroup>
      </Row>
    </div>
  );
}

export default CustomFormInputUI;
