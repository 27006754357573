import { useState } from "react";
import { useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import { getStudentAssignments, isDueDatePassed } from "../../../controllers/assignment_controller";
import classes from "../../../css_modules/StudentAssignmentListPage.module.css";

import useStore from "../../../store/WebsiteStore";
import {
  AssignmentDataStoreInterface,
  StudentAssignmentData,
  TeacherAssignmentData,
} from "../../../models/assignment_model";
import { WebsiteStoreInterface } from "../../../models/website_global_state";
import { UserAuthDetails } from "../../../models/user_auth_deatils";
import { Shadows } from "react-bootstrap-icons";
import { convertToIndianISOString } from "../../../controllers/time_zone_controller";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { studentViewAssignmentUrl } from "./AssignmentDetailPage";
import assignmentStore from "../../../store/assignment_store";

//the student will be able to view their assignment here and be able to submit their assignment
function StudentAssignmentList() {
  const [studentAssignments, setStudentAssignments] = useState<
    StudentAssignmentData[]
  >([]);
  const [loadingAssingments, setLoadingAssignments] = useState(false);
  const currentStudentData = useStore<UserAuthDetails | undefined>(
    (state) => state.currentUserData
  );

  //for navigation
  const navigate = useNavigate();
  //State managment stores
  const setCurentStudentAssignment = assignmentStore(
    (state) => state.setCurrentStudentAssignmentData
  );

  useEffect(() => {
    const fetcher = async () => {
      if (currentStudentData?.id) {
        const assignmentDataList: StudentAssignmentData[] =
          await getStudentAssignments(
            currentStudentData.id,
            setLoadingAssignments
          );
        setStudentAssignments(assignmentDataList);
      }
    };

    fetcher();
    return () => { };
  }, [currentStudentData]);

  function getDueDate(time: Timestamp): string {
    return convertToIndianISOString(time, "DD-MM-YYYY - hh:mm a");
  }

  function navigateToAssignmentDetail(assignmentData: StudentAssignmentData) {
    //set the current assignment data as well
    setCurentStudentAssignment(assignmentData);
    navigate(studentViewAssignmentUrl);
  }

  function AssignmentStatus({
    assignment,
  }: {
    assignment: StudentAssignmentData;
  }) {
    //check the current date and check if the due date is less than the current date
    if (isDueDatePassed(assignment.dueDateAt))
      return (
        <div>
          <Badge
            bg={`${assignment.isSubmitted ? "warning" : "danger"}`}
            text={`${assignment.isSubmitted ? "black" : "white"}`}
          >
            {assignment.isSubmitted ? "Late Submission" : "Not submitted !!"}
          </Badge>
        </div>
      );
    else if (assignment.isSubmitted) {
      return <Badge bg={"success"}>Assignment Submitted</Badge>;
    }

    return null;
  }


  return (
    <div
      style={{
        marginTop: "16px",
      }}
    >
      <Container>
        {loadingAssingments ? (
          <h4>Loading assignments...</h4>
        ) : studentAssignments.length === 0 ? (
          <h5>You don't have any assignments yet</h5>
        ) : (
          <div>
            {studentAssignments.map((assignment) => {
              return (
                <Card className={classes.cardStyle}>
                  {/* <Card.Title></Card.Title> */}
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className={classes.assignmentTitle}>
                          <h5>{assignment.title}</h5>
                        </div>
                        <div>
                          <Badge bg="dark" text="white">
                            Due Date : {getDueDate(assignment.dueDateAt)}
                          </Badge>
                        </div>
                        {/* Show assignment submission status */}
                        <AssignmentStatus
                          assignment={assignment}
                        ></AssignmentStatus>
                      </Col>
                      <Col md="auto">
                        <Button
                          onClick={() => navigateToAssignmentDetail(assignment)}
                          className={classes.viewButton}
                        >
                          View
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        )}
      </Container>
    </div>
  );
}

export default StudentAssignmentList;
