import React from "react";
import { Badge, Row, Col, Button, Container, Stack } from "react-bootstrap";
import { Days, TimeTableSlotEntry } from "../../../models/time_table_model";
import { PlusCircle, XCircle } from "react-bootstrap-icons";

interface SlotItemProps {
  classNumber: string;
  weekDay: Days;
  slotEntryIndex: number;
  isTeacherView: boolean;
  timeTableData: { [key: string]: { [key: string]: TimeTableSlotEntry[] } };
  onAddSlotEntry: (classNumber: string, weekDay: Days) => void; // Function to be passed as prop
  onEditOrRemoveSlotEntry: (
    weekDay: Days,
    classNumber: string,
    slotEntryIndex: number,
    removeEntry: boolean
  ) => void; // Function to be passed as prop
}

const SlotItem: React.FC<SlotItemProps> = ({
  classNumber,
  weekDay,
  timeTableData,
  slotEntryIndex,
  isTeacherView,
  onAddSlotEntry: addSlotEntry,
  onEditOrRemoveSlotEntry: editOrRemoveSlotEntry,
}) => {
  const handleIconClick = () => {
    console.log("adding a slot");
    addSlotEntry(classNumber, weekDay);
  };

  function handleOnRemoveEntry(entryIndex: number) {
    console.log("Removing slot entry");
    editOrRemoveSlotEntry(weekDay, classNumber, entryIndex, true);
  }

  function handleEditEntry(entryIndex: number) {
    editOrRemoveSlotEntry(weekDay, classNumber, entryIndex, false);
  }

  return (
    <td key={`${classNumber}-${weekDay}-${slotEntryIndex}`}>
      <div key={`${classNumber}-${weekDay}-${slotEntryIndex}`}>
        {timeTableData[classNumber] && timeTableData[classNumber][weekDay] ? (
          timeTableData[classNumber][weekDay].map(
            (slotEntry: TimeTableSlotEntry, index: number) => (
              <div
                key={`${classNumber}-${weekDay}-${slotEntry.subjectName}-${index}`}
              >
                <Button
                  variant="dark"
                  size="sm"
                  style={{
                    marginTop: "8px",
                    color: "white"
                  }}
                  onClick={() => handleEditEntry(index)}
                >
                  {slotEntry.subjectName} | {slotEntry.teacherName} |{" "}
                  {slotEntry.fromTime} - {slotEntry.toTime}
                </Button>
                {isTeacherView ? (
                  <Button
                    variant="danger"
                    size="sm"
                    style={{
                      marginTop: "8px",
                      marginLeft: "4px",
                      paddingTop: "0px",
                      borderRadius: "8px",
                    }}
                    onClick={() => handleOnRemoveEntry(index)}
                  >
                    <XCircle></XCircle>
                  </Button>
                ) : null}
              </div>
            )
          )
        ) : (
          <div>
            <Badge
              bg="secondary"
              style={{
                marginTop: "8px",
              }}
            >
              No Entries
            </Badge>
          </div>
        )}

        {/* Add time table slot entry */}
        {isTeacherView ? (
          <Button
            size="sm"
            onClick={handleIconClick}
            className="mt-2"
            style={{
              paddingTop: "0px",
              borderRadius: "8px",
            }}
            variant="success"
          >
            <PlusCircle />
          </Button>
        ) : null}
      </div>
    </td>
  );
};

export default SlotItem;
