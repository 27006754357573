import { useState, useEffect } from "react";

import { db } from "../../../firebase-config";

import { useNavigate } from "react-router-dom";

import { collection, getDocs, query, orderBy } from "firebase/firestore";
// import { deepCopy } from "@firebase/util";

import useStore from "../../../store/WebsiteStore";

// import StudentNavBar from "../../components/layout/StudentNavBar";
// import ScaffoldLayout from "../components/layout/Scaffold";

function StudentsListPage() {
  const navigate = useNavigate();
  const [loading, setLoadingStatus] = useState(true);
  const [students, setStudents] = useState([]);

  const studentCollectionRef = collection(db, "users");

  const setCurrentUserData = useStore((state) => state.setCurrentUserData);

  useEffect(() => {
    // setLoadingStatus(true);
    const getStudents = async () => {
      const queryRef = query(studentCollectionRef, orderBy("class", "desc"));

      const data = await getDocs(queryRef);

      setStudents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); //TODO: Make a utility function for it

      //console.log()(students);

      return;
    };

    //console.log()("loading");
    getStudents().then(() => {
      //console.log()("loaded");
      setLoadingStatus(false);
    });

    //create an async function

    //this gets called when this page dismounts
    return () => {};
  }, [students, studentCollectionRef]);

  return loading ? (
    <div className="d-flex align-items-center">
      <div
        className="box"
        style={{
          marginTop: "50%",
          marginBottom: "50%",
          marginLeft: "30%",
        }}
      >
        <h1>Loading...</h1>
      </div>
    </div>
  ) : (
    <div
      style={{
        // textAlign: "center",
        marginLeft: "2%",
        marginTop: "2%",
        marginRight: "2%",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginBottom: "5px",
        }}
      >
        <h1>Ayachi Academy</h1>
      </div>

      <br></br>
      <div>
        {/* Students list */}
        {students.map((student) => {
          // return <h5>{student.username}</h5>

          return student.role === "student" ? (
            <div key={student.id}>
              <div className="container">
                <div
                  className="card"
                  style={{
                    marginBottom: "4px",
                    marginTop: "4px",
                  }}
                >
                  <div
                    className="row"
                    style={
                      {
                        // display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        // height : "200px"
                      }
                    }
                  >
                    <div className="col-6">
                      <div
                        style={{
                          padding: "8px",
                        }}
                      >
                        <h3> {student.username.toUpperCase()} </h3>
                      </div>
                    </div>

                    <div className="col-2">
                      <h3>{student.class}</h3>
                    </div>

                    <div className="col-1">
                      <div
                        style={{
                          position: "absolute",
                          textAlign: "right",
                          padding: "8px",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-info"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            //navigate to the student info page

                            setCurrentUserData(student);
                            navigate("/student-info");
                          }}
                        >
                          Info
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <h4>{student.role}</h4> */}
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

export default StudentsListPage;

// : (

// );
