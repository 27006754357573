import {
  addDoc,
  collection,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
  deleteDoc,
  orderBy,
  getDoc,
  setDoc,
  FieldPath,
  query,
  DocumentReference,
  DocumentData,
  QuerySnapshot,
} from "firebase/firestore";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../firebase-config";
import { getAllStudentsFromClassNumber } from "./quiz_controller";
import { showAlert, showSuccess } from "./toaster";
import { convertToIndianISOString } from "./time_zone_controller";
import {
  AssignmentStatus,
  StudentAssignmentData,
  TeacherAssignmentData,
} from "../models/assignment_model";

const storage = getStorage();

// export async function getStudentAssignments(
//   userId: string,
//   setLoadingAssignments: React.Dispatch<React.SetStateAction<boolean>>
// ) : Promise<TeacherAssignmentData[]> {
//   try {
//     const assignmentRef = collection(db, "users", userId, "assignments");

//     setLoadingAssignments(true);
//     // const data = await getDocs(assignmentRef, orderBy("dueDateAt"));
//     const q = query(assignmentRef, orderBy("dueDateAt", "desc"));
//     const data : QuerySnapshot<DocumentData> = await getDocs(q);

//     //console.log()("fetching assignments of student => " + userId);
//     const filteredData : TeacherAssignmentData[] = data.docs.map((doc) => {
//       return {
//         ...doc.data(),
//         id: doc.id,
//       };
//     });
//     // assignmentsData = filteredData;
//     setLoadingAssignments(false);
//     return filteredData;
//   } catch (error) {
//     //console.log()("error occured while getting the assignments");
//     //console.log()(error);
//     return [];
//   }
//   return [];
// }

export async function getStudentAssignments(
  userId: string,
  setLoadingAssignments: React.Dispatch<React.SetStateAction<boolean>>
): Promise<StudentAssignmentData[]> {
  try {
    const assignmentRef = collection(db, "users", userId, "assignments");

    setLoadingAssignments(true);

    const q = query(assignmentRef, orderBy("dueDateAt", "asc"));
    const data: QuerySnapshot<DocumentData> = await getDocs(q);

    //console.log("fetching assignments of student => " + userId);
    const filteredData: StudentAssignmentData[] = data.docs.map((doc) => {
      return {
        ...(doc.data() as StudentAssignmentData),
        id: doc.id,
      };
    });

    setLoadingAssignments(false);
    return filteredData;
  } catch (error) {
    //console.log("error occurred while getting the assignments");
    //console.error(error);
    setLoadingAssignments(false);
    return [];
  }
}

export async function getAllMadeAssignments(
  setLoadingAssignments: React.Dispatch<React.SetStateAction<boolean>>
) {
  //console.log()("loading all made assignments ");
  try {
    const assignmentRef = collection(db, "assignments");

    setLoadingAssignments(true);
    // const data = await getDocs(assignmentRef, orderBy("dueDateAt"));
    const q = query(assignmentRef, orderBy("dueDateAt", "desc"));
    const data = await getDocs(q);
    // const data = await getDocs(assignmentRef, orderBy("deadlineAt", "desc"));

    // //console.log()("fetching assignments of student => " + userId);
    const filteredData = data.docs.map((doc) => {
      // Convert to IST (Indian Standard Time)
      const options = { timeZone: "Asia/Kolkata", hour12: false };
      // const dateString = doc.data().dueDateAt.toDate().toLocaleString('en-GB', options);
      const dateString = convertToIndianISOString(doc.data().dueDateAt);
      const reqDate = doc.data().dueDateAt.toDate().toISOString().split(".")[0];
      console.log("dateString", dateString);
      console.log("reqDate", reqDate);
      // console.log("inmdian date string" , dateString);
      return {
        ...doc.data(),
        id: doc.id,
        readableDueDate: dateString,
      };
    });

    setLoadingAssignments(false);
    return filteredData;
  } catch (error) {
    //console.log()(
    //   "error occured while getting the assignments made by the teacher"
    // );
    //console.log()(error);
  }

  return [];
}

export async function createAssignment(
  assignmentName: string,
  setCreatingAssignment: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    setCreatingAssignment(true);
    const assignmentsRef = collection(db, "assignments");
    const dueDate = new Date();
    dueDate.setHours(dueDate.getHours() + 2);

    await addDoc(assignmentsRef, {
      title: assignmentName,
      createdAt: Timestamp.fromDate(new Date()),
      dueDateAt: Timestamp.fromDate(dueDate), //initially set curent date as due date
      storagePathCloud: null,
      isUploadedOnStorage: false,
      fileName: null,
      hasQuestionPaper: false,
    }).then((document) => {
      const docRef = doc(db, "assignments", document.id);

      updateDoc(docRef, {
        id: document.id,
      });
    });

    showSuccess("Assignment Created", 4000);
  } catch (error) {
    //console.log()("error in making assignment");
    //console.log()(error);
    showAlert("Error in creating the assignment", 5000);
    console.log("creating assignment error", error);
  } finally {
    setCreatingAssignment(false);
  }
}

export async function deleteAssignment(assignmentId: string) {
  try {
    const assignedStudents = await getAlreadyAssignedStudents(assignmentId);

    if (assignedStudents && assignedStudents.length > 0) {
      //show the alert and don't delete
      showAlert("Please unassign this assignment before deleting", 5000);
    } else {
      const assignmentDocRef = doc(db, "assignments", assignmentId);
      await deleteDoc(assignmentDocRef);
    }
  } catch (error) {
    //console.log()("error in deleting the assignmnent");
    //console.log()(error);
    showAlert("Some error occured while deleting the assignment ", 5000);

    console.log("error occurred in deleting the assignment", error);
  }
}

//delete the uploaded assignment to the student
export async function deleteAssignmentFromStudent(
  assignmentId: string,
  studentId: string
) {
  try {
    const document = doc(db, "users", studentId, "assignments", assignmentId);

    await deleteDoc(document);
  } catch (error) {
    //console.log()("error in deleting the assignmnent");
    //console.log()(error);
    showAlert("Some error occured while deleting the assignment ", 5000);
    console.log(
      "error occurred in deleting the assignment from the student",
      error
    );
  }
}

// /**
//  * Updates the assignment for the following students like changing the name of the assignment or changing the grading
//  * of the assignment
//  */
// export async function updateAssignment(
//   assignmentId : string,
//   assignmentData : TeacherAssignmentData,
//   setUpdating: React.Dispatch<React.SetStateAction<boolean>>
// ) {
//   try {
//     setUpdating(true);
//     const assignmentDoc : DocumentReference<DocumentData> = doc(db, "assignments", assignmentId);

//     const getProperDueDate = assignmentData.dueDateAt;
//     //delete the createdAt key as that's not be updated
//     await updateDoc<TeacherAssignmentData>(assignmentDoc, assignmentData);

//     showSuccess("Assignment Saved !!", 5000);
//   } catch (error) {
//     //console.log()("error in deleting the assignmnent");
//     //console.log()(error);
//     showAlert("Some error occured while updating the assignment ", 5000);
//   } finally {
//     setUpdating(false);
//   }
// }

export async function updateAssignment(
  assignmentId: string,
  assignmentData: TeacherAssignmentData,
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    setUpdating(true);
    const assignmentDoc: DocumentReference<DocumentData> = doc(
      db,
      "assignments",
      assignmentId
    );

    // Remove the createdAt key as that should not be updated
    const { createdAt, ...updateData } = assignmentData;

    await updateDoc(assignmentDoc, updateData);

    showSuccess("Assignment Saved !!", 5000);
  } catch (error) {
    // Handle the error appropriately
    console.error("Error updating the assignment:", error);
    showAlert("Some error occurred while updating the assignment", 5000);
  } finally {
    setUpdating(false);
  }
}

export async function uploadAssignmentToStudents(
  assignmentData: TeacherAssignmentData,
  removedStudents: any[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  setLoading(true);
  const assignmentId = assignmentData.id;
  const assignedStudents = assignmentData.assignedTo;
  assignedStudents.forEach(async (student) => {
    //keeping in a try catch b
    try {
      const studentId = student.id;
      console.log("assigning/updating to ", student.username);
      const assigmentDocRef = doc(
        db,
        "users",
        studentId,
        "assignments",
        assignmentId
      ); //set the doc with the assignment id for the user
      const studentAssignmentData: StudentAssignmentData = {
        dueDateAt: assignmentData.dueDateAt,
        id: assignmentData.id,
        marks: assignmentData.marks,
        question: assignmentData.question,
        title: assignmentData.title,
        allowLateSubmit: assignmentData.allowLateSubmit,
        graded: assignmentData.graded,
        isSubmitted: false,
        submittedAt: null,
        assignmentStatus: AssignmentStatus.STATUS_UNKNOWN,
        hasQuestionPaper: assignmentData.hasQuestionPaper,
        storagePathCloud: assignmentData.storagePathCloud,
        hasAnswerSheetFileAttachment: false //by default it will be false only
      };

      //before assigning remove unwanted fields
      await setDoc(assigmentDocRef, studentAssignmentData);
    } catch (error) {
      console.log(
        "error occurred in uploading assignment to => ",
        student.username,
        error
      );
      showAlert("Error occured in uploading file to student", 5000);
      return false;
    }
  });

  //remove assignment from the removed students
  removedStudents.forEach(async (student) => {
    try {
      const assigmentDocRef = doc(
        db,
        "users",
        student.id,
        "assignments",
        assignmentId
      ); //set the doc with the assignment id for the user

      await deleteDoc(assigmentDocRef);
    } catch (error) {
      console.log(
        "error occurred while removinmg the assignment form the student",
        student.username
      );
    }
  });

  setLoading(false);
  return true;
  //go to the users doc and then upload the assignment to the specific students
}

export async function fetchNotSelectedStudentsFromClass(
  classNumber: number,
  assignmentId: string,
  setAlreadySelected: React.Dispatch<React.SetStateAction<any[]>>,
  setNotSelected: React.Dispatch<React.SetStateAction<any[]>>,
  setSelectedStudents: React.Dispatch<React.SetStateAction<any[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  setLoading(true);
  const allStudents = await getAllStudentsFromClassNumber(classNumber);

  //get already assigned students
  const alreadyAssigned = await getAlreadyAssignedStudents(assignmentId);

  if (alreadyAssigned !== undefined) setAlreadySelected(alreadyAssigned);

  //console.log()("all students ", allStudents);
  //console.log()("already assigned students", alreadyAssigned);

  const filtered = removeAlreadySelected(alreadyAssigned, allStudents);

  if (filtered !== undefined) {
    setNotSelected(filtered);
    const additionalData = filtered.map(
      (document: { id: any; username: any }) => {
        return {
          id: document.id,
          username: document.username,
          studentSelected: false,
        };
      }
    );

    setSelectedStudents(additionalData);
  }
}
async function getAlreadyAssignedStudents(assignmentId: string) {
  //console.log()("check first ", assignmentId);
  var assigned = [];
  try {
    const document = doc(db, "assignments", assignmentId);

    const data = await getDoc(document);
    if (data && data.data()) {
      assigned = data.data()?.assignedTo;
    }
  } catch (error) {
    //console.log()("error occured in getting assigned students", error);
  }
  return assigned;
}

//removes the already selected students from the list
function removeAlreadySelected(
  alreadySelectedStudents: any[],
  allStudents: any
) {
  const notSelectedStudents = [];
  if (alreadySelectedStudents === undefined) return allStudents;

  for (var i = 0; i < allStudents.length; i++) {
    const student = allStudents[i];
    var present = false;
    for (var j = 0; j < alreadySelectedStudents.length; j++) {
      //check if a student is there in this already selected list or not
      if (student.id === alreadySelectedStudents[j].id) {
        present = true;
        //console.log()("present", student.username);
        break;
      }
    }
    //now if present is false then only add it to the correct student list
    if (!present) notSelectedStudents.push(student);
  }

  return notSelectedStudents;
}

export async function uploadAssignmentQPaper(
  questionPaperFile: any,
  assignmentData: TeacherAssignmentData,
  setFileUploading: React.Dispatch<React.SetStateAction<boolean>>
) {
  setFileUploading(true);
  //console.log()("uploading the question paper ");

  const fileExtenstion = questionPaperFile.name.split(".")[1];

  const questionPaperRef = ref(
    storage,
    `assignments/${assignmentData.id}/questionPaper/${assignmentData.id}.${fileExtenstion}` //for students it will be submissions
  );

  var fullPath = "";
  try {
    const uploadSnapshot = await uploadBytes(
      questionPaperRef,
      questionPaperFile
    );

    fullPath = uploadSnapshot.metadata.fullPath;
  } catch (error) {
    //console.log()("error occured while uploading the file ");
    fullPath = "error/404";
    showAlert(`Failed to upload the question file ${error}`);
    console.log("error occurred while uploading", error);
  }
  setFileUploading(false);
  return fullPath;
}


/** 
 * Helper file to upload the answer sheet on firebase storage 
 * 
*/
export async function uploadSolvedAssignmentFile(
  questionPaperFile: any,
  assignmentData: StudentAssignmentData | undefined,
  setFileUploading: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (assignmentData) {
    setFileUploading(true);
    //console.log()("uploading the question paper ");

    const fileExtenstion = questionPaperFile.name.split(".")[1];

    const questionPaperRef = ref(
      storage,
      `assignments/${assignmentData.id}/answerSheet/${assignmentData.id}.${fileExtenstion}` //for students it will be submissions
    );

    var fullPath = "";
    try {
      const uploadSnapshot = await uploadBytes(
        questionPaperRef,
        questionPaperFile
      );

      fullPath = uploadSnapshot.metadata.fullPath;
    } catch (error) {
      //console.log()("error occured while uploading the file ");
      fullPath = "error/404";
      showAlert(`Failed to upload the question file ${error}`);
      console.log("error occurred while uploading", error);
      throw Error("Error occurred while uploading the paper")
    }
    setFileUploading(false);
    return fullPath;
  }
  throw Error("Error occurred while uploading the paper")
}


export async function assignmentSubmitByStudent(
  assignmentId: string,
  studentId: string,
  assignmentData: StudentAssignmentData,
  setAssignmentUploading: React.Dispatch<React.SetStateAction<boolean>>
) {
  try {
    setAssignmentUploading(true);
    const assignmentDoc: DocumentReference<DocumentData> = doc(
      db,
      "users",
      studentId,
      "assignments",
      assignmentId
    );
    await updateDoc(assignmentDoc, {
      ...assignmentData
    });
    showSuccess("Assignment Submitted !!", 5000);
  } catch (error) {
    // Handle the error appropriately
    console.error("Error updating the assignment:", error);
    showAlert("Some error occurred while updating the assignment", 5000);
  } finally {
    setAssignmentUploading(false);
  }
}


//TODO : Fix the due date Timing fix , should be internationally acceptable
export function isDueDatePassed(dueDate: Timestamp) {
  // const currTime = Timestamp.now().toMillis();
  // const currTime = moment().tz("Asia/Kolkata").toDate();
  const currTime = Timestamp.now().toDate();
  const dueDateMilis = dueDate.toDate();
  // console.log("curr time", currTime);
  // console.log("due date", dueDateMilis);
  return currTime > dueDateMilis;
}
