import {
  collection,
  getDocs,
  query,
  orderBy,
  setDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { sha256 } from "js-sha256";

import { db } from "../firebase-config";
import { showAlert, showSuccess } from "./toaster";

//add a payment in the database and for the single user also
//also update their doc with the latest payment that they made
export async function addStudentPayment(
  studentData,
  paymentData,
  setProcessing
) {
  console.log(studentData);
  console.log(paymentData);
  try {
    const studentPaymentId = `${paymentData.forYear}-${paymentData.forMonth}`;
    const globalPaymentId = `${studentData.id}-${studentData.username}-${studentPaymentId}`;

    const studentDocRef = doc(
      db,
      "users",
      studentData.id,
      "payments",
      studentPaymentId
    );
    const globalPaymentsRef = doc(db, "payments", globalPaymentId);

    setProcessing(true);
    //console.log()("uploading to users payment collection");
    await setDoc(studentDocRef, paymentData);
    //console.log()("uploaded to students collection");

    //console.log()("uploading to global payments");
    await setDoc(globalPaymentsRef, paymentData);
    //console.log()("uploaded to global payments");

    const studentDoc = doc(db, "users", studentData.id);
    // also updating last paid of the user in their own acoount
    let data = await getDoc(studentDoc);

    data = { ...data.data(), id: data.id };

    const lastPayed = data.lastPayed;

    var isNewPayment = checkIfNewPayment(lastPayed, paymentData.forBillDate);

    if (isNewPayment) {
      console.log("new payment being added ");

      //then only update in the user's db
      await updateDoc(studentDoc, {
        lastPayed: paymentData.forBillDate,
        // lastPayed: Timestamp.fromDate(new Date()),
      });
    }
  } catch (error) {
    console.log("Error occured in payments", error);
    showAlert(error, 15000); // not a good idea to show the error but anyways , dad will contact me for it
  } finally {
    setProcessing(false);
  }
}

//gets the students from all class groups
export async function getStudentsFromClassGroup() {
  const usersRef = collection(db, "users");

  const q = query(usersRef, orderBy("createdAt", "asc"));

  const userDocs = await getDocs(q);

  const jsonData = userDocs.docs.map((doc) => {
    //lets create the document which will filter the users data
    const data = { ...doc.data() };

    return data;
  });

  var filteredData = jsonData.filter(
    (element) =>
      element.role !== "teacher" && element.activeUser && element.class !== 23
  );

  return filteredData;
}

export async function getStudentPaymentInfo(studentId) {
  if (studentId) {
    console.log("loading students payment info from id ", studentId);
    //first get the payments collection of the specific student
    const paymentRef = collection(db, "users", studentId, "payments");

    const q = query(paymentRef, orderBy("addedAt", "desc"));

    //get the documents from this collection then
    const paymentDocs = await getDocs(q);

    const filteredData = paymentDocs.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
    console.log("payments data" , filteredData);

    return filteredData;
  }else{
    console.log("student id is invalid", studentId);
  }
}

//gets the students from all class groups
export async function getAllStudentsForPayment() {
  const usersRef = collection(db, "users");

  const q = query(usersRef, orderBy("lastPayed", "asc"));

  const userDocs = await getDocs(q);

  const jsonData = userDocs.docs.map((doc) => {
    //lets create the document which will filter the users data
    const data = { ...doc.data() };

    return data;
  });

  var filteredData = jsonData.filter(
    (element) =>
      element.role !== "teacher" && element.activeUser && element.class !== 23
  );

  return filteredData;
}

export async function deletePayment(paymentData, setDeleting, studentData) {
  //before allowing delete operation ask the pin for deleting the payment
  const pinValue = prompt("This a HIGH Level Operation PLEASE ENTER THE KEY ");
  //already existing data
  const studentPaymentId = `${paymentData.forYear}-${paymentData.forMonth}`;
  const globalPaymentId = `${studentData.id}-${studentData.username}-${studentPaymentId}`;

  try {
    var checkPin = await checkDeleteAccess(pinValue);

    if (!checkPin) {
      showAlert("Wrong pin !!", 5000);
      return;
    }

    showSuccess("Correct pin Deleting the payment", 2000);

    setDeleting(true);

    await archivePayment(globalPaymentId, studentPaymentId, studentData.id);
  } catch (error) {
    console.log("error occured while deleting", error);
  } finally {
    setDeleting(false);
  }
}

/**
 * Archives the payment made by the user so that it can be later used to recover any payments done by mistake
 */
async function archivePayment(
  globalPaymentId,
  studentPaymentId,
  studentUserId
) {
  //archive collection
  const globalPaymentArchive = doc(db, "archivedPayments", globalPaymentId);
  const studentPaymentArchive = doc(
    db,
    "users",
    studentUserId,
    "archivedPayments",
    studentPaymentId
  );
  console.log("archive payment called");
  //first delete the record from the global payments database
  const globalPaymentDoc = doc(db, "payments", globalPaymentId);
  const studentPaymentDoc = doc(
    db,
    "users",
    studentUserId,
    "payments",
    studentPaymentId
  );

  try {
    //get the global payment and the students payment document
    const globalPaymentDocData = await getDoc(globalPaymentDoc);
    //get the student payment and the students payment document
    const studentPaymentDocData = await getDoc(studentPaymentDoc);

    // console.log(globalPaymentDocData);
    // console.log(studentPaymentDocData);

    //add the payments in the archive
    await setDoc(globalPaymentArchive, globalPaymentDocData.data());
    await setDoc(studentPaymentArchive, studentPaymentDocData.data());
  } catch (error) {
    console.log("some error occured while archiving the payment ", error);
  } finally {
    //TODO: look for the delete safety feature cuz if some error occurs it will be hard to recover from it
    await deleteDoc(globalPaymentDoc); //be careful while doing deletions
    await deleteDoc(studentPaymentDoc);
  }
}

function checkIfNewPayment(lastPayment, newPayment) {
  if (lastPayment === null) return true; //as this is the new payment

  try {
    const lastPayed = lastPayment.toDate().getTime();
    const newPayed = newPayment.toDate().getTime();

    const difference = newPayed - lastPayed >= 0;
    //that means it's new
    if (difference) {
      console.log("new date");
      return true;
    } else return false;
  } catch (error) {
    console.log("error in checking new payment", error);
    return false;
  }
}

async function checkDeleteAccess(inputKey) {
  const shaValue = sha256(inputKey);
  console.log(shaValue);

  const adminDoc = await getDoc(doc(db, "adminControls", "paymentControls"));
  //get the admin controls sha delete key
  const deleteKey = adminDoc.data().deleteKey;

  return shaValue === deleteKey;
}

export async function getAllCollectedCash() {
  //iterate through all the payments and get the generated cash
  const paymentsRef = collection(db, "payments");

  let total = 0;

  const paymentsDocs = await getDocs(paymentsRef);

  paymentsDocs.forEach((doc) => {
    console.log(doc.data());
    try {
      total += doc.data().amount;
    } catch (error) {
      // showAlert("Some error occured" , error)
      console.log(error);
      return -1;
    }
  });

  // console.log("docs" , paymentsDocs)
  return total;
}
