import useStore from "../../store/WebsiteStore";
import { Button } from "react-bootstrap";
import { useNavigate} from "react-router-dom";
import { singleStudentAcaademicInfoUrl } from "../../utils/all_routes";
// import StudentNavBar from "./layout/StudentNavBar";

function CompletedQuizPageOnlineMode({ goBackUrl }) {
  const quizData = useStore((state) => state.completedQuizData);
  const navigate = useNavigate();

  const setCompleteQuizData = useStore((state) => state.setCompletedQuizData);
  const userRoleType = useStore((state) => state.userRoleType);

  // //console.log()(quizData)

  //console.log()(quizData);

  function colorReturner(correctAnsIndex, optionSelectedIndex, currentIndex) {
    //there are three cases
    //so if the option selected is not in the correctAnsIndex
    //only color the selected indices
    if (
      currentIndex !== correctAnsIndex &&
      currentIndex !== optionSelectedIndex
    )
      return { bgColor: "#ededed", textColor: "black" };
    else {
      //now two conditions are there

      if (currentIndex === correctAnsIndex)
        return { bgColor: "#1ad9a9", textColor: "white" };

      if (
        currentIndex === optionSelectedIndex &&
        correctAnsIndex !== optionSelectedIndex
      )
        return { bgColor: "#ed3999", textColor: "white" };
    }
  }

  function goBackHandler() {
    //navigate to appropriate routes when going back
    //console.log()("back clicked online mode");
    if (userRoleType === "teacher") {
      //console.log()("admin go back clicked");
      navigate(singleStudentAcaademicInfoUrl);
    } else {
      navigate("/studentAcademics");
    }
    setCompleteQuizData({});
    //also clear the quiz data
  }

  return (
    <div>
      {/* <StudentNavBar sticky="top"></StudentNavBar> */}
      <div
        style={{
          marginLeft: "2%",
          marginRight: "2%",
          marginTop: "2%",
        }}
      >
        <div className="container">
          <div className="row">
            <h2>{quizData.title}</h2>
            <div className="col">
              <h4>
                {quizData.scored} / {quizData.marks} marks{" "}
              </h4>
            </div>
          </div>
          <Button onClick={goBackHandler}>Go Back</Button>
          <hr></hr>
          <div className="row">
            <div className="col">
              <h4>Questions</h4>
            </div>
          </div>

          {/* show the questions in a container  */}

          {quizData.questions.map((question, questionIndex) => {
            return (
              <div
                key={questionIndex}
                className="card"
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  className="container"
                  style={{
                    borderWidth: "2px",
                    borderColor: "cyan",
                    padding: "16px",
                    // marginBottom: "8px",
                  }}
                >
                  <div className="row">
                    <div className="col">
                      <b>{question.questionText}</b>
                    </div>

                    <div
                      style={{
                        marginTop: "1%",
                      }}
                    >
                      <b>{question.marks} Marks</b>
                    </div>

                    <div
                      style={{
                        color:
                          question.correctAns === question.optionSelected
                            ? "blue"
                            : "red",
                      }}
                    >
                      <b>
                        {question.correctAns === question.optionSelected
                          ? "Correct"
                          : "Incorrect"}
                      </b>
                    </div>
                  </div>

                  {/* also show whether the ans they answered was correct or not */}
                  <br></br>
                  {/* now iterate through the options  */}
                  {question.options.map((option, optionIndex) => {
                    return (
                      <div
                        key={optionIndex}
                        className="card"
                        style={{
                          padding: "2px",
                          // margin: "2%px",
                          marginTop: "1%",
                          marginBottom: "1%",
                          borderWidth: "2.5px",

                          backgroundColor: colorReturner(
                            question.correctAns,
                            question.optionSelected,
                            optionIndex
                          ).bgColor,

                          // // backgroundColor: "red",
                          // borderColor: colorReturner(
                          //   question.correctAns,
                          //   question.optionSelected,
                          //   optionIndex
                          // ),
                          fontSize: "15px",

                          //   question.correctAns === optionIndex ? "green" : "red",
                        }}
                      >
                        <span
                          style={{
                            color: colorReturner(
                              question.correctAns,
                              question.optionSelected,
                              optionIndex
                            ).textColor,
                          }}
                        >
                          <b>{option.text}</b>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CompletedQuizPageOnlineMode;
