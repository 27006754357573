export interface TimeTable {
  [classNumber: string]: DayEntry;
}

/**
 * WIll be a map of day entries
 */
export interface DayEntry {
  [day: string]: TimeTableSlotEntry[];
}

/**
 * This interface is responsible for adding the slot entry
 */
export interface TimeTableSlotEntry {
  slotEntryId?: string;
  teacherName: string; //the taecher who will be taking the class
  class: string;
  subjectName: string;
  fromTime: string;
  toTime: string; //becase it has been mentioned that the time can change
  forDay: Days;
}

export enum Days {
  MON = "Mon",
  TUE = "Tue",
  WED = "Wed",
  THU = "Thu",
  FRI = "Fri",
  SAT = "Sat",
  SUN = "Sun",
  NONE = "None", //no selection
}

export const timeTableDummyData: TimeTable = {};
