import { useRef, useState } from "react";

// import { getStudentDetails } from "../../controllers/student_controller";
//
import classes from "../../css_modules/StudentMyProfile.module.css";
import { useEffect } from "react";

import { updateStudentProfile } from "../../controllers/student_controller";

import useStore from "../../store/WebsiteStore";

import EditableText from "../../components/ui/EditableText";

import { Container, Button, Alert } from "react-bootstrap";

import ProfilePicCompo from "../../components/ui/ProfilePic";
import { isEditable } from "@testing-library/user-event/dist/utils";

function StudentMyAccount() {
  //get all the details of the user

  // const [studentDetails, setStudentDetails] = useState({});
  const setCurrentUserData = useStore((state) => state.setCurrentUserData);

  const [editDetails, setEditDetails] = useState(false);

  const schoolRef = useRef();
  const mobileRef = useRef();
  const nameRef = useRef();
  // const emailRef = useRef();

  // const currentUserId = useStore((state) => state.currentUserId);

  const userDetails = useStore((state) => state.currentUserData);
  const [isUserEditBlocked, setIsUserEditBlocked] = useState(false);

  useEffect(() => {
    // setStudentDetails(userDetails);
    checkIfUserEditIsBlocked(userDetails);
    return () => {};
  }, []);

  const editHandler = () => {
    if (!isUserEditBlocked) {
      if (editDetails === false) {
        setEditDetails(true); //toggle to edit details
        //then also save the new details entered
      } else {
        console.log("saving changes");
        setEditDetails(false); //toggle to non edit details
        try {
          const nameLen = nameRef.current.value.length;
          const schoolLen = schoolRef.current.value.length;
          const mobileLen = mobileRef.current.value.length;

          const check = nameLen > 0 && schoolLen > 0 && mobileLen > 0;
          if (check) {
            const newProfile = {
              ...userDetails,
              username: nameRef.current.value,
              school: schoolRef.current.value,
              mobileNo: mobileRef.current.value,
            };

            setCurrentUserData(newProfile);

            updateStudentProfile(newProfile);
          }

          //then actually update on the database
        } catch (error) {
          console.log("Issue in editing profile", error);
          //console.log()("error occured");
          //console.log()(error);
        }
      }
    }
  };

  function checkIfUserEditIsBlocked(userData) {
    console.log("checking if user is blocked");
    if (userData && userData.isUserEditBlocked) {
      setIsUserEditBlocked(true);
    }
  }

  function EditBlockedUi() {
    if (isUserEditBlocked) {
      return (
        <Alert variant="danger">
          You can't edit your profile (Blocked by Admin)
        </Alert>
      );
    }
  }

  return (
    <Container
      style={{
        // marginLeft: "4%",
        marginTop: "4%",
        fontFamily: "Raleway",
        // marginRight: "4%",
      }}
    >
      {/* <StudentNavBar sticky={"top"} onlyShowBanner={false} /> */}

      {/* {editDetails === false ? (
        <h2
          className={classes.text}
          onClick={() => {
            setEditName(true);
          }}
        >
          {studentDetails.username} ({studentDetails.role})
        </h2>
      ) : (
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control type="text" placeholder={studentDetails.username} />
        </Form.Group>
      )} */}

      {userDetails.imageUrl !== undefined ? (
        <ProfilePicCompo userDetails={userDetails} />
      ) : null}

      <h2 className={classes.text}>
        {userDetails.username} ({userDetails.role})
      </h2>

      <EditBlockedUi />

      <Button
        size="sm"
        onClick={editHandler}
        disabled={isUserEditBlocked}
        variant={editDetails ? "success" : "primary"}
      >
        {editDetails ? "Save" : "Edit Profile"}
      </Button>

      {/* <p className={classes.text}>Joined on {studentDetails.createdAt}</p> */}

      <hr />

      <EditableText
        editDetails={editDetails}
        title="Email"
        content={userDetails.email}
        cantEdit={true}
        // reffer={emailRef}
      />

      <EditableText
        editDetails={editDetails}
        title="Class"
        content={userDetails.class}
        cantEdit={true}
        // reffer={nameRef}
      />

      <EditableText
        editDetails={editDetails}
        title="Name"
        content={userDetails.username}
        cantEdit={false}
        reffer={nameRef}
      />

      <EditableText
        editDetails={editDetails}
        title="School"
        content={userDetails.school}
        classes={classes}
        cantEdit={false}
        reffer={schoolRef}
      />

      <EditableText
        editDetails={editDetails}
        title="Mobile"
        content={userDetails.mobileNo}
        cantEdit={false}
        reffer={mobileRef}
      />
      {/* <p className={classes.subText}>Email : {studentDetails.email}</p>

      <p className={classes.subText}>School : {studentDetails.school}</p>

      <p className={classes.subText}>Mobile : {studentDetails.mobileNo}</p> */}
    </Container>
  );
}

export default StudentMyAccount;
