import create from "zustand";

import { persist } from "zustand/middleware";

const customHandler = (set, get) => ({
  currentStudentData: "",

  setCurrentStudentData: (data) => set((state) => ({ currentStudentData: data }))
});

const studentStore = create(
  // customHandler,
  persist(customHandler, {
    name: "ayachi-student-data",
    // storage: localStorage,
  })
);

export default studentStore;
