import { Row, Col } from "react-bootstrap";

function DatePicker({
  setPickedDate,
  pickedDate,
  isQuizUpdatedEver,
  initialValue,
  labelText,
  alsoShowTimeStamp = true
}) {
  if (!alsoShowTimeStamp) {
    initialValue = initialValue.split("T")[0];
  }
  return (
    <Row>
      <Col md="auto">
        <h5> {labelText}</h5>
      </Col>
      <Col md="">
        <input
          disabled={isQuizUpdatedEver}
          type={alsoShowTimeStamp ? "datetime-local" : "date"}
          onChange={(e) => setPickedDate(e.target.value)}
          defaultValue={initialValue}
        />
      </Col>
    </Row>
  );
}

export default DatePicker;
