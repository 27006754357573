import { logEvent } from "firebase/analytics";
import { Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Container, Form, Row, Table } from "react-bootstrap";
import DatePicker from "../../../components/ui/DatePicker";
import { getStudentsFromClassGroup } from "../../../controllers/payments_controller";
import { formattedDate } from "../../../controllers/quiz_controller";
import { getStudentsAttendanceDetailsForDate, updateAttendanceOfStudent } from "../../../controllers/student_attendance_controller";
import { convertToIndianISOString, convertToIndianISOStringFromDate } from "../../../controllers/time_zone_controller";
import { showAlert } from "../../../controllers/toaster";
import { analytics } from "../../../firebase-config";
import { AttendanceRecord } from "../../../models/student_attendance_model";
import { UserAuthDetails } from "../../../models/user_auth_deatils";
import { isNumeric } from "../../../utils/utlsFunction";


export const updateAttendancePageUrl = "/take-attendance";

interface AttendaceUpdatePageProps {

}

const AttendanceUpdatePage: React.FC<AttendaceUpdatePageProps> = ({ }) => {

  const [studentsAttendanceData, setStudentsAttendanceData] = useState<AttendanceRecord[]>([])
  const [filteredStudents, setFilteredStudents] = useState<AttendanceRecord[]>([]) //apply status of absent and present on filtered students only
  const [pickedDate, setPickedDate] = useState<string | null | undefined>(convertToIndianISOStringFromDate(new Date()));
  const [isHoliday, setIsHoliday] = useState(false);


  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    async function getUsers() {
      try {
        logEvent(analytics, "attendance_records_fetched", {
          "date": Timestamp.fromDate(new Date())
        })
        console.log("loading student attendance details");
        setLoading(true);
        let studentAttendanceRecords: AttendanceRecord[] = []
        const todaysDate = new Date();
        studentAttendanceRecords = await getStudentsAttendanceDetailsForDate(convertToIndianISOStringFromDate(todaysDate).split("T")[0]);
        setStudentsAttendanceData(studentAttendanceRecords);
        setFilteredStudents(studentAttendanceRecords);
      } catch (error) {
        logEvent(analytics, "error_attendance_page_load_failed", {
          error: error
        })
        showAlert("Some error occured while getting students ", error);
        console.log("some error ocurred in getting the records", error);
      } finally {
        setLoading(false);
      }
    }

    // console.log("the picked up date is ", convertToIndianISOStringFromDate(new Date()))
    setPickedDate(convertToIndianISOStringFromDate(new Date()).split("T")[0])
    console.log("The picked date is ", pickedDate);
    getUsers();
    return () => {
    }
  }, [])

  function updateStudentAttendanceInfo(index: number, isPresent: boolean | null, _isHoliday: boolean) {
    console.log("the selected date is", pickedDate);
    // console.log(attendanceStatus)
    setStudentsAttendanceData(prevData => {
      //update the attedance detail of the student right away
      return prevData.map((student, i) => {
        if (i === index) {
          //update only for the selected index
          if (pickedDate) {
            updateAttendanceOfStudent(student.userId, pickedDate.split("T",)[0], !student.isPresent, _isHoliday);
          }
          if (!_isHoliday) {
            return { ...student, isPresent: isPresent, isHoliday: false }
          } else {
            return { ...student, isPresent: false, isHoliday: true }
          }
        } else {
          return student
        }
      }
      )
    }
    );
  }

  /**
   * This functions marks the attendance of students today as a holiday so that it will 
   * not be considered in the attendance calculation for the different users while getting their
   * attendance calculation
   * */
  function markTodayAsHoliday() {
    for (let idx = 0; idx < filteredStudents.length; idx++) {
      // updateStudentAttendanceInfo(idx);
    }
  }

  async function setPickedUpDate(selectedDate: Date) {
    setLoading(true);
    console.log("upated the picked up date", selectedDate)
    setPickedDate(convertToIndianISOStringFromDate(selectedDate))

    //after setting up the picked up date also update the reocrds of that date
    const studentAttendanceRecords = await getStudentsAttendanceDetailsForDate(convertToIndianISOStringFromDate(selectedDate).split("T")[0]);
    setStudentsAttendanceData(studentAttendanceRecords);
    setLoading(false);
  }

  //searches the given parametrs (for ex => a class or a student's name)
  //and returns true for mathcing searches
  function searcher(student: AttendanceRecord, _searchField: string) {
    if (searchField.length === 0) return true;

    //first check if string is numeric or not
    if (!isNumeric(searchField)) {
      //if it's not numeric then we will do a regex check only then
      //create the regex to search
      try {
        const regex = new RegExp(`^${searchField}`, "i"); //and also keep it case insensitive
        return regex.test(student.userName);
      } catch (error) {
        //console.log()("error in pattern matching");
        //console.log()(error);

        //if some error then return all the results as such
        return true;
      }
    }
    return student.class === parseInt(searchField);
  }

  /**
   * Ths button status that needs to be returned in case of the status 
   * If the student is present then the success wil be returned based on that condition
   *
   * @parametrs requestedFor - the requested for string is for the css requested for the 
   * specific button that needs to be returned 
   * */
  function buttonStatus(studentAttendanceRecord: AttendanceRecord, requestedFor: string) {
    switch (requestedFor) {
      case "absent":
        if (!studentAttendanceRecord.isHoliday && !studentAttendanceRecord.isPresent) return "danger";
        console.log("absent is ", studentAttendanceRecord.userName)
        return "outline-danger" // the default return status
      case "present":
        if (!studentAttendanceRecord.isHoliday && studentAttendanceRecord.isPresent) return "success";
        console.log("present is ", studentAttendanceRecord.userName)
        return "outline-success"
      case "holiday":
        console.log(`holiday is ${studentAttendanceRecord.userName}`)
        if (studentAttendanceRecord.isHoliday) return "secondary";
        return "outline-secondary"
      default:
        return "info"
    }
    // if (studentAttendanceRecord.isHoliday) return 'info';

  }

  return (
    <Container
      style={{
        marginTop: "2%",
      }}
    >
      <div>
        <Row>
          <Col>
            {/* Search field form control */}
            <Form.Control
              style={{
                fontSize: "20px",
              }}
              placeholder="Search..."
              onChange={(event) => {
                setSearchField(event.currentTarget.value);
              }}
            ></Form.Control>
          </Col>
          <Col>
            <DatePicker
              labelText={"Select attendance date"}
              setPickedDate={setPickedUpDate}
              pickedDate={pickedDate}
              initialValue={pickedDate}
              isQuizUpdatedEver={false}
              alsoShowTimeStamp={false}
            />
          </Col>
        </Row>

        {/* mark today as holiday checkbox */}

        {
          loading ? <div>Loading....</div> :
            <Table striped borderless responsive>
              <thead>
                <tr>
                  <th>Sno.</th>
                  <th>Name</th>
                  <th>Class</th>
                  <th>Absent/Present</th>
                </tr>
              </thead>
              <tbody>
                {
                  studentsAttendanceData.map((studentAttData: AttendanceRecord, index: number) => {
                    if (searcher(studentAttData, searchField))
                      return <tr key={index}>
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          {studentAttData.userName}
                        </td>
                        <td>
                          {studentAttData.class}
                        </td>
                        <td>
                          <ButtonGroup>
                            {/* <Button variant={!studentAttData.isHoliday && studentAttData.isPresent ? 'outline-danger' : 'danger'} */}
                            {/*   onClick={() => updateStudentAttendanceInfo(index, false)} */}
                            {/* >Absent</Button> */}
                            {/* <Button variant={!studentAttData.isHoliday && studentAttData.isPresent ? "success" : "outline-success"} */}
                            {/*   onClick={() => updateStudentAttendanceInfo(index, false)} */}
                            {/* >Present</Button> */}
                            {/**/}
                            {/* <Button variant={studentAttData.isHoliday ? "info" : "outline-info"} */}
                            {/*   onClick={() => updateStudentAttendanceInfo(index, true)} */}
                            {/* >Mark Holiday</Button> */}


                            <Button variant={buttonStatus(studentAttData, 'absent')}
                              onClick={() => updateStudentAttendanceInfo(index, false, false)}
                            >Absent</Button>
                            <Button variant={buttonStatus(studentAttData, 'present')}
                              style={{
                                marginLeft: "4px"
                              }}
                              onClick={() => updateStudentAttendanceInfo(index, true, false)}
                            >Present</Button>
                            <Button variant={buttonStatus(studentAttData, 'holiday')}
                              style={{
                                marginLeft: "4px"
                              }}
                              onClick={() => updateStudentAttendanceInfo(index, null, true)}
                            >Mark Holiday</Button>

                          </ButtonGroup>
                        </td>
                      </tr>
                  })
                }
              </tbody>
            </Table>
        }
      </div>
    </Container >
  )
}

export default AttendanceUpdatePage;
