import {
  Button,
  Row,
  Col,
  Form,
  Card,
  Container,
  Badge,
  CloseButton,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import QuizzesMadeListShower from "../../../../components/list components/QuizzesMadeList";
import { quizStore } from "../../../../store/quizStore";
import { onlineQuizMakerUrl } from "../../../../utils/all_routes";

//The actual page where the online quiz is made
function OnlineQuizMakerMainCompo() {
  const currentOnlineQuizIndex = quizStore(
    (state) => state.currentOnlineQuizIndex
  );

  const currentQuizData = quizStore(
    (state) => state.onlineQuizzes[currentOnlineQuizIndex]
  );

  const addQuestion = quizStore((state) => state.addQuestion);
  const deleteQuestion = quizStore((state) => state.deleteQuestion);
  const editQuestionTitle = quizStore((state) => state.editQuestionFields);

  const addOption = quizStore((state) => state.addOption);
  const delOption = quizStore((state) => state.deleteOption);

  const editOptionFields = quizStore((state) => state.editOptionFields);

  const navigate = useNavigate(); //navigate to other pages

  function handleGoBack() {
    navigate(onlineQuizMakerUrl); //go back the online quiz making main page
  }

  function handleAddQuestion() {
    //adds a new question to the list of questions of current quiz
    addQuestion();
  }

  function handleEditQuestionTitle(questionIndex, value) {
    editQuestionTitle(questionIndex, value);
  }

  function handleDeleteQuestion(questionIndex) {
    //console.log()("deleting index", questionIndex);
    deleteQuestion(questionIndex);
  }

  function handleAddOption(questionIndex) {
    addOption(questionIndex);
  }

  function handleDeleteOption(questionIndex, optionIndex) {
    // //console.log()("deleting options" , optionIndex)
    delOption(questionIndex, optionIndex);
  }

  function handleEditOption(questionIndex, optionIndex, text) {
    editOptionFields(questionIndex, optionIndex, "optionText", text);
  }

  return (
    <div
      style={{
        marginLeft: "16px",
        marginTop: "16px",
      }}
    >
      <Row>
        <Col md="auto">
          <Button onClick={handleGoBack}>Go Back</Button>
        </Col>

        <Col>
          <h4>{currentQuizData.title}</h4>
        </Col>
      </Row>

      <div
        style={{
          textAlign: "center",
        }}
      >
        <Row>
          {/* display the questions here  */}
          <div
            style={{
              marginBottom: "16px",
            }}
          >
            {currentQuizData.questions.map((question, questionIndex) => {
              return (
                <div
                  key={question.questionAddedAt}
                  style={{
                    marginRight: "10%",
                    marginTop: "16px",
                    // marginLeft: "2%",
                    // marginBottom: "16px",
                  }}
                >
                  <Row>
                    <Col>
                      <Card bg="light">
                        <Card.Body>
                          {/* The question area */}
                          <Container>
                            <Row
                              style={{
                                marginBottom: "8px",
                              }}
                            >
                              <Col>
                                <Form.Control
                                  style={{
                                    fontFamily: "Raleway",
                                    fontSize: "20px",
                                  }}
                                  onChange={(event) =>
                                    handleEditQuestionTitle(
                                      questionIndex,
                                      event.currentTarget.value
                                    )
                                  }
                                  type="text"
                                  as="textarea"
                                  rows={4}
                                  key={questionIndex}
                                  placeholder={"Enter Question"}
                                  defaultValue={question.questionText}
                                ></Form.Control>
                              </Col>
                            </Row>

                            <Row
                              style={{
                                marginBottom: "8px",
                              }}
                            >
                              <Col md="auto">
                                <Form.Control
                                  type="number"
                                  placeholder="Enter Marks"
                                />
                              </Col>
                              <Col></Col>
                              <Col md="auto">
                                <Button
                                  size="sm"
                                  variant="danger"
                                  //   disabled={true}
                                  onClick={() =>
                                    handleDeleteQuestion(questionIndex)
                                  }
                                >
                                  Delete
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              {/* shows the options */}

                              {question.options.map((option, optionIndex) => {
                                return (
                                  <div
                                    key={option.optionCreatedAt}
                                    style={{
                                      marginTop: "4px",
                                    }}
                                  >
                                    <Row>
                                      <Col>
                                        <Form.Control
                                          placeholder="Enter option"
                                          defaultValue={option.optionText}
                                          type="text"
                                          onChange={(event) => {
                                            handleEditOption(
                                              questionIndex,
                                              optionIndex,
                                              event.currentTarget.value
                                            );
                                          }}
                                        />
                                      </Col>

                                      <Col md="auto">
                                        <CloseButton
                                          size="sm"
                                          onClick={() =>
                                            handleDeleteOption(
                                              questionIndex,
                                              optionIndex
                                            )
                                          }
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              })}
                            </Row>

                            <Row>
                              <Col md="auto">
                                <Button
                                  size="sm"
                                  onClick={() => handleAddOption(questionIndex)}
                                >
                                  Add Option
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="auto">
                      <Badge>Question {questionIndex + 1}</Badge>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
          <Col>
            <Button onClick={handleAddQuestion}>Add Question</Button>
          </Col>
        </Row>
      </div>

      {/* now everything should be in the center */}
    </div>
  );
}

export default OnlineQuizMakerMainCompo;
