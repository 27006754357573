// import StudentNavBar from "../../components/layout/StudentNavBar";

// import { useState } from "react";

import { Accordion } from "react-bootstrap";

import classes from "../../css_modules/MyAcademics.module.css";

// import useStore from "../../store/WebsiteStore";
import QuizHistoryViewList from "../../components/quiz specific/QuizhHistoryCard";

function StudentAcademicsPage() {
  // const [showCompletedQuiz, setShowCompletedQuiz] = useState(false); //for a better optimized approach

  // const [showAssignmentCard, setShowAssignmentCard] = useState(false);

  // const handleQuizClick = () => {
  //   setShowCompletedQuiz(true);
  //   setShowAssignmentCard(false);
  // };

  // const handleAssignmentClick = () => {
  //   setShowCompletedQuiz(false);
  //   setShowAssignmentCard(true);
  // };

  return (
    <div>
      {/* <StudentNavBar sticky="top" onlyShowBanner={false} /> */}

      <Accordion defaultActiveKey={0}>
        <Accordion.Item
          className={classes.accordianStyle}
          eventKey={0}
          // onClick={handleQuizClick}
        >
          <Accordion.Header>
            <b className={classes.accordianTitle}>Quiz History</b>
          </Accordion.Header>

          <Accordion.Body>
            <QuizHistoryViewList />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item
          // onClick={handleAssignmentClick}
          className={classes.accordianStyle}
          eventKey={1}
        >
          <Accordion.Header>
            <b className={classes.accordianTitle}>Assignment History</b>
          </Accordion.Header>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default StudentAcademicsPage;
