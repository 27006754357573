import { TeacherAssignmentData } from "../models/assignment_model";

export function isAssignmentDataChanged(
  initialAssignmentData: TeacherAssignmentData,
  changedAssignmentData: TeacherAssignmentData
) {
  console.log("initial data", initialAssignmentData);
  console.log("current data", changedAssignmentData);

  //check if due date has changed
    // if (initialAssignmentData.dueDateAt !== changedAssignmentData.dueDateAt)
    //   return true;

  //check if question is changed
  if(initialAssignmentData.question !== changedAssignmentData.question) return true;
  return false;
}
