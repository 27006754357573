import { Navigate, Outlet } from "react-router-dom";
import useStore from "../store/WebsiteStore";

import TeacherNavBar from "../components/layout/TeacherNavBar";

import StudentNavBar from "../components/layout/StudentNavBar";

function PrivateRoutes({ children, isUserAuth, ...rest }) {
  const showAllItemsOfNavBar = useStore((state) => state.showAllItemsOfNavBar);
  const userRoleType = useStore((state) => state.userRoleType);

  return isUserAuth ? (
    <>
      {userRoleType === "teacher" ? (
        <TeacherNavBar onlyShowBanner={!showAllItemsOfNavBar} />
      ) : (
        <StudentNavBar onlyShowBanner={!showAllItemsOfNavBar} />
      )}

      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRoutes;
