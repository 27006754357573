export function groupData(data, key) {
  const groupedData = {};

  for (var v = 0; v < data.length; v++) {
    var studentClass = data[v][key];

    if (groupedData[studentClass] === undefined) {
      groupedData[studentClass] = {
        students: [data[v].username],
        itemCount: 1,
      };
    } else {
      groupedData[studentClass].students.push(data[v].username);

      groupedData[studentClass].itemCount += 1;
    }
  }
  return groupedData;
}

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
