import { Container, Button, Row, Col, Badge } from "react-bootstrap";
import useStore from "../../store/WebsiteStore";
import { downloadAnswerSheet } from "../../controllers/quiz_controller";

import { singleStudentAcaademicInfoUrl } from "../../utils/all_routes";
// import { useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  // downloadAnswerSheet,
  downloadQuestionPaper,
} from "../../controllers/quiz_controller";
import HidableComponent from "../ui/HidableComponent";
export const completedQuizPageOfflineModeUrl = "/offline-completed-quiz";

export function CompletedQuizPageOfflineMode() {
  const quizData = useStore((state) => state.completedQuizData);
  const setCompleteQuizData = useStore((state) => state.setCompletedQuizData);
  const navigate = useNavigate();
  const userRoleType = useStore((state) => state.userRoleType);

  // const [downloadingAnswerSheet, setdownloadingAnswerSheet] = useState(false);

  function goBackHandler() {
    //console.log()("back clicked offline mode");
    //navigate to appropriate routes when going back
    if (userRoleType === "teacher") {
      //console.log()("admin go back clicked");
      navigate(singleStudentAcaademicInfoUrl);
    } else {
      navigate("/studentAcademics");
    }
    setCompleteQuizData({});
    //also clear the quiz data
  }

  const answerSheetDownload = async () => {
    const answerPath = quizData.answerPath;
    const downloadedFile = await downloadAnswerSheet(answerPath);
    //console.log()("file downloaded" + downloadedFile);
  };

  async function questionPaperDownload() {
    const questionPath = quizData.storagePathCloud;
    await downloadQuestionPaper(questionPath);
  }

  return (
    <div
      style={{
        // marginLeft: "2%",
        // marginRight: "2%",
        marginTop: "2%",
      }}
    >
      <Container>
        <Row>
          <Col>
            <Button onClick={goBackHandler} size="sm">
              Go Back
            </Button>
          </Col>
          <h2> {quizData.title} </h2>
          {/* <h2> {quizData.answerPath} </h2> */}
          <h4>
            <Badge bg="success">
              {quizData.scored} / {quizData.marks} marks
            </Badge>
          </h4>

          <Row
            style={{
              marginBottom: "8px",
            }}
          >
            <Col md="auto">
              <h4>Chapters</h4>
            </Col>
            {quizData.chapters.map((chapter) => {
              return (
                <Col md="auto">
                  <Badge>{chapter}</Badge>
                </Col>
              );
            })}
          </Row>

          <Row>
            <h5>
              Note : You can save the question paper and answer sheet in your
              device so that you won't have to download again and again (We are
              working on the feature to display the answer sheet on the website
              without downloading)
            </h5>
          </Row>
          <hr></hr>

          <div
            style={{
              paddingRight: "10%",
              paddingLeft: "10%",
            }}
          >
            <HidableComponent hide={quizData.hasQuestionPaper}>
              <Row>
                <Button
                  onClick={questionPaperDownload}
                  variant="success"
                  style={{
                    marginBottom: "16px",
                  }}
                >
                  Download Question Paper
                </Button>
              </Row>
            </HidableComponent>

            <Row>
              <Button onClick={answerSheetDownload}>
                Download Answer Sheet
              </Button>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
}
