import {
  Button,
  Form,
  InputGroup,
  Row,
  Col,
  Card,
  Badge,
} from "react-bootstrap";
import DatePicker from "../../../components/ui/DatePicker";
import { useNavigate } from "react-router-dom";
import assignmentStore from "../../../store/assignment_store";
import { useRef, useState } from "react";
import CustomFormInputUI from "../../../components/ui/CustomFormUI";
import { useEffect } from "react";
import { showAlert, showSuccess } from "../../../controllers/toaster";
import { Timestamp } from "firebase/firestore";
import { makeAssignmentUrl } from "../../../utils/all_routes";
import {
  fetchNotSelectedStudentsFromClass,
  // getAlreadyAssignedStudents,59
  // removeAlreadySelected,
  updateAssignment,
  uploadAssignmentToStudents,
  uploadAssignmentQPaper,
} from "../../../controllers/assignment_controller";
import CustomFilePicker from "../../../components/ui/CustomFilePicker";
import { isAssignmentDataChanged } from "../../../controllers/unsaved_changes_controller";
import AssignedUserCard from "./AssignedStudentsCard";

function EditAssignmentPage() {
  const navigate = useNavigate(); //navigate to other pageskk

  const currentAssignmentData = assignmentStore(
    (state) => state.currentAssignmentData
  );

  const [initialAssignmentData, setInitialAssignmentData] = useState({
    ...currentAssignmentData,
  });

  const assignmentTitleRef = useRef("");
  const questionRef = useRef("");
  const assignmentMarksRef = useRef(0);
  const [pickedDate, setPickedDate] = useState(
    currentAssignmentData.readableDueDate
  );
  const [currentClassSelected, setCurrentClassSelected] = useState(
    currentAssignmentData.classAssignedTo
  );

  const [removedStudents, setRemovedStudents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [allStudents, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [uploadingAssignmentsToStudent, setUploadingAssignmentToStudent] =
    useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [previouslySelectedStudents, setPreviouslySelectedStudents] = useState(
    []
  ); //the list of previosly selected students
  const [saving, setSaving] = useState(false);

  //   const assignmentMarksRef = useRef(0);
  //functions

  // setRefresh(true)
  //get the current students from the class
  useEffect(() => {
    console.log("curent assignment data", currentAssignmentData);
    setRefresh(true);
    async function fetcher() {
      setLoadingStudents(true);

      await fetchNotSelectedStudentsFromClass(
        currentClassSelected,
        currentAssignmentData.id,
        setPreviouslySelectedStudents,
        setStudents,
        setSelectedStudents, //this one is used for the students displaying in the ui
        setLoadingStudents
      );

      setLoadingStudents(false);

      setLoadingStudents(false);
    }

    if (currentClassSelected !== undefined) fetcher();
    return () => { };
  }, [currentClassSelected, refresh, currentAssignmentData.id]);

  /**
   * This function is only being used to unlock the edit of the assignments
   */
  function handleAssignmentConfirm() {
    setIsAssignmentPristine(false); //unset to make edits
    // var check = validator();
    // if (check === false) {
    //   showAlert("Please enter all the details ");
    //   return;
    // }

    // const finalSelectedStudents = selectedStudents.filter(
    //   (element) => element.studentSelected
    // );

    // console.log("students selected", finalSelectedStudents, selectedStudents);

    // const assignmentData = getAssignmentData();

    // //check if marks is not zero then also assign marks to the quiz

    // //otherwise now proceed to do it
    // //console.log()(finalSelectedStudents);
    // // setUploadingAssignmentToStudent(false);

    // //console.log()(assignmentData);
    // // uploadingAssignmentsToStudent(
    // //   finalSelectedStudents,
    // //   assignmentData,

    // //   setUploadingAssignmentToStudent
    // // );
  }

  //this function will validate the form for the correct inputs before submitting
  function validator() {
    var titleCheck = assignmentTitleRef.current.value.length > 4;
    var marksCheck = assignmentMarksRef.current.value.length > 0;

    return titleCheck && marksCheck;
  }

  //handle save can save small parts of the assignment as well
  async function handleSave() {
    //if hasQuestionPaper is selected then validate if filename is there
    if (hasQuestionPaper && !qPaperFileName) {
      showAlert("Please include a file to upload");
      return;
    }

    // saves the assignment data on firebase of this current assginemtn
    // const assignmentData = getAssignmentData();
    // saveAssignmentOnFirebase(currentAssignmentData.id, assignmentData);
    const assignmentData = getAssignmentData();
    //console.log()("assignment data", assignmentData);
    await updateAssignment(currentAssignmentData.id, assignmentData, setSaving);
    console.log("saving the data", assignmentData);
    setInitialAssignmentData(assignmentData);

    //currently only re-settting the removed students to avoid duplication

    //assign the assignmnt to the selected students
    const isAssignmentProperlyUploaded = await uploadAssignmentToStudents(
      assignmentData,
      removedStudents,
      setSaving
    );

    setRemovedStudents([]);
    setRefresh(!refresh);
    setIsAssignmentPristine(true);
  }
  function handleGoback() {
    console.log("going back");
    //check if any data has changed
    if (isAssignmentDataChanged(initialAssignmentData, getAssignmentData())) {
      console.log("there are unsaved changes");
      showAlert("Please save unsaved changes !!", 5000);
      return;
    }
    navigate(makeAssignmentUrl);
  }

  //returns the assignment data for the calling fuction

  function getAssignmentData() {
    var isGraded = assignmentMarksRef.current.value !== "0";
    const finalSelectedStudents = selectedStudents.filter(
      (element) => element.studentSelected
    );
    return {
      id: currentAssignmentData.id, //why were we not assigning ?
      title: assignmentTitleRef.current.value,
      graded: isGraded,
      // createdAt: Timestamp.fromDate(new Date()),
      dueDateAt: Timestamp.fromDate(new Date(pickedDate)), //this is working fine and assigning proper time
      assignedTo: [...previouslySelectedStudents, ...finalSelectedStudents],
      allowLateSubmit: true, //allowing as of now :)
      marks: parseInt(assignmentMarksRef.current.value),
      classAssignedTo: currentClassSelected,
      question: questionRef.current.value,

      //file related keys

      storagePathCloud: hasQuestionPaper ? storagePath : null,
      isUploadedOnStorage: true,
      fileName: qPaperFileName === undefined ? null : qPaperFileName,
      hasQuestionPaper: hasQuestionPaper,
    };
  }

  /**
   * when the save will be clicked then only the students who were already assigned will be removed from their
   * assignment page
   * This function is therefore only for the ui update scenario
   *
   * based on the opreatio, if the the operatio is not remove then this user should be added back to the assigned list
   */
  function handleRemoveAssignmentFromStudent(
    currentAssignmentId,
    selectedStudentId,
    isRemoveOperation
  ) {
    //=================== UI UPDATES
    if (isRemoveOperation) {
      const removedStudentData = previouslySelectedStudents.filter(
        (assignedStudent) => assignedStudent.id === selectedStudentId
      );

      //remove this student from previously selected student
      setPreviouslySelectedStudents(
        previouslySelectedStudents.filter(
          (assignedStudent) => assignedStudent.id !== selectedStudentId
        )
      );
      setRemovedStudents([...removedStudents, ...removedStudentData]);
    } else {
      //remove this student from the removed students list and add back into previouslySelected student
      const assignedBackStudent = removedStudents.filter(
        (assignedStudent) => assignedStudent.id === selectedStudentId
      );

      //remove from removed students
      setRemovedStudents(
        removedStudents.filter(
          (studentData) => studentData.id !== selectedStudentId
        )
      );

      //add back into previously selected students
      setPreviouslySelectedStudents([
        ...previouslySelectedStudents,
        ...assignedBackStudent,
      ]);
    }
  }
  function handleEditAssignmentForStudent(
    currentAssignmentId,
    selectedStudentId
  ) { }

  // ============ File related state changes
  //  ===================== FILE uploading related states (Even I am not happy copying this code)

  const fileRef = useRef();
  const [hasQuestionPaper, setHasQuestionPaper] = useState(
    currentAssignmentData.hasQuestionPaper !== undefined
      ? currentAssignmentData.hasQuestionPaper
      : true
  );
  const [fileUploaded, setFileUploaded] = useState(
    currentAssignmentData.isUploadedOnStorage
  ); //use this boolean itself to just check if the quiz has been already updated earlier on the firebase storage
  const [qPaperSelectedBool, setQPaperSelectedBool] = useState(
    currentAssignmentData.isUploadedOnStorage
  );
  const [qPaperFileName, setqPaperFileName] = useState(
    currentAssignmentData.fileName
  );
  const [qPaperFile, setqPaperFile] = useState("nothin");
  const [updatigQuiz, setUpdatigQuiz] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [storagePath, setStoragePath] = useState(
    currentAssignmentData.storagePathCloud
  );

  const [isAssignmentPristine, setIsAssignmentPristine] = useState(
    currentAssignmentData.isUploadedOnStorage !== undefined
      ? currentAssignmentData.isUploadedOnStorage
      : false
  ); // we will use this to check that if the quiz has anytime been saved to the storage and to the backedn

  function handleIncludeQPaper() {
    //toggles for includeing question paper or not
    setHasQuestionPaper(!hasQuestionPaper);
  }

  async function handleFileChange() {
    setQPaperSelectedBool(false); //give the user to again upload the file
    setFileUploaded(false);
  }

  async function handleQuestionPaperSelect(event) {
    const files = event.target.files;
    setQPaperSelectedBool(true);
    setqPaperFile(files[0]);
    setqPaperFileName(files[0].name);
    //try to upload the file to the firebase storage
    //console.log()(files[0]);
  }

  async function handleQuestionPaperUpload() {
    const response = await uploadAssignmentQPaper(
      qPaperFile,
      // setUploadProgress,
      currentAssignmentData,
      setFileUploading
    );
    console.log("response is", response);

    setStoragePath(response);

    setFileUploaded(true);
  }

  // ================ File related state changes end here

  return (
    <div
      style={{
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "16px",
      }}
    >
      <Button onClick={handleGoback}>Go Back</Button>
      <Row>
        {/* First show the assignment title the user wants to edit again */}

        <Col>
          <Form>
            <h3>{currentAssignmentData.title}</h3>
            <Col>
              <Button
                onClick={handleSave}
                disabled={saving || isAssignmentPristine}
                variant={saving ? "secondary" : "success"}
              >
                {saving ? "Saving..." : "Save"}
              </Button>
            </Col>

            <DatePicker
              labelText={"Set due date for assignment"}
              setPickedDate={setPickedDate}
              pickedDate={pickedDate}
              initialValue={pickedDate}
              isQuizUpdatedEver={isAssignmentPristine}
              disabled={isAssignmentPristine}
            />
            <Form.Label>
              <h5>Enter the question</h5>
            </Form.Label>

            {/* Enter the question to be added */}
            {/* Title ehre */}
            <CustomFormInputUI
              type="text"
              reffer={assignmentTitleRef}
              title="Assignment Title"
              someDefaultValue={currentAssignmentData.title}
              isFormDisabled={isAssignmentPristine}
              placeholder={currentAssignmentData.title}
              customTextSize="24px"
              required
            />

            {/* Question here */}
            <InputGroup>
              <InputGroup.Text>Question (or instructions)</InputGroup.Text>
              <Form.Control
                as="textarea"
                aria-label="With textarea"
                ref={questionRef}
                defaultValue={currentAssignmentData.question}
                disabled={isAssignmentPristine}
              />
            </InputGroup>

            <CustomFormInputUI
              type="number"
              reffer={assignmentMarksRef}
              title={"Marks ( enter 0 for non graded )"}
              someDefaultValue={currentAssignmentData.marks}
              isFormDisabled={isAssignmentPristine}
              placeholder={currentAssignmentData.marks}
            />

            <CustomFormInputUI
              type="number"
              title="Class"
              someDefaultValue={currentAssignmentData.classAssignedTo}
              isFormDisabled={isAssignmentPristine}
              placeholder={currentAssignmentData.class}
              onChange={(event) => {
                setCurrentClassSelected(parseInt(event.target.value));
              }}
            />
          </Form>
        </Col>

        {/* <Row>
          <Form.Check label={<h6>Include Assignment File</h6>} />
        </Row> */}

        {/* <CustomFilePicker includeQPaper={true} labelText={"Include File"} /> */}
        <CustomFilePicker
          labelText={"Include Question Paper"}
          handleIncludeQPaper={handleIncludeQPaper}
          hasQuestionPaper={hasQuestionPaper}
          includeQPaper={hasQuestionPaper}
          fileUploaded={fileUploaded}
          qPaperSelectedBool={qPaperSelectedBool}
          qPaperFileName={qPaperFileName}
          handleFileChange={handleFileChange}
          handleQuestionPaperSelect={handleQuestionPaperSelect}
          handleQuestionPaperUpload={handleQuestionPaperUpload}
          fileUploading={fileUploading}
          fileRef={fileRef}
          isQuizUpdatedEver={isAssignmentPristine}
          storagePathCloud={storagePath}
        />

        {/* <hr></hr> */}

        {/* now show the file picker for showing assignment file to pick */}

        {/* Now show the students of that class */}
        {/* <hr></hr> */}

        <Row
          style={{
            marginBottom: "10%",
            marginTop: "16px",
          }}
        >
          <Col>
            <h5>Assign the assignments to new students here </h5>
            <Row>
              {allStudents.map((student, index) => {
                return (
                  <div key={student.id}>
                    <Card
                      style={{
                        marginBottom: "8px",
                        // padding: "8px",
                      }}
                    >
                      <Card.Body>
                        <Form.Check
                          // defaultChecked={student.studentSelected}
                          type="switch"
                          disabled={isAssignmentPristine}
                          key={student.id}
                          label={student.username}
                          onClick={() => {
                            console.log("students selected");
                            //change the students selected data that we chose
                            setSelectedStudents([
                              ...selectedStudents.slice(0, index),
                              {
                                ...selectedStudents[index],
                                studentSelected:
                                  !selectedStudents[index].studentSelected,
                              },
                              ...selectedStudents.slice(index + 1),
                            ]);
                          }}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                );
              })}

              {loadingStudents ? (
                <h5>Loading students...</h5>
              ) : (
                <div>
                  {currentClassSelected !== undefined ? (
                    <Button
                      variant="success"
                      onClick={handleAssignmentConfirm}
                      disabled={uploadingAssignmentsToStudent}
                    >
                      {uploadingAssignmentsToStudent
                        ? "Uploading..."
                        : "Make changes"}
                    </Button>
                  ) : null}
                </div>
              )}
            </Row>
          </Col>

          <Col>
            <h5>Assigned students</h5>
            {/* Not assigned students */}
            {previouslySelectedStudents.map((assignedPerson) => {
              return (
                <AssignedUserCard
                  key={assignedPerson.id}
                  assignedPerson={assignedPerson}
                  isRemoveOperation={true}
                  isAssignmentPristine={isAssignmentPristine}
                  handleRemoveAssignmentFromStudent={
                    handleRemoveAssignmentFromStudent
                  }
                  handleEditAssignmentForStudent={
                    handleEditAssignmentForStudent
                  }
                ></AssignedUserCard>
              );
            })}

            {/* <hr></hr> */}
            {/* Show the removed students */}
            {removedStudents.length > 0 ? (
              <div>
                <h5>Removed Students</h5>
                {removedStudents.map((assignedPerson) => {
                  return (
                    <div>
                      <AssignedUserCard
                        key={assignedPerson.id}
                        assignedPerson={assignedPerson}
                        isRemoveOperation={false}
                        isAssignmentPristine={isAssignmentPristine}
                        handleRemoveAssignmentFromStudent={
                          handleRemoveAssignmentFromStudent
                        }
                      ></AssignedUserCard>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </Col>
        </Row>
      </Row>
    </div>
  );
}

export default EditAssignmentPage;
