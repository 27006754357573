import { setDoc, Timestamp } from "firebase/firestore";
import {
  getCurrentTimeStamp,
  getNotificationCollection,
  getNotificationDocument,
} from "../utils/constants";

//notifyToken - firebases' notification token
export async function addNotificationIdToFirebase(userId, notifyToken) {
  const notifyTokenRef = getNotificationDocument(userId, notifyToken);

  //then add the notification token if any is there

  console.log("adding notification id ==> " + notifyToken);

  try {
    await setDoc(notifyTokenRef, {
      addedAt: getCurrentTimeStamp(),
      id: notifyToken,
    });

    console.log("added");
  } catch (error) {
    console.log("adding failed ", error);
  }
}
